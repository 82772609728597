import React from "react";
import styles from "./index.module.scss";
import MainButton from "../../components/mainButton";
import Inbox from "../../assets/Inbox.svg";
import { useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../../components/AuthLayout";
function CheckYourEmail() {
  const location = useLocation();
  const { email, type } = location.state || {};
  const navigate = useNavigate();

  // //console.log("navigate with this email", data?.email);
  return (
    <AuthLayout customBg={styles["custom-bg"]}>
      <div className={styles["login-btn-container"]}>
        <img src={Inbox} alt="Inbox" />
        <p className={styles["login-title"]}>Check Your Email</p>
        <div>
          <p className={styles["login-p"]}>
            We have sent a {type} link to {email}
          </p>
          <p className={styles["login-p"]}>
            Please check your email for the link, if not found please check the
            junk folder.
          </p>
        </div>
      </div>
      <MainButton
        handleSubmit={() => {
          navigate("/login");
        }}
        className={styles["login-btn"]}
      >
        Back to Sign In
      </MainButton>
    </AuthLayout>
  );
}

export default CheckYourEmail;
