import styles from "./index.module.scss";
import eye from "../../../assets/eye.svg";
import { useState } from "react";

export function RequestQuoteInput({
  formRegister,
  icon,
  name,
  placeholder,
  label,
  maxLength,
  type = "text",
  error,
  inputMode,
  disabled = false,
  unit,
  onChange,
  value,
  unitStyle,
}) {
  return (
    <div
      className={`${styles["container"]} ${
        disabled ? styles["container-disabled"] : styles[""]
      }`}
    >
      <div className={[styles["input-container"]].join(" ")}>
        {label && (
          <label htmlFor={name} className={"input-label"}>
            {label}
          </label>
        )}
        <input
          id={name}
          name={name}
          type={type}
          {...formRegister}
          placeholder={placeholder ? placeholder : ""}
          maxLength={maxLength}
          autoComplete="off"
          inputMode={inputMode}
          disabled={disabled}
          className={`${error ? styles["input-error"] : ""}`}
          onChange={onChange}
          value={value}
        />
        {icon && icon}
        {unit && <p className={`${styles["unit"]} ${unitStyle}`}>{unit}</p>}
      </div>
      {error && name !== "password" && (
        <p className={styles["form-error"]}>{error?.message}</p>
      )}
    </div>
  );
}

export default RequestQuoteInput;
