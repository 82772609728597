import styles from "./index.module.scss";
import { Button } from "@mui/material";
export default function MainButton({
  className = "",
  variant = "contained",
  handleSubmit,
  children,
  type,
  disabled,
}) {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={handleSubmit}
      variant={variant}
      className={`${[styles.btn, styles.defaultBtn, className].join(" ")}`}
    >
      {children}
    </Button>
  );
}
