import axios from "axios";

export const checkToken = () => {
  return axios
    .get()
    .then((response) => response)
    .catch((err) => err.response);
};

export const login = (payload) => {
  return axios
    .post("/auth/login", payload)
    .then((response) => response)
    .catch((err) => err.response);
};
export const signup = (payload) => {
  return axios
    .post("/auth/signup", payload)
    .then((response) => response)
    .catch((err) => {
      //console.log("Error response data:", err.response.data);
      //console.log("Error response status:", err.response.status);
      return err.response;
    });
};

export const logout = (payload) => {
  const config = {
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Token ${payload}`, // Token from payload
    },
  };
  return axios
    .get("/auth/logout", config)
    .then((response) => response)
    .catch((err) => err.response);
};

export const sendOTP = (payload) => {
  return axios
    .post("/auth/otp/send", payload)
    .then((response) => response)
    .catch((err) => err.response);
};
export const verifyEmail = (payload) => {
  return axios
    .post("/auth/verification-link/send", payload)
    .then((response) => response)
    .catch((err) => err.response);
};
export const FetchSignupAvailability = () => {
  return axios
    .get("/global/config/signup/availability")
    .then((response) => response)
    .catch((err) => err.response);
};

export const checkOTP = (payload) => {
  return axios
    .post("/auth/otp/check", payload)
    .then((response) => response)
    .catch((err) => err.response);
};

export const resetPassword = (payload, params = "") => {
  return axios
    .post(`/auth/password/reset${params ? `?${params}` : ""}`, payload)
    .then((response) => response)
    .catch((err) => err.response);
};

export const changePassword = (payload, token) => {
  return axios
    .post("/auth/password/change", payload, {
      headers: { Authorization: `Token ${token}` },
    })
    .then((response) => response)
    .catch((err) => err.response);
};

export const getProfile = (token) => {
  return axios
    .get("/auth/profile", {
      headers: { Authorization: `Token ${token}` },
    })
    .then((response) => response)
    .catch((err) => err.response);
};

export const editProfile = (payload, formData, token) => {
  return axios
    .put("/auth/profile/edit", payload, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response)
    .catch((err) => err.response);
};

export const editProfileImage = (file, action, token) => {
  const formData = new FormData();
  formData.append("action", action);
  formData.append("picture", file);

  return axios
    .patch(`/auth/profile/image/edit`, formData, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response)
    .catch((err) => err.response);
};
