import React, { useState } from "react";
import styles from "./index.module.scss"; // Make sure to create this file for styling

const Pagination = ({
  totalItems = 48,
  itemsPerPage = 7,
  pageNumber = 1,
  setPageNumber,
}) => {
  // const [pageNumber, setPageNumber] = useState(pageNumber);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const type = localStorage.getItem("type");
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setPageNumber(page);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      if (i >= 1 && i <= 3) {
        pages.push(
          <button
            key={i}
            className={`${styles.pageButton} ${
              pageNumber === i ? styles.active : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
      if (i === 4 && totalPages !== 4) {
        pages.push(
          <button
            key={i}
            className={` ${
              pageNumber >= 4 && pageNumber < totalPages ? "" : ""
            }`}
            onClick={() => handlePageChange(i)}
            style={{
              background: "none",
              border: "none",
            }}
          >
            {"..."}
          </button>
        );
      }
      if (i === totalPages && i > 3) {
        pages.push(
          <button
            key={i}
            className={`${styles.pageButton} ${
              pageNumber === i ? styles.active : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    }
    return pages;
  };

  return (
    <div
      className={
        type === "client"
          ? styles.paginationContainerClient
          : styles.paginationContainer
      }
    >
      <button
        className={styles.arrowButton}
        onClick={() => handlePageChange(pageNumber - 1)}
        disabled={pageNumber === 1}
      >
        &lt;
      </button>
      {renderPageNumbers()}
      <button
        className={styles.arrowButton}
        onClick={() => handlePageChange(pageNumber + 1)}
        disabled={pageNumber === totalPages}
      >
        &gt;
      </button>

      <span className={styles.pageInfo}>
        Showing ({(pageNumber - 1) * itemsPerPage + 1} -{" "}
        {Math.min(pageNumber * itemsPerPage, totalItems)}) out of {totalItems}
      </span>
    </div>
  );
};

export default Pagination;
