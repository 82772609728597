import { createSlice } from "@reduxjs/toolkit";

const myAccountTitleSlice = createSlice({
  name: "myAccountTitle",
  initialState: {
    title: "My Account",
  },
  reducers: {
    setMyAccountTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});

export const { setMyAccountTitle } = myAccountTitleSlice.actions;
export default myAccountTitleSlice.reducer;
