import React from "react";
import { useParams } from "react-router-dom";
import RequestQuoteStepOne from "./StepOne";
import RequestQuoteStepTwo from "./StepTwo";
import RequestQuoteStepThree from "./StepThree";
// import RequestQuoteStepFour from "./StepFour";
import RequestQuoteStepFive from "./StepFive";
import RequestQuoteStepSix from "./StepSix";

const Step = ({}) => {
  const { step } = useParams();
  const getStep = () => {
    switch (step) {
      case "shipping-details":
        return <RequestQuoteStepOne />;
      case "cargo-details":
        return <RequestQuoteStepTwo />;
      case "trucking-details":
        return <RequestQuoteStepThree />;
      case "other":
        return <RequestQuoteStepFive />;
      // case "others":
      //   return <RequestQuoteStepFive />;
      case "success":
        return <RequestQuoteStepSix />;
      default:
        return null;
    }
  };
  return getStep();
};

export default Step;
