import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import MainButton from "../mainButton";
import styles from "./index.module.scss";
function DeleteConfirmationModal({ open, setOpen, setSuccess, confirm }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    confirm();
    setSuccess(true);
    setOpen(false);
  };

  return (
    <div style={{ borderRadius: "1rem !important" }}>
      <Dialog
        sx={{ borderRadius: "1rem" }}
        open={open}
        onClose={handleClose}
        className={styles["modal"]}
      >
        <DialogTitle
          className={styles["modal-title"]}
          style={{ fontSize: "24px" }}
        >
          {"Are you sure you want to delete Shipment?"}
        </DialogTitle>

        <DialogActions
          style={{ width: "100%" }}
          className={styles["modal-actions"]}
        >
          <Button
            onClick={handleClose}
            className={styles["modal-cancel"]}
            color="primary"
          >
            No
          </Button>
          <MainButton
            className={styles["modal-confirm"]}
            handleSubmit={handleConfirm}
          >
            Yes
          </MainButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteConfirmationModal;
