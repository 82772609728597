import { useState, useEffect, useReducer } from "react";
import FreightSearch from "../../components/FreightSearch";
import styles from "./index.module.scss";
import FreightCard from "../../components/FreightCard";
import FiltersSidebar from "../../components/filtersSidebar";
import { getFreights } from "../../services/testService";
import LandingIcon from "../../assets/search_ship_schedules.png";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "../../assets/Filter.svg";
import Arrows from "../../assets/Arrows.svg";
import VesselSearchFIlterModal from "../../components/VesselSearchFIlterModal";
import SortModal from "../../components/SortModal";
import SortBy from "../../components/SortBy";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalConfigs } from "../../services/testService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSearchHistory } from "../../services/contentService";
/* */
const initialState = {
  priceRange: [1000, 6000],
  transitTimeRange: [10, 20],
  etdRange: ["2024-05-04", "2024-06-11"],
  etaRange: ["2024-05-17", "2024-09-06"],
};
const filtersReducer = (state, action) => {
  switch (action.type) {
    case "SET_PRICE_RANGE":
      return { ...state, priceRange: action.payload };
    case "SET_TRANSIT_TIME_RANGE":
      return { ...state, transitTimeRange: action.payload };
    case "SET_ETA_RANGE":
      return { ...state, etaRange: action.payload };
    case "SET_ETD_RANGE":
      return { ...state, etdRange: action.payload };
    default:
      return state;
  }
};
/* */
export default function SearchVessels() {
  // //console.log(window.innerWidth);
  const location = useLocation();
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [baseModal, setBaseModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [freights, setFreights] = useState([]);
  const [filterdFreights, setFilterdFreights] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isPressed, setIsPressed] = useState(false);
  const [isOpend, setIsOpend] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [sortCriterion, setSortCriterion] = useState("");
  const [bounds, setBounds] = useState({});
  const [filters, dispatch] = useReducer(filtersReducer, initialState);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [globalConfigs, setGlobalConfigs] = useState({});
  const [searchWarning, setSearchWarning] = useState(false);
  const [quoteLocation, setquoteLocation] = useState("");
  const [searchId, setsearchId] = useState(null);
  const [searchHistory, setSearchHistory] = useState([]);

  const token = localStorage.getItem("token");
  const sidebarWidth = useSelector((state) => state.sidebar.width);
  const {
    originPort,
    destinationPort,
    readyOn,
    cargoType,
    isOriginChanged,
    isDestinationChanged,
    isReadyOnChanged,
    isCargoTypeChanged,
  } = useSelector((state) => state.search);

  // Not Done Yet.
  // useEffect(() => {
  //   setIsPressed(
  //     !isOriginChanged ||
  //       !isDestinationChanged ||
  //       !isReadyOnChanged ||
  //       !isCargoTypeChanged
  //   );
  // }, [
  //   isOriginChanged,
  //   isDestinationChanged,
  //   isReadyOnChanged,
  //   isCargoTypeChanged,
  // ]);

  // Preparing Search params.
  const origin_port = originPort?.locode ?? "ITGOA";
  const destination_port = destinationPort?.locode ?? "EGALY";
  const cargo_type = cargoType?.type ?? "GC";
  const ready_on = readyOn === "1970-01-01" ? "2024-12-02" : readyOn;

  const handleSearch = async () => {
    const response = await getFreights(
      token,
      origin_port,
      destination_port,
      ready_on,
      cargo_type
    );
    if (response.status === 200 || response.status === 201) {
      //console.log(response.data);

      setFreights(response.data.data);
      setFilterdFreights(response.data.data);
      setShow(true);
      setIsPressed(true);
      // Daily limit Reached Toastify Condition.
      if (response.data?.error) {
        setSearchWarning(true);
      }
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  // Daily limit Reached Toastify.
  useEffect(() => {
    if (searchWarning) {
      toast.warn("Daily limit Reached.", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        autoClose: true,
        style: {
          width: "100%",
        },
      });
    }
  }, [searchWarning]);
  const sortData = (data, criterion) => {
    setSortCriterion(criterion);
    return data.slice().sort((a, b) => {
      switch (criterion) {
        case "highestPrice":
          return b.price - a.price;
        case "lowestPrice":
          return a.price - b.price;
        case "transitTime":
          return a.transit_time - b.transit_time;
        default:
          throw new Error("Invalid sorting criterion");
      }
    });
  };
  const handleSort = (data, criterion) => {
    setFreights(sortData(data, criterion));
    setFilterdFreights(sortData(data, criterion));
    //console.log("sorted :", criterion);
  };
  const calculateBounds = (data) => {
    let minPrice = Infinity;
    let maxPrice = -Infinity;
    let minTransitTime = Infinity;
    let maxTransitTime = -Infinity;
    let minETA = null;
    let maxETA = null;
    let minETD = null;
    let maxETD = null;
    data.forEach(({ price, transit_time, ETD, ETA }) => {
      // Price
      minPrice = Math.min(minPrice, price);
      maxPrice = Math.max(maxPrice, price);

      // Transit Time
      minTransitTime = Math.min(minTransitTime, transit_time);
      maxTransitTime = Math.max(maxTransitTime, transit_time);
      const etaDate = new Date(ETA);
      if (!minETA || etaDate < new Date(minETA)) minETA = ETA;
      if (!maxETA || etaDate > new Date(maxETA)) maxETA = ETA;

      // ETD (convert to Date objects and compare)
      const etdDate = new Date(ETD);
      if (!minETD || etdDate < new Date(minETD)) minETD = ETD;
      if (!maxETD || etdDate > new Date(maxETD)) maxETD = ETD;
    });

    return {
      minPrice,
      maxPrice,
      minTransitTime,
      maxTransitTime,
      minETA,
      maxETA,
      minETD,
      maxETD,
    };
  };
  const filterData = (
    data,
    { priceRange, transitTimeRange, etaRange, etdRange }
  ) => {
    return data.filter((item) => {
      const price = item.price;
      const transitTime = item.transit_time;
      const eta = new Date(item.ETA);
      const etd = new Date(item.ETD);
      //console.log("new ranges", eta, etd);
      const matchesPrice = priceRange
        ? price >= priceRange[0] && price <= priceRange[1]
        : true;
      const matchesTransitTime = transitTimeRange
        ? transitTime >= transitTimeRange[0] &&
          transitTime <= transitTimeRange[1]
        : true;
      const matchesETA = etaRange
        ? eta >= new Date(etaRange[0]) && eta <= new Date(etaRange[1])
        : true;
      const matchesETD = etdRange
        ? etd >= new Date(etdRange[0]) && etd <= new Date(etdRange[1])
        : true;

      return matchesPrice && matchesTransitTime && matchesETA && matchesETD;
    });
  };
  const handleFilter = (
    data,
    { priceRange, transitTimeRange, etaRange, etdRange }
  ) => {
    setFilterdFreights(
      filterData(data, { priceRange, transitTimeRange, etaRange, etdRange })
    );
  };
  const sortOptions = [
    {
      title: "Highest price",
      value: "Highest price",
      onclick: () => handleSort(freights, "highestPrice"),
    },
    {
      title: "Lowest price",
      value: "Lowest price",
      onclick: () => handleSort(freights, "lowestPrice"),
    },
    {
      title: " Soonest arrival",
      value: " Soonest arrival",
      onclick: () => handleSort(freights, "transitTime"),
    },
  ];
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (sortCriterion) {
      // //console.log(sortCriterion);
      handleSort(freights, sortCriterion);
    }
  }, [sortCriterion]);
  useEffect(() => {
    if (freights?.length > 0) {
      setBounds(calculateBounds(freights));
      // handleFilter(freights , )
    }
  }, [freights]);
  useEffect(() => {
    if (filtersApplied) {
      handleFilter(freights, filters);
      setIsFiltered(true);
    }
  }, [freights, filters, filtersApplied]);

  useEffect(() => {
    const fetchGlobalConfigs = async () => {
      const response = await getGlobalConfigs();
      //console.log("globalConfigs: ", response.settings);
      setGlobalConfigs(response.settings);
    };
    fetchGlobalConfigs();
  }, []);

  const shouldShowPrice = () => {
    const userTier = localStorage.getItem("user-tier"); // premium-standard
    const displayFrieghtPrice = globalConfigs.display_freight_prices; // all-none-premium-logged

    return (
      (displayFrieghtPrice === "all" && userTier) ||
      (displayFrieghtPrice === "premium" && userTier === "premium") ||
      (displayFrieghtPrice === "logged" && userTier)
    );
  };

  // Getting the search id and the prevpathname from the request quotation.
  useEffect(() => {
    if (location.state?.quoteLocation)
      setquoteLocation(location.state.quoteLocation);
    if (location.state?.searchId) setsearchId(location.state.searchId);
  }, []);

  useEffect(() => {
    // console.log(quoteLocation, searchId);
  }, [searchId, quoteLocation]);

  // Fetching the search history.
  useEffect(() => {
    if (searchId && quoteLocation.includes("request-quote/")) {
      const fetchSearchHistory = async () => {
        const response = await getSearchHistory(searchId, token);
        if (response.status === 200) {
          setSearchHistory(JSON.parse(response.data.response_body));
        }
      };
      fetchSearchHistory();
    }
  }, [searchId, quoteLocation.includes("request-quote/")]);

  useEffect(() => {
    // console.log(searchHistory);
  }, [searchHistory]);

  return (
    <>
      <ToastContainer />
      <div
        className={styles["home"]}
        style={{ "--sidebar-width": `${sidebarWidth}px` }}
      >
        <FreightSearch handleSearch={handleSearch} isPressed={isPressed} />

        {(show && !searchWarning) ||
        (searchId && quoteLocation.includes("request-quote/")) ? (
          <div className={styles["home-main"]}>
            {isMobile ? null : (
              <FiltersSidebar
                bounds={bounds}
                dispatch={dispatch}
                filters={filters}
                setFiltersApplied={setFiltersApplied}
                shouldShowPrice={shouldShowPrice()}
              />
            )}
            {isMobile ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    // width: 250,
                    justifySelf: "center",
                    alignSelf: "center",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <button
                      className={styles.logo}
                      onClick={() => setBaseModal(true)}
                    >
                      <img className={styles.img} src={Filter} alt="Filter" />
                      <p className={styles.text}>Filter</p>
                    </button>
                  </div>
                  {/* <hr className={styles.separator} />

                  <div>
                    <button
                      className={styles.logo}
                      onClick={() => setSortModal(true)}
                    >
                      <img className={styles.img} src={Arrows} alt="Arrows" />
                      <p className={styles.text}>Sort</p>
                    </button>
                  </div> */}
                </div>
              </>
            ) : null}
            <div className={styles["home-main-content"]}>
              <div className={styles["home-title"]}>
                <h2>Search Results</h2>
                {/* {!isMobile && (
                <SortBy
                  // setIsOpend={setIsOpend}
                  // isOpend={isOpend}
                  options={sortOptions}
                  setMenuOpen={setMenuOpen}
                  label="Sort By"
                />
              )} */}
              </div>
              <div className={styles["home-card-container"]}>
                {isFiltered && filtersApplied
                  ? filterdFreights?.map((freight) => (
                      <FreightCard
                        freight={freight}
                        globalConfigs={globalConfigs}
                        shouldShowPrice={shouldShowPrice()}
                      />
                    ))
                  : freights?.map((freight) => (
                      <FreightCard
                        freight={freight}
                        globalConfigs={globalConfigs}
                        shouldShowPrice={shouldShowPrice()}
                      />
                    ))}
                {searchId &&
                  quoteLocation.includes("request-quote/") &&
                  searchHistory?.map((freight) => (
                    <FreightCard
                      freight={freight}
                      globalConfigs={globalConfigs}
                      shouldShowPrice={shouldShowPrice()}
                    />
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.mainContainer}>
            <img src={LandingIcon} alt="LandingIcon" />
            <p className={styles.title}>Find the best deals right now</p>
            <p className={styles.paragraph}>
              To see the best market rates, please input place of loading, place
              of discharge, select the transport mode and click "Search"
            </p>
            <button
              onClick={() => {
                navigate("/request-quote/shipping-details", {
                  state: { previousPath: location.pathname },
                });
              }}
              className={styles.btn}
            >
              <p className={styles.btnText}>Get a Quote</p>
            </button>
          </div>
        )}
        {isMobile && baseModal ? (
          <VesselSearchFIlterModal
            handleOpen={setBaseModal}
            isOpen={baseModal}
            bounds={bounds}
            dispatch={dispatch}
            filters={filters}
            setFiltersApplied={setFiltersApplied}
            shouldShowPrice={shouldShowPrice()}
          />
        ) : null}
        {isMobile && sortModal ? (
          <SortModal
            handleOpen={setSortModal}
            isOpen={sortModal}
            handleSort={setSortCriterion}
            sortCriterion={sortCriterion}
          />
        ) : null}
      </div>
    </>
  );
}
