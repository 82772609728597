import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    width: 0,
    activeRequestsCount: null,
  },
  reducers: {
    setSidebarWidth: (state, action) => {
      state.width = action.payload;
    },
    setActiveRequestsCount: (state, action) => {
      state.activeRequestsCount = action.payload;
    },
  },
});

export const { setSidebarWidth, setActiveRequestsCount } = sidebarSlice.actions;
export default sidebarSlice.reducer;
