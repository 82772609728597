import React, { useEffect, useLayoutEffect, useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import { FormInput } from "../../components/formInput";
import styles from "./index.module.scss";
import { useHookForm } from "../../hooks/hook-form";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setUser } from "../../store/slices/userSlice";
import {
  FetchSignupAvailability,
  signup,
  verifyEmail,
} from "../../services/authService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainButton from "../../components/mainButton";
import TeuLogo from "../../assets/TEU_logo_metadata.png";
import LoadingSpinner from "../../components/LoadingSpinner";

function EmailVerification() {
  const location = useLocation();
  const { isError, errorMessage } = location.state || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, errors, emailRegister, isDirty, isValid } = useHookForm(
    {
      isEmail: true,
    }
  );

  const handleVerifyEmail = async (data) => {
    setIsLoading(true);
    const response = await verifyEmail({
      email: data.email,
      type: "register_user",
    });
    if (response.status === 200 || response.status === 201) {
      //console.log(response.data);
      //console.log("email sent successfully");
      navigate("/check-your-email", {
        state: { email: data.email, type: "Verification Email" },
      });
    } else {
      //console.log(response.data.error);
      setError(response.data.error);
    }
    setIsLoading(false);
  };
  const handleSignUpAvailability = async () => {
    const response = await FetchSignupAvailability();

    if (
      response.status === 200 &&
      response.data.signup_availability === false
    ) {
      navigate("/login", { replace: true });
      // //console.log(response.data);
    }
  };
  useEffect(() => {
    if (isError) {
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }, []);
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }, [error]);
  useLayoutEffect(() => {
    handleSignUpAvailability();
  }, []);
  return (
    <AuthLayout customBg={styles["custom-bg"]}>
      <ToastContainer />
      <form
        noValidate
        onSubmit={handleSubmit(handleVerifyEmail)}
        className={styles["signup-form"]}
      >
        <div className={styles["signup-container"]}>
          <div className={styles["signup-header"]}>
            <h3 className={styles["signup-title"]}>
              <img
                src={TeuLogo}
                style={{ width: "40px", height: "40px" }}
                alt="Welcome To Teu"
              />{" "}
              Sea Portal
            </h3>
            <h3 className={styles["signup-header-subtitle"]}>Sign Up</h3>
          </div>
          <p className={styles["signup-subtitle"]}>
            Sign up using your email address. First, we'll send you an email
            with a link to verify it is yours.
          </p>
          <div className={styles["signup-field"]}>
            <p>Email</p>
            <FormInput
              name="email"
              error={errors["email"]}
              placeholder={"Email"}
              formRegister={emailRegister}
              labelIcon={true}
              labelIconName={"Email"}
            />
          </div>
        </div>
        <div style={{ gap: "1rem" }}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <MainButton type={"submit"} disabled={!isValid || !isDirty}>
              Sign Up
            </MainButton>
          )}
          <div className={styles["tabs"]}>
            <a className={styles["sign-up"]} onClick={() => navigate("/login")}>
              Already have an account? <span>Sign In</span>
            </a>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
}

export default EmailVerification;
