import React from "react";

function ExpireTab({ color }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1666_7814)">
        <path
          d="M10 0.5C4.48595 0.5 0 4.98595 0 10.5C0 16.014 4.48595 20.5 10 20.5C15.514 20.5 20 16.014 20 10.5C20 4.98595 15.514 0.5 10 0.5ZM10 19.0714C5.27373 19.0714 1.42857 15.2263 1.42857 10.5C1.42857 5.77373 5.27373 1.92857 10 1.92857C14.7263 1.92857 18.5714 5.77373 18.5714 10.5C18.5714 15.2263 14.7263 19.0714 10 19.0714Z"
          fill={color}
        />
        <path
          d="M7 14L14 7"
          stroke={color}
          stroke-width="1.2"
          stroke-linecap="round"
        />
        <path
          d="M7 7L14 14"
          stroke={color}
          stroke-width="1.2"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1666_7814">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ExpireTab;
