import React from "react";

function ToggleTab({ color }) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1797 9.67969H5.91699C5.46393 9.67969 5.09668 10.0469 5.09668 10.5C5.09668 10.9531 5.46393 11.3203 5.91699 11.3203H20.1797C20.6327 11.3203 21 10.9531 21 10.5C21 10.0469 20.6327 9.67969 20.1797 9.67969Z"
        fill={color}
      />
      <path
        d="M20.1797 3.52734H5.91699C5.46393 3.52734 5.09668 3.8946 5.09668 4.34766C5.09668 4.80071 5.46393 5.16797 5.91699 5.16797H20.1797C20.6327 5.16797 21 4.80071 21 4.34766C21 3.8946 20.6327 3.52734 20.1797 3.52734Z"
        fill={color}
      />
      <path
        d="M20.1797 15.832H5.91699C5.46393 15.832 5.09668 16.1993 5.09668 16.6523C5.09668 17.1054 5.46393 17.4727 5.91699 17.4727H20.1797C20.6327 17.4727 21 17.1054 21 16.6523C21 16.1993 20.6327 15.832 20.1797 15.832Z"
        fill={color}
      />
      <path
        d="M1.10742 5.45703C1.71903 5.45703 2.21484 4.96122 2.21484 4.34961C2.21484 3.738 1.71903 3.24219 1.10742 3.24219C0.49581 3.24219 0 3.738 0 4.34961C0 4.96122 0.49581 5.45703 1.10742 5.45703Z"
        fill={color}
      />
      <path
        d="M1.10742 11.6055C1.71903 11.6055 2.21484 11.1097 2.21484 10.498C2.21484 9.88643 1.71903 9.39062 1.10742 9.39062C0.49581 9.39062 0 9.88643 0 10.498C0 11.1097 0.49581 11.6055 1.10742 11.6055Z"
        fill={color}
      />
      <path
        d="M1.10742 17.7617C1.71903 17.7617 2.21484 17.2659 2.21484 16.6543C2.21484 16.0427 1.71903 15.5469 1.10742 15.5469C0.49581 15.5469 0 16.0427 0 16.6543C0 17.2659 0.49581 17.7617 1.10742 17.7617Z"
        fill={color}
      />
    </svg>
  );
}

export default ToggleTab;
