import React from "react";

const NextSliderArrow = ({ disabled, style }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g filter="url(#filter0_d_2888_5481)">
        <rect
          width="20"
          height="20"
          rx="5.55533"
          transform="matrix(1 0 0 -1 2 21.1992)"
          fill="white"
        />
        <rect
          x="0.347208"
          y="-0.347208"
          width="19.3056"
          height="19.3056"
          rx="5.20812"
          transform="matrix(1 0 0 -1 2 20.5048)"
          stroke={disabled ? "#808B8C" : "#06329D"}
          stroke-width="0.694416"
        />
        <path
          d="M7.95117 11.1973H16.0527M16.0527 11.1973L12.0019 15.248M16.0527 11.1973L12.0019 7.14653"
          stroke={disabled ? "#808B8C" : "#06329D"}
          stroke-width="1.15967"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2888_5481"
          x="0"
          y="0.199219"
          width="24"
          height="24"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2888_5481"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2888_5481"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default NextSliderArrow;
