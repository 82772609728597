import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useHookForm } from "../../../hooks/hook-form";
import FormFooter from "../../formFooter";
import RequestQuoteInput from "../input";
import Dropdown from "../../dropdown";
import styles from "./index.module.scss";

const RequestQuoteStepThree = () => {
  const {
    inputsData,
    stepTwoData,
    stepOneData,
    stepThreeData,
    setStepThreeData,
    infoIcon,
    isPolTrucking,
    isPodTrucking,
  } = useOutletContext();

  const {
    handleSubmit,
    errors,
    originStateRegister,
    destinationStateRegister,
    originAddressRegister,
    destinationAddressRegister,
  } = useHookForm({
    isOriginState: isPolTrucking(),
    isOriginAddress: isPolTrucking(),
    isDestinationState: isPodTrucking(),
    isDestinationAddress: isPodTrucking(),
    values: {
      // originState: stepTwoData?.originState ?? "",
      // destinationState: stepTwoData?.destinationState ?? "",
      // originAddress: stepTwoData?.originAddress ?? "",
      // destinationAddress: stepTwoData?.destinationAddress ?? "",
    },
    defaultValues: {
      // originState: stepTwoData?.originState ?? "",
      // destinationState: stepTwoData?.destinationState ?? "",
      // originAddress: stepTwoData?.originAddress ?? "",
      // destinationAddress: stepTwoData?.destinationAddress ?? "",
    },
  });

  // useEffect(() => {
  //   //console.log("first time");
  //   return () => //console.log("destroyed");
  // }, []);

  const navigate = useNavigate();

  const handleFormSubmit = (data) => {
    //console.log("data", data);
    setStepThreeData({ ...stepThreeData, ...data });
    navigate(`/request-quote/cargo-details`);
  };

  useEffect(() => {
    //console.log("3", stepThreeData);
  }, [stepThreeData]);

  const handleOriginCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectOption = inputsData?.countries.find(
      (option) => option.name === value
    );
    setStepThreeData({ ...stepThreeData, originCountry: selectOption });
  };
  const handleDestinationCountryChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectOption = inputsData?.countries.find(
      (option) => option.name === value
    );
    setStepThreeData({ ...stepThreeData, destinationCountry: selectOption });
  };
  const handleOriginState = (event) => {
    const {
      target: { value },
    } = event;
    setStepThreeData({ ...stepThreeData, originState: value });
  };
  const handleOriginAdsress = (event) => {
    const {
      target: { value },
    } = event;
    setStepThreeData({ ...stepThreeData, originAddress: value });
  };
  const handleDestinationState = (event) => {
    const {
      target: { value },
    } = event;
    setStepThreeData({ ...stepThreeData, destinationState: value });
  };
  const handleDestinationAdsress = (event) => {
    const {
      target: { value },
    } = event;
    setStepThreeData({ ...stepThreeData, destinationAddress: value });
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className={styles["form-container"]}
      noValidate
    >
      <div
        className={`${styles["form-container-row"]} ${styles["form-container-row-title"]}`}
      >
        <h2>Trucking</h2>
      </div>
      <div className={`${styles["form-container-row"]}`}>
        <Dropdown
          label={"Origin Country*"}
          value={stepThreeData.originCountry?.name ?? ""}
          onChange={handleOriginCountryChange}
          placeholder={"Select country"}
          options={inputsData?.countries}
          placeholderShowed={true}
          disabled={!isPolTrucking()}
        />
        <RequestQuoteInput
          placeholder="Enter state"
          label="Origin State*"
          name="originState"
          error={errors["originState"]}
          formRegister={originStateRegister}
          onChange={handleOriginState}
          disabled={!isPolTrucking()}
          value={stepThreeData?.originState ?? ""}
        />
      </div>
      <div className={`${styles["form-container-row"]}`}>
        <RequestQuoteInput
          placeholder="Enter full address"
          label="Origin Address*"
          name="originAddress"
          error={errors["originAddress"]}
          formRegister={originAddressRegister}
          onChange={handleOriginAdsress}
          disabled={!isPolTrucking()}
          value={stepThreeData?.originAddress ?? ""}
        />
      </div>

      <div className={`${styles["form-container-row"]} `}>
        <Dropdown
          label={"Destination Country*"}
          value={stepThreeData.destinationCountry?.name ?? ""}
          onChange={handleDestinationCountryChange}
          placeholder={"Select country"}
          options={inputsData?.countries}
          placeholderShowed={true}
          disabled={!isPodTrucking()}
        />
        <RequestQuoteInput
          placeholder="Enter state"
          label="Destination State*"
          name="destinationState"
          error={errors["destinationState"]}
          formRegister={destinationStateRegister}
          onChange={handleDestinationState}
          disabled={!isPodTrucking()}
          value={stepThreeData?.destinationState ?? ""}
        />
      </div>
      <div className={`${styles["form-container-row"]} `}>
        <RequestQuoteInput
          placeholder="Enter full address"
          label="Destination Address*"
          name="destinationAddress"
          error={errors["destinationAddress"]}
          formRegister={destinationAddressRegister}
          onChange={handleDestinationAdsress}
          disabled={!isPodTrucking()}
          value={stepThreeData?.destinationAddress ?? ""}
        />
      </div>

      <div className={styles["form-container-info"]}>
        <img src={infoIcon} alt="info" />
        <p>
          Please enter full origin address if you have selected “POL Trucking”,
          and full destination address if you have selected “POD Trucking” from
          the previous step, so we can quote trucking services correctly.
        </p>
      </div>
      <FormFooter
        disableBackButton={false}
        disableNextButton={
          !stepOneData.pol ||
          (isPolTrucking() && !stepThreeData.originCountry) ||
          (isPodTrucking() && !stepThreeData.destinationCountry)
        }
        handleBackButton={() => navigate(-1)}
        buttonText="Next"
      />
    </form>
  );
};

export default RequestQuoteStepThree;
