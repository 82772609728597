import styles from "./index.module.scss";

const HorizontalTabs = ({
  activeTab,
  tabs,
  setActiveTab,
  role,
  layout = "user",
  clientRequests = false,
}) => {
  return (
    <div className={styles["container"]}>
      {tabs.map((item) => (
        <button
          onClick={() => setActiveTab(item.slug)}
          key={item.slug}
          className={`${styles["container-tab-container"]} ${
            item.slug == activeTab ? styles["container-tab-active"] : ""
          }`}
        >
          {role == "sales" && (
            <div style={{ marginBlock: "0.25rem" }}>
              <item.icon
                color={item.slug == activeTab ? "#157FFB" : "#ADB8CC"}
              />
            </div>
          )}
          {clientRequests && (
            <div style={{ marginBlock: "0.25rem" }}>
              <item.icon
                color={item.slug == activeTab ? "#157FFB" : "#ADB8CC"}
              />
            </div>
          )}
          <h6>{item.name}</h6>
          {layout === "sales"
            ? (item.slug === "accepted" || item.slug === "pending") &&
              item.count !== 0 && (
                <div className={styles["container-tab-container-count"]}>
                  <p>{item.count}</p>
                </div>
              )
            : item.slug === "active" &&
              item.count !== 0 && (
                <div className={styles["container-tab-container-count"]}>
                  <p>{item.count}</p>
                </div>
              )}
        </button>
      ))}
    </div>
  );
};

export default HorizontalTabs;
