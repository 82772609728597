import { InputLabel, MenuItem, Select } from "@mui/material";
import styles from "./index.module.scss";
import icon from "../../assets/grey-chevron-down.svg";

const Dropdown = ({
  inputIcon = icon,
  label,
  value = "",
  onChange,
  placeholder,
  options,
  containerStyle,
  itemStyle,
  labelStyle,
  disabled,
  className,
  placeholderShowed = false,
  // formRegister,
}) => {
  const dropdownContainerStyle = {
    width: "100%",
    height: "2.9rem",
    background: "#fff",
    border: "0.8px solid #d9dcdc",
    borderRadius: "6px",
    padding: "0px !important",
    outline: "none !important",
    fontSize: "0.875rem !important",
    marginTop: "2px !important",
    ...containerStyle,
    // borderColor: trigger && !selectedGrade ? "#1F1F21" : "#1F1F21",
    "& *": {
      fontFamily: "Poppins !important",
      fontSize: "0.875rem !important",
      border: "0 !important",
      // color: "#1F1F21 !important",
      // paddingTop: "px !important",
      // paddingBottom: "0px !important",
    },
    "& p": {
      fontSize: "0.875rem !important",
      color: value ? "inherit" : "#bababa",
      textTransform: "none",
    },
    "& .MuiOutlinedInput-input": {
      // paddingBlock: "55px !important"
    },
  };

  const menuItemStyle = {
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    border: "0 !important",
    ...itemStyle,
  };

  const labelStyles = {
    fontSize: "0.875rem !important",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#000",
    padding: "0 !important",
    ...labelStyle,
  };

  return (
    <div
      className={`${styles["container"]} ${className} ${
        disabled ? styles["container-disabled"] : styles[""]
      }`}
    >
      <InputLabel style={labelStyles} id="demo-multiple-chip-label-grade">
        {label}
      </InputLabel>
      <Select
        // {...formRegister}
        IconComponent={() => (
          <img src={inputIcon} alt="" className={styles["icon"]} />
        )}
        label={label}
        labelId="demo-multiple-chip-label-grade"
        id="demo-multiple-chip-grade"
        sx={dropdownContainerStyle}
        displayEmpty
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
          // getContentAnchorEl: null,
        }}
      >
        <MenuItem
          sx={menuItemStyle}
          value={placeholderShowed ? "" : placeholder}
        >
          <p>{placeholder}</p>
        </MenuItem>
        {options
          ?.sort((a, b) => a.name.localeCompare(b.name))
          ?.map((item) => (
            <MenuItem key={item.id} value={item.name} sx={menuItemStyle}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default Dropdown;
