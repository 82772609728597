// CustomModal.jsx

import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "../../assets/Vector.png";
import styles from "./index.module.scss";
import DateInput from "../dateInput";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
function SortModal({ handleOpen, isOpen, handleSort, sortCriterion }) {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState("");
  const handleClose = () => {
    handleOpen(false);
  };

  const handleChange = (newValue) => {
    setValue(newValue.target.value);
    handleSort(newValue.target.value);
    handleClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xl" // Adjust width as needed
      fullWidth
      PaperProps={{
        className: styles.customDialogPaper,
      }}
      classes={{ paper: styles.customDialogRoot }}
    >
      <DialogTitle>
        <div className={styles.titleBar}>
          <span>Sort by</span>
          <IconButton
            onClick={handleClose}
            size="small"
            className={styles.closeButton}
          >
            <img src={CloseIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.container}>
          <div className={styles.radioItem}>
            <input
              type="radio"
              id="lowestPrice"
              name="lowestPrice"
              value="lowestPrice"
              checked={sortCriterion === "lowestPrice"}
              onChange={handleChange}
              className={styles.radioInput}
            />
            <label className={styles.radioLabel}>Lowest price</label>
          </div>
          <div className={styles.radioItem}>
            <input
              type="radio"
              id="highestPrice"
              name="highestPrice"
              value="highestPrice"
              checked={sortCriterion === "highestPrice"}
              onChange={handleChange}
              className={styles.radioInput}
            />
            <label className={styles.radioLabel}>Highest price</label>
          </div>
          <div className={styles.radioItem}>
            <input
              type="radio"
              id="transitTime"
              name="transitTime"
              value="transitTime"
              checked={sortCriterion === "transitTime"}
              onChange={handleChange}
              className={styles.radioInput}
            />
            <label className={styles.radioLabel}>Soonest arrival</label>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SortModal;
