import React, { useState } from "react";
import styles from "./index.module.scss";
import Upload from "../../assets/upload.svg";
import X from "../../assets/x.svg";
import { useDroppable } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import DateInput from "../dateInput";
import MainButton from "../mainButton";
import { selectOpenedRequestID } from "../../store/slices/salesRequestsSlice";
import { useSelector } from "react-redux";
import { sendOffer } from "../../services/contentService";
import { use } from "i18next";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";

const OfferForm = ({
  setShowSendOfferModal,
  setShowSuccessModal,
  fetchRequests,
}) => {
  const [isDropped, setIsDropped] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [freightPrice, setFreightPrice] = useState(null);
  const [date, setDate] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isOver, setNodeRef } = useDroppable({
    id: "file-drop-area",
  });
  const navigate = useNavigate();
  const [files, setFiles] = useState(null);
  const select = useSelector;
  const ID = select(selectOpenedRequestID);
  const token = localStorage.getItem("token");
  const style = {
    backgroundColor: isDragging ? "#e6f7ff" : "#fafafa",
    border: isDragging ? "2px dashed #007bff" : "2px dashed #d9d9d9",
  };
  function handleDragEnd(event) {
    if (event.over && event.over.id === "droppable") {
      setIsDropped(true);
    }
  }
  const handleDrop = (event) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFiles(selectedFile);
      setError(null);
      setIsDragging(false);
    } else {
      setError("Only PDF files are allowed");
    }
  };

  const handleFileChange = (event) => {
    event.preventDefault();
    const newFile = event.target.files[0];
    if (newFile && newFile.size > 10 * 1024 * 1024) {
      setError("File size exceeds 10 MB. Please choose a smaller file.");
      event.target.value = ""; // Clear the file input
      return;
    }
    if (newFile.type === "application/pdf") {
      setIsDragging(false);
    } else {
      setError("Only PDF files are allowed");
    }
    if (!!newFile) {
      setError("");
    }
    setFiles(newFile);
  };
  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleFileDrop = (event) => {
    event.preventDefault();
    setIsDragging(false); // Reset dragging state when file is dropped
    const newFiles = Array.from(event.dataTransfer.files); // Convert FileList to array
    setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append to the existing files
  };
  const handleRemoveFile = () => {
    setFiles(null);
  };
  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (!date || !files) {
      setError("Expiry Date and File are required.");
      return;
    }
    const formData = new FormData();
    formData.append("attachment", files);
    if (freightPrice) {
      formData.append("freight_price", freightPrice);
    }
    formData.append("expiration_date", date);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `auth/sales/offer/send?request_id=${ID}`,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: formData,
      formData: formData,
    };

    const response = await sendOffer(config);
    if (response.status === 200) {
      fetchRequests();
      setShowSendOfferModal(false);
      setShowSuccessModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
    setIsLoading(false);
  };
  const handleDateFormat = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date)) {
      throw new Error("Invalid date string");
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  return (
    <form className={styles.formContainer} onSubmit={handleSubmit}>
      <div className={styles.row}>
        <DateInput
          iconColor={"dark"}
          handleChange={(date) => setDate(handleDateFormat(date))}
          label="Expiry Date"
          placeholderShowed={false}
          className={styles.dateInput}
          required // Marked as required
          disablePast={true}
        />

        <div className={styles.formGroup}>
          <p>Freight Price</p>
          <div className={styles["formGroup-input"]}>
            <input
              type="number"
              placeholder="Enter freight price"
              value={freightPrice}
              onChange={(e) => setFreightPrice(e.target.value)}
            />
            <span>US Dollars</span>
          </div>
        </div>
      </div>

      <div className={styles.formGroup}>
        <label>Attachments</label>

        <DndContext onDragEnd={handleDrop}>
          <div
            ref={setNodeRef}
            className={styles.fileDropZone}
            style={style}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <input
              type="file"
              accept="application/pdf"
              style={{ display: "none" }}
              id="file-upload-input"
              onChange={handleFileChange}
              required // Marked as required
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            <label htmlFor="file-upload-input">
              <div>
                <img
                  src={Upload}
                  alt="Upload Icon"
                  style={{ marginBottom: "10px" }}
                />
                <p>Drop your files here</p>
                <p>PDFs only with max 100 MB</p>
              </div>
            </label>
          </div>
          {files?.name && (
            <ul>
              <li className={styles["list-item"]}>{files.name} </li>
            </ul>
          )}
        </DndContext>
      </div>
      <div className={styles["formContainer-button"]}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <MainButton
            className={styles["formContainer-button-btn"]}
            type="submit" // Button submits the form
            disabled={!date || !files}
          >
            Send
          </MainButton>
        )}
      </div>
    </form>
  );
};

export default OfferForm;
