import { useNavigate, useOutletContext } from "react-router-dom";
import FormFooter from "../../formFooter";
import TextArea from "../../textArea";
import RequestQuoteInput from "../input";
import styles from "./index.module.scss";
import { useHookForm } from "../../../hooks/hook-form";
import { useEffect } from "react";
import { postRequestQuote } from "../../../services/contentService";
import { loadState } from "../../../helpers/sessionStorage";
import { useSelector } from "react-redux";
import { userData } from "../../../store/slices/userSlice";
import { useState } from "react";
import Box from "@mui/material/Box";
import LoadingSpinner from "../../LoadingSpinner";

const RequestQuoteStepFive = () => {
  const {
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFiveData,
    setStepFiveData,
    infoIcon,
  } = useOutletContext();
  const { handleSubmit, errors, textAreaRegister, targetRateRegister } =
    useHookForm({
      isTextArea: true,
      isTargetRate: true,
      values: {
        textArea: stepFiveData?.info,
        targetRate: stepFiveData?.targetRate,
      },
      defaultValues: {
        textArea: stepFiveData?.info,
        targetRate: stepFiveData?.targetRate,
      },
    });
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  // const { token } = useSelector(userData);
  const token = localStorage.getItem("token");
  //console.log("Token retrieved:", token);

  useEffect(() => {
    //console.log("user", loadState("user"));
    //console.log("token", loadState("token"));
  }, []);

  // Readiness Date New Format Function.
  function formatDateToISO8601(currentDate) {
    const date = new Date(currentDate);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // Add leading zeros if necessary
    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;
    if (hours < 10) hours = `0${hours}`;
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds < 10) seconds = `0${seconds}`;

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const handleFormSubmit = async (data) => {
    //console.log("data", data);
    setStepFiveData({ ...stepFiveData, ...data });
    setSubmitLoading(true);
    const payload = {
      eta: stepOneData?.eta,
      etd: stepOneData?.etd,
      search_id: stepOneData?.search_id,
      shipping_line: stepOneData?.shippingLine?.id,
      pod_free_time: Number(stepOneData?.podFreeTime),
      port_of_loading: stepOneData?.pol?.port_id,
      port_of_discharge: stepOneData?.pod?.port_id,
      required_services: stepOneData?.requiredServices?.map((item) => {
        return item.id;
      }),
      invoice_value: Number(stepOneData?.invoiceValue) ?? null,

      readiness_date: formatDateToISO8601(stepTwoData?.readinessDate),
      commodity: stepTwoData?.commodity,
      dangerous_cargo: stepTwoData?.dangerousCargo,
      containers: stepTwoData?.containers?.map((item) => {
        return {
          type: item.type,
          number: item.count,
          weight: item.weight,
        };
      }),

      origin_country: stepThreeData?.originCountry?.id ?? null,
      origin_state: stepThreeData?.originState ?? null,
      origin_address: stepThreeData?.originAddress ?? null,
      destination_country: stepThreeData?.destinationCountry?.id ?? null,
      destination_state: stepThreeData?.destinationState ?? null,
      destination_address: stepThreeData?.destinationAddress ?? null,
      additional_info: data?.textArea ?? null,
      target_rate: Number(data?.targetRate) ?? null,
    };
    !stepOneData?.eta && delete payload.eta;
    !stepOneData?.etd && delete payload.etd;
    !stepOneData?.search_id && delete payload.search_id;
    !stepOneData?.shippingLine?.id && delete payload.shipping_line;
    !stepOneData?.podFreeTime && delete payload.pod_free_time;
    !stepThreeData?.originCountry?.id && delete payload.origin_country;
    !stepThreeData?.destinationCountry?.id &&
      delete payload.destination_country;
    !data?.textArea && delete payload.additional_info;
    !data?.targetRate && delete payload.target_rate;
    const response = await postRequestQuote(payload, token);
    //console.log("success", response);
    if (response.status === 200 || response.status === 201) {
      navigate(`/request-quote/success`, {
        state: response?.data?.id,
      });
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };

  const handleTargetRateChange = (event) => {
    const {
      target: { value },
    } = event;
    setStepFiveData({ ...stepFiveData, targetRate: value });
  };
  const handleTextAreaChange = (event) => {
    const {
      target: { value },
    } = event;
    setStepFiveData({ ...stepFiveData, textArea: value });
  };

  useEffect(() => {
    //console.log("step five", stepFiveData);
  }, [stepFiveData]);
  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className={styles["form-container"]}
    >
      <div
        className={`${styles["form-container-row"]} ${styles["form-container-row-title"]}`}
      >
        <h2>other</h2>
      </div>
      <RequestQuoteInput
        placeholder="Enter your target rate"
        label="Target Rate"
        unit="US Dollars"
        error={errors["targetRate"]}
        formRegister={targetRateRegister}
        name="targetRate"
        isInsurance={true}
        onChange={handleTargetRateChange}
        value={stepFiveData?.targetRate ?? ""}
      />
      <TextArea
        label={"Additional Information"}
        placeholder={"Anything else we need to know?"}
        error={errors["textArea"]}
        formRegister={textAreaRegister}
        name={"textArea"}
        onChange={handleTextAreaChange}
        value={stepFiveData?.textArea ?? ""}
      />
      <div className={styles["form-container-info"]}>
        <img src={infoIcon} alt="info" />
        <p>
          Target rate is your expected overall pricing in US dollars. Also,
          enter any additional information we should consider when preparing the
          quotation for you.
        </p>
      </div>
      {!submitLoading ? (
        <FormFooter
          disableBackButton={false}
          disableNextButton={!stepOneData.pol || !stepTwoData.readinessDate}
          handleBackButton={() => navigate(-1)}
          buttonText="Submit"
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <LoadingSpinner />
        </Box>
      )}
    </form>
  );
};

export default RequestQuoteStepFive;
