import React from "react";
import greyChevronRight from "../../assets/grey-chevron-right.svg";
import tick from "../../assets/tick.svg";
import styles from "./index.module.scss";

const FormStepper = ({ steps, currentStep }) => {
  return (
    <div className={styles["form-stepper"]}>
      {steps.map((item) => (
        <React.Fragment key={item.slug}>
          {(!item.isPolTrucking || !item.isPodTrucking) && (
            <div key={item.slug} className={styles["form-stepper-step"]}>
              <div
                className={`${styles["form-stepper-step-indicator"]} ${
                  currentStep === item.step
                    ? styles["form-stepper-step-indicator-active"]
                    : currentStep > item.step
                    ? styles["form-stepper-step-indicator-completed"]
                    : ""
                }`}
              >
                {currentStep > item.step && <img src={tick} alt="completed" />}
              </div>
              <p
                className={`${styles["form-stepper-step-name"]} ${
                  currentStep === item.step || currentStep > item.step
                    ? styles["form-stepper-step-name-active"]
                    : ""
                }`}
              >
                {item.name}
              </p>
            </div>
          )}
          {item.step < steps.length - 1 &&
            (!item.isPolTrucking || !item.isPodTrucking) &&
            !item.except && <img src={greyChevronRight} alt="" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default FormStepper;
