import React, { useState } from "react";
import styles from "./index.module.scss";
import search from "../../assets/search.svg";
function SearchBar({
  handleSearch,
  setSearchTerm,
  searchTerm,
  placeholder = "Search by request ID",
}) {
  const onChange = (e) => {
    setSearchTerm(e.target.value);
    // handleSearch(e.target.value);
  };

  return (
    <div className={styles["search-bar"]}>
      <img src={search} alt="search" />
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={onChange}
        className={styles["search-bar-input"]}
      />
    </div>
  );
}

export default SearchBar;
