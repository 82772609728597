import React from "react";

function Search({ isPressed }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6517 20.4481L16.4531 14.2495C17.4656 12.9613 18.0702 11.338 18.0702 9.57612C18.0702 5.39861 14.6716 2 10.494 2C6.31658 2 2.91797 5.39861 2.91797 9.57606C2.91797 13.7535 6.31658 17.1521 10.494 17.1521C12.2559 17.1521 13.8792 16.5476 15.1674 15.5351L21.3661 21.7337C21.5436 21.9113 21.7762 22 22.0089 22C22.2416 22 22.4742 21.9113 22.6517 21.7337C23.0067 21.3787 23.0067 20.8031 22.6517 20.4481ZM4.73615 9.57606C4.73615 6.40115 7.31913 3.81818 10.494 3.81818C13.669 3.81818 16.252 6.40115 16.252 9.57606C16.252 12.751 13.669 15.3339 10.494 15.3339C7.31913 15.3339 4.73615 12.751 4.73615 9.57606Z"
        fill={"#fff"}
      />
    </svg>
  );
}

export default Search;
