import React, { useEffect, useRef, useState } from "react";
import BaseModal from "../BaseModal";
import styles from "./index.module.scss";
import MainButton from "../mainButton";
import tick from "../../assets/tick.svg";

import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment/moment";
import { acceptOffer } from "../../services/contentService";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const QuoteModal = ({
  handleClose,
  isOpen,
  title,
  id,
  pdfUrl,
  expiration_date,
  request_status,
  role = "user",
  setIsOpen,
  getRequests,
}) => {
  const containerRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        const newScale = Math.min(clientWidth / 60, clientHeight / 1200);
        setScale(newScale);
      }
    };

    updateScale();
    window.addEventListener("resize", updateScale);
    return () => window.removeEventListener("resize", updateScale);
  }, []);
  const token = localStorage.getItem("token");
  //console.log(pdfUrl);
  const downloadPdf = () => {
    const pdfPath = pdfUrl;
    const link = document.createElement("a");
    link.href = pdfPath;
    link.target = "_blank";
    link.download = "Quote.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleAccept = async () => {
    setIsLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/request/confirm?request_id=${id}&status=accepted`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await acceptOffer(config);

    if (response?.status === 200) {
      setIsOpen(false);
      getRequests();
      //console.log(response.data); // log the response data
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
    setIsLoading(false);
  };
  return (
    <BaseModal
      handleClose={handleClose}
      isOpen={isOpen}
      title={title}
      baseModalHeight={styles.baseModalHeight}
    >
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <p>
            <span>Valid Until: </span>
            {moment(expiration_date).format("D MMM YYYY")}
          </p>
          <button onClick={downloadPdf} className={styles.downloadBtn}>
            View as PDF
          </button>
        </div>
        <div ref={containerRef}>
          <Document
            className={styles.pdfContainer}
            file={pdfUrl}
            renderMode="canvas"
            onLoadSuccess={() => {
              //console.log("loaded successfully");
            }}
          >
            <div className={styles.croppedPageContainer} ref={containerRef}>
              <Page
                pageNumber={1}
                // size="A3"
                renderTextLayer={false}
                renderAnnotationLayer={false}
                scale={1}
                className={styles.pageContainer}
                renderMode="canvas"
              />
            </div>
          </Document>
        </div>
        {role === "user" && (
          <div className={styles.buttonsContainer}>
            {request_status === "accepted" ? (
              <div className={styles.accepted}>
                <MainButton disabled={true}>
                  <span className={styles.completed}>
                    <img src={tick} alt="completed" />
                  </span>
                  Accepted
                </MainButton>
              </div>
            ) : request_status === "expired" ? (
              <MainButton disabled={true}>Accept</MainButton>
            ) : (
              <>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <MainButton handleSubmit={handleAccept}>Accept</MainButton>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default QuoteModal;
