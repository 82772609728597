import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HorizontalTabs from "../../components/HorizontalTabs";
import { getAllRequests } from "../../services/contentService";
import { userData } from "../../store/slices/userSlice";
import styles from "./index.module.scss";
import RequestCardUpdated from "../../components/requestCardUpdated";
import Pagination from "../../components/Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTimelineSkeleton from "../../components/DashboardTimeLineSkeleton";
import QuoteModal from "../../components/quoteModal";
import ToggleTab from "../../components/SVGs/ToggleTab";
import ClockTab from "../../components/SVGs/ClockTab";
import TrueTab from "../../components/SVGs/TrueTab";
import ArrowTab from "../../components/SVGs/ArrowTab";
import ExpireTab from "../../components/SVGs/ExpireTab";
import { setActiveRequestsCount } from "../../store/slices/sidebarSlice";

const Requests = () => {
  const [activeTab, setActiveTab] = useState("");
  const [requests, setRequests] = useState(null);
  const [requestInfo, setRequestInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { requestId } = location.state || {};
  console.log("requestId", requestId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabs = [
    {
      name: "All",
      slug: "",
      icon: ToggleTab,
    },
    {
      name: "Pending",
      slug: "pending",
      icon: ArrowTab,
    },
    {
      name: "Active",
      slug: "active",
      icon: ClockTab,
      count: requests?.count?.active,
    },
    {
      name: "Accepted",
      slug: "accepted",
      icon: TrueTab,
    },
    {
      name: "Expired",
      slug: "expired",
      icon: ExpireTab,
    },
  ];

  const getRequests = async () => {
    setLoading(true);
    const response = await getAllRequests(
      activeTab
        ? `status=${activeTab}&page=${pageNumber}`
        : `page=${pageNumber}`,
      token
    );
    if (response.status === 200) {
      //console.log(response.data?.count?.total_count);
      setRequests(response.data);
      dispatch(setActiveRequestsCount(response?.data?.count?.active));
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }

    setLoading(false);
  };
  const getRequestInfo = async () => {
    setLoading(true);
    const response = await getAllRequests(`id=${requestId}`, token);
    if (response.status === 200) {
      setRequestInfo(response.data.data[0]);
      setShowOfferModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    getRequests();
    setPageNumber(1);
  }, [activeTab]);
  useEffect(() => {
    getRequests();
  }, [pageNumber]);
  useEffect(() => {
    if (requestId) {
      getRequestInfo();
    }
  }, [requestId]);

  const sidebarWidth = useSelector((state) => state.sidebar.width);

  return (
    <div
      className={styles["requests"]}
      style={{ "--sidebar-width": `${sidebarWidth}px` }}
    >
      <HorizontalTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        clientRequests={true}
      />
      {loading ? (
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            rowGap: "0.7rem",
          }}
        >
          <DashboardTimelineSkeleton className={styles["skeleton-styles"]} />
          <DashboardTimelineSkeleton className={styles["skeleton-styles"]} />
          <DashboardTimelineSkeleton className={styles["skeleton-styles"]} />
        </div>
      ) : (
        <div className={styles["requests-container"]}>
          {requests?.data?.map((item) => (
            <RequestCardUpdated
              key={item.id}
              request={item}
              getRequests={getRequests}
            />
          ))}
        </div>
      )}
      <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        itemsPerPage={requests && requests?.count?.division}
        totalItems={requests && requests?.count?.total_count}
      />
      {showOfferModal && requestInfo && (
        <QuoteModal
          handleClose={() => setShowOfferModal(false)}
          isOpen={showOfferModal}
          title={"Quotation Details"}
          id={requestInfo.id}
          pdfUrl={requestInfo.attachment}
          expiration_date={requestInfo.expiration_date}
          request_status={requestInfo.request_status}
          role="sales"
        />
      )}
    </div>
  );
};

export default Requests;
