import React from "react";

function TrueTab({ color }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1666_7807)">
        <path
          d="M14.3362 6.42017L7.68824 13.068L5.66372 11.0436C5.38475 10.7646 4.93253 10.7646 4.65356 11.0436C4.37459 11.3226 4.37459 11.7748 4.65356 12.0537L7.18317 14.5833C7.32261 14.7229 7.50539 14.7926 7.68824 14.7926C7.87102 14.7926 8.05388 14.7228 8.19332 14.5833L15.3463 7.43025C15.6253 7.15129 15.6253 6.69906 15.3463 6.42009C15.0675 6.14121 14.6151 6.14121 14.3362 6.42017Z"
          fill={color}
        />
        <path
          d="M10 0.5C4.48595 0.5 0 4.98595 0 10.5C0 16.014 4.48595 20.5 10 20.5C15.514 20.5 20 16.014 20 10.5C20 4.98595 15.514 0.5 10 0.5ZM10 19.0714C5.27373 19.0714 1.42857 15.2263 1.42857 10.5C1.42857 5.77373 5.27373 1.92857 10 1.92857C14.7263 1.92857 18.5714 5.77373 18.5714 10.5C18.5714 15.2263 14.7263 19.0714 10 19.0714Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1666_7807">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TrueTab;
