import React, { useEffect, useState } from "react";
import moment from "moment";
import styles from "./index.module.scss";
import salesRequests, {
  selectOpenedRequestID,
} from "../../store/slices/salesRequestsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getRequestInfo } from "../../services/contentService";
import { useNavigate } from "react-router-dom";
function RequestDetails() {
  const select = useSelector;
  const ID = select(selectOpenedRequestID);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [requestInfo, setRequestInfo] = useState(null);
  const dispatch = useDispatch();
  const fetchRequestDetails = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/request?id=${ID}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await getRequestInfo(config);

    if (response?.status === 200) {
      setRequestInfo(response?.data?.data);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    if (ID) {
      fetchRequestDetails();
    }
  }, [ID]);
  useEffect(() => {
    dispatch(
      salesRequests.setClientName(
        `${requestInfo?.client_fname + ` ` + requestInfo?.client_lname}`
      )
    );
    dispatch(salesRequests.setAttachment(requestInfo?.attachment));
  }, [ID]);
  return (
    <div className={styles["card-collapse"]}>
      {/* Shipping Informations */}
      <div className={styles["card-collapse-step"]}>
        <h3>Shipping</h3>
        <div className={styles["card-collapse-step-data"]}>
          {requestInfo?.port_of_loading && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                Port Of Loading (POL)
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.port_of_loading}
              </p>
            </div>
          )}
          {requestInfo?.port_of_discharge && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                Port Of Discharge (POD)
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.port_of_discharge}
              </p>
            </div>
          )}
          {requestInfo?.shipping_line?.name && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                Shipping Line
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.shipping_line?.name}
              </p>
            </div>
          )}
          {requestInfo?.pod_free_time > 0 && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                POD Free Time
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.pod_free_time} days
              </p>
            </div>
          )}
          {requestInfo?.required_services && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                Required Services
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.required_services?.map((item) => (
                  <>
                    {item.title}
                    <br />
                  </>
                ))}
              </p>
            </div>
          )}
          {requestInfo?.invoice_value > 0 && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                Invoice Value
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.invoice_value}$
              </p>
            </div>
          )}
        </div>
      </div>
      {/* Truking Informations */}
      {(requestInfo?.origin_country || requestInfo?.destination_country) && (
        <>
          <div className={styles["card-collapse-separator"]} />
          <div className={styles["card-collapse-step"]}>
            <h3>Trucking</h3>
            {/* Origin Details Informations */}
            {requestInfo?.origin_country && (
              <div className={styles["card-collapse-step"]}>
                <div className={styles["card-collapse-step-data"]}>
                  {requestInfo?.origin_country && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Origin Country
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {requestInfo?.origin_country}
                      </p>
                    </div>
                  )}
                  {requestInfo?.origin_state && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Origin State
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {requestInfo?.origin_state}
                      </p>
                    </div>
                  )}
                  {requestInfo?.origin_address && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        origin Address
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {requestInfo?.origin_address}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* Destination Details Informations */}
            {requestInfo?.destination_country && (
              <div className={styles["card-collapse-step"]}>
                <div className={styles["card-collapse-step-data"]}>
                  {requestInfo?.destination_country && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Destination Country
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {requestInfo?.destination_country}
                      </p>
                    </div>
                  )}
                  {requestInfo?.destination_state && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Destination State
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {requestInfo?.destination_state}
                      </p>
                    </div>
                  )}
                  {requestInfo?.destination_address && (
                    <div className={styles["card-collapse-step-data-item"]}>
                      <p
                        className={styles["card-collapse-step-data-item-label"]}
                      >
                        Destination Address
                      </p>
                      <p
                        className={styles["card-collapse-step-data-item-value"]}
                      >
                        {requestInfo?.destination_address}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <div className={styles["card-collapse-separator"]} />
      {/* Cargo Details Informations */}
      <div className={styles["card-collapse-step"]}>
        <h3>Cargo Details</h3>
        <div className={styles["card-collapse-step-data"]}>
          <div className={styles["card-collapse-step-data-item"]}>
            <p className={styles["card-collapse-step-data-item-label"]}>
              Readiness Date
            </p>
            <p className={styles["card-collapse-step-data-item-value"]}>
              {moment(requestInfo?.readiness_date).format("D MMM YYYY")}
            </p>
          </div>
          {requestInfo?.commodity && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                Commodity
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.commodity}
              </p>
            </div>
          )}
          <div className={styles["card-collapse-step-data-item"]}>
            <p className={styles["card-collapse-step-data-item-label"]}>
              Dangerous Cargo
            </p>
            <p className={styles["card-collapse-step-data-item-value"]}>
              {requestInfo?.dangerous_cargo ? "Yes" : "No"}
            </p>
          </div>
          {/* {requestInfo?.un_number && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                Un Number
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.un_number}
              </p>
            </div>
          )} */}
          {/* {requestInfo?.class_number && (
            <div className={styles["card-collapse-step-data-item"]}>
              <p className={styles["card-collapse-step-data-item-label"]}>
                Class Number
              </p>
              <p className={styles["card-collapse-step-data-item-value"]}>
                {requestInfo?.class_number}
              </p>
            </div>
          )} */}

          <div className={styles["card-collapse-step-data-item"]}>
            <p className={styles["card-collapse-step-data-item-label"]}>
              Containers
            </p>
            <p className={styles["card-collapse-step-data-item-value"]}>
              {requestInfo?.containers?.map((item) => (
                <>
                  {item.name} (x{item.number})
                  <br />
                </>
              ))}
            </p>
          </div>
          <div className={styles["card-collapse-step-data-item"]}>
            <p className={styles["card-collapse-step-data-item-label"]}>
              Weight Per Container
            </p>
            <p className={styles["card-collapse-step-data-item-value"]}>
              {requestInfo?.containers?.map((item) => (
                <>
                  {item.weight} kg
                  <br />
                </>
              ))}
            </p>
          </div>
        </div>
      </div>
      {(requestInfo?.target_rate > 0 || requestInfo?.additional_info) && (
        <div className={styles["card-collapse-separator"]} />
      )}
      {/* Additional Information */}
      {(requestInfo?.target_rate > 0 || requestInfo?.additional_info) && (
        <div className={styles["card-collapse-step"]}>
          <h3>Additional Information</h3>
          <div className={`${styles["card-collapse-step-data"]}`}>
            {requestInfo?.target_rate > 0 && (
              <div className={styles["card-collapse-step-data-item"]}>
                <p className={styles["card-collapse-step-data-item-label"]}>
                  Target Rate
                </p>
                <p className={styles["card-collapse-step-data-item-value"]}>
                  {requestInfo?.target_rate}$
                </p>
              </div>
            )}
            {requestInfo?.additional_info && (
              <div className={styles["card-collapse-step-data-item"]}>
                <p className={styles["card-collapse-step-data-item-label"]}>
                  Others
                </p>
                <p className={styles["card-collapse-step-data-item-value"]}>
                  {requestInfo?.additional_info}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default RequestDetails;
