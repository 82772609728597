import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useHookForm } from "../../../hooks/hook-form";
import DateInput from "../../dateInput";
import FormCheckbox from "../../checkbox";
import DropdownMultipleWithCount from "../../DropdownMultipleWithCount";
import FormFooter from "../../formFooter";
import RequestQuoteInput from "../input";
import styles from "./index.module.scss";
import { Button } from "@mui/material";
import dayjs from "dayjs";

const RequestQuoteStepOne = () => {
  const {
    inputsData,
    stepTwoData,
    setStepTwoData,
    stepOneData,
    infoIcon,
    rows,
    setRows,
  } = useOutletContext();

  const [isDangerousCargoChecked, setIsDangerousCargoChecked] = useState(false);
  const [isPast, setIsPast] = useState(false);

  const { handleSubmit, errors, commodityRegister, weightRegister } =
    useHookForm({
      isCommodity: true,
      // isWeight: true,
      values: {
        // commodity: stepTwoData?.commodity ?? "",
        // weight: stepTwoData?.weight ?? "",
      },
      defaultValues: {
        // commodity: stepTwoData?.commodity ?? "",
        // weight: stepTwoData?.weight ?? "",
      },
    });

  const navigate = useNavigate();

  const dropdownContainerStyle = {
    width: "100%",
    minHeight: "2.6rem",
    background: "#fff",
    border: "0.8px solid #d9dcdc",
    borderRadius: "6px",
    padding: "0px !important",
    outline: "none !important",
    fontSize: "0.875rem !important",
    // borderColor: trigger && !selectedGrade ? "#1F1F21" : "#1F1F21",
    "& *": {
      fontFamily: "Poppins !important",
      //   fontSize: "0.875rem !important",
      border: "0 !important",
      // color: "#1F1F21 !important",
      // paddingTop: "0px !important",
      // paddingBottom: "0px !important",
    },
    "& p": {
      fontSize: "0.875rem !important",
      color: "#bababa",
      textTransform: "none",
    },
    "& .MuiInputBase-input": {
      paddingBlock: "10px !important",
      color: stepTwoData?.readinessDate ? "#bababa" : "#bababa",
    },
  };

  const handleFormSubmit = (data) => {
    //console.log("data", data);
    setStepTwoData({
      ...stepTwoData,
      containers: prepareDataForServer(),
      ...data,
    });
    navigate(`/request-quote/other`);
  };
  const handleDateChange = (value) => {
    //console.log("type", value?.$d);
    setStepTwoData((prevData) => ({
      ...prevData,
      readinessDate: dayjs(value),
    }));
  };
  const handleCommodity = (event) => {
    const {
      target: { value },
    } = event;
    setStepTwoData({ ...stepTwoData, commodity: value });
  };
  // Update stepTwoData and checkbox state together
  const handleCheckboxChange = () => {
    setIsDangerousCargoChecked((prev) => !prev);
    setStepTwoData((prevData) => ({
      ...prevData,
      dangerousCargo: !prevData.dangerousCargo,
    }));
  };
  const handleAddRow = () => {
    setRows([...rows, { id: rows.length + 1, containers: [], weight: "" }]);
  };
  const handleRemoveRow = () => {
    if (rows.length > 1) {
      setRows(rows.slice(0, -1));
    }
  };
  const handleCount = (id, op) => {
    const newArr = [];
    for (const row of rows) {
      for (const item of row.containers) {
        if (item.type === id) {
          if (op === "+") {
            if (item.count < 99) item.count = item.count + 1;
          } else {
            item.count = item.count - 1;
          }
        }
        if (item.count !== 0) {
          newArr.push(item);
        }
      }
    }
    setStepTwoData({ ...stepTwoData, containers: newArr });
  };

  const handleContainerChange = (index, event) => {
    const {
      target: { value },
    } = event;

    const lastSelected = value[value.length - 1]; // Get the last selected container

    // Set updatedContainers to include only the last selected container
    const updatedContainers = lastSelected
      ? [{ ...lastSelected, count: lastSelected.count ?? 1 }]
      : [];

    // Update the specific row's containers
    const updatedRows = [...rows];
    updatedRows[index].containers = updatedContainers;
    setRows(updatedRows);
  };

  const handleWeightPerContainer = (index, newWeight) => {
    const updatedRows = [...rows];
    updatedRows[index].weight = newWeight;
    setRows(updatedRows);
  };
  const prepareDataForServer = () => {
    const formattedData = rows
      .map((row) => {
        return row.containers.map((item) => {
          return {
            type: item.type,
            count: item.count, // count for each container
            weight: Number(row.weight), // weight for the current row
          };
        });
      })
      .flat(); // flatten the array to avoid nested arrays

    return formattedData;
  };
  // Checking If The Entered Date Is Before Today.
  useEffect(() => {
    const targetDate = new Date(stepTwoData?.readinessDate?.$d);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // Checking if targetDate is before today.
    if (targetDate < today) {
      setIsPast(true);
    } else {
      setIsPast(false);
    }
  }, [stepTwoData?.readinessDate, isPast]);

  useEffect(() => {
    //console.log("step two data", stepTwoData);
  }, [stepTwoData]);

  const containerStyle = {
    border: "0.8px solid #d9dcdc",
    minHeight: "2.9rem",
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className={styles["form-container"]}
    >
      <div
        className={`${styles["form-container-row"]} ${styles["form-container-row-title"]}`}
      >
        <h2>Cargo</h2>
      </div>
      <div className={styles["form-container-row"]}>
        <div className={styles["form-container-row-options-container"]}>
          <DateInput
            handleChange={handleDateChange}
            disablePast={true}
            placeholder={"Select date"}
            style={dropdownContainerStyle}
            value={stepTwoData?.readinessDate ?? null}
            iconColor={"dark"}
            placeholderShowed={false}
            inputHeight={styles.dateInputHeight}
            isPast={isPast}
          />
        </div>
        <RequestQuoteInput
          placeholder="Enter commodity"
          label="Commodity*"
          name={"commodity"}
          error={errors["commodity"]}
          formRegister={commodityRegister}
          onChange={handleCommodity}
          value={stepTwoData?.commodity ?? ""}
        />
      </div>
      <div
        className={`${styles["form-container-row"]} ${styles["form-container-dangerousCargo"]}`}
      >
        <FormCheckbox
          handleChange={handleCheckboxChange}
          label={"Dangerous Cargo"}
          checked={stepTwoData?.dangerousCargo ?? isDangerousCargoChecked}
        />
      </div>

      <div className={styles["containers-parent"]}>
        {rows.map((row, index) => (
          <div className={`${styles["form-container-row"]}`} key={row.id}>
            <DropdownMultipleWithCount
              label={
                index > 0 ? (
                  ""
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      position: "relative",
                    }}
                  >
                    <p className={styles["label"]}>Containers*</p>
                    <Button
                      variant="contained"
                      disabled={rows.length <= 1}
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        color: "#157ffb",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        padding: "0px",
                        display: "block",
                        minWidth: "auto",
                        fontFamily: "Poppins",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                        },
                        position: "absolute",
                        right: "70px",
                        bottom: "-5px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        },
                        ":disabled": {
                          opacity: 0.6,
                          backgroundColor: "transparent",
                          color: "#157ffb",
                        },
                      }}
                      onClick={handleRemoveRow}
                    >
                      -Remove
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        color: "#BD3C31",
                        fontWeight: "600",
                        textTransform: "capitalize",
                        padding: "0px",
                        display: "block",
                        minWidth: "auto",
                        fontFamily: "Poppins",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                        },
                        position: "absolute",
                        right: "0px",
                        bottom: "-5px",
                        "&:hover": {
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        },
                      }}
                      onClick={handleAddRow}
                    >
                      +Add
                    </Button>
                  </div>
                )
              }
              value={row?.containers ?? []}
              onChange={(selectedContainers) =>
                handleContainerChange(index, selectedContainers)
              }
              placeholder={"Select a container and number of"}
              options={inputsData.cargos_type}
              handleCount={handleCount}
              containerStyle={containerStyle}
              className={styles.container}
            />

            <RequestQuoteInput
              placeholder="Enter weight per container"
              label={index > 0 ? "" : "Weight per container*"}
              unit="Kilograms"
              type="number"
              inputMode="numeric"
              value={row.weight}
              onChange={(e) => handleWeightPerContainer(index, e.target.value)}
              unitStyle={index > 0 ? styles["unit-style"] : ""}
            />
          </div>
        ))}
      </div>

      <div className={styles["form-container-info"]}>
        <img src={infoIcon} alt="info" />
        <p>
          Readiness date is the date the cargo will be ready for loading. Please
          enter commodity and mention if cargo is dangerous. Also, please select
          the types and numbers of container desired, and the weight per
          container type, NOT total weight of containers. If more than one
          container of the same type have different weights, please make
          separate entries using the +Add button.
        </p>
      </div>
      <FormFooter
        disableBackButton={false}
        disableNextButton={
          !stepTwoData.readinessDate ||
          !rows.every((row) => row.containers?.length > 0) ||
          !rows.every((row) => row.weight !== "") ||
          isPast ||
          !stepOneData.pol
        }
        buttonText="Next"
        handleBackButton={() => navigate(-1)}
      />
    </form>
  );
};

export default RequestQuoteStepOne;
