import React from "react";

function calenderDark() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2030_4911)">
        <path
          d="M6.88691 6.66165H7.17304C7.60789 6.66165 7.96037 6.30269 7.96037 5.86014V3.64832V2.821C7.96037 2.37845 7.60789 2.01953 7.17304 2.01953H6.88691C6.4521 2.01953 6.09961 2.37845 6.09961 2.821V3.64836V5.86014C6.09961 6.30269 6.4521 6.66165 6.88691 6.66165Z"
          fill="#808B8C"
        />
        <path
          d="M16.9866 6.64211H17.2727C17.7075 6.64211 18.06 6.2832 18.06 5.84061V3.43873V2.80142C18.06 2.35892 17.7075 2 17.2727 2H16.9866C16.5517 2 16.1992 2.35892 16.1992 2.80142V3.43873V5.84057C16.1993 6.2832 16.5518 6.64211 16.9866 6.64211Z"
          fill="#808B8C"
        />
        <path
          d="M20.7201 3.64844H18.6994V6.05027C18.6994 6.85148 18.0591 7.2934 17.2722 7.2934H16.9861C16.199 7.2934 15.5587 6.64157 15.5587 5.84035V3.64844H8.60079V5.86021C8.60079 6.66142 7.96052 7.31326 7.17347 7.31326H6.88734C6.10033 7.31326 5.46006 6.66142 5.46006 5.86021V3.64844H3.27996C2.5742 3.64844 2 4.23297 2 4.95148V20.6965C2 21.4151 2.5742 21.9996 3.27996 21.9996H20.7201C21.4258 21.9996 22 21.4151 22 20.6965V4.95148C22.0001 4.23302 21.4258 3.64844 20.7201 3.64844ZM20.7201 20.6965H3.28001L3.27996 8.80628H20.7203L20.721 20.6965C20.7209 20.6965 20.7207 20.6965 20.7201 20.6965Z"
          fill="#808B8C"
        />
        <path
          d="M12.6611 12.7954H14.9593C15.0504 12.7954 15.1243 12.7202 15.1243 12.6274V10.6015C15.1243 10.5088 15.0504 10.4336 14.9593 10.4336H12.6611C12.57 10.4336 12.4961 10.5088 12.4961 10.6015V12.6274C12.4961 12.7202 12.57 12.7954 12.6611 12.7954Z"
          fill="#808B8C"
        />
        <path
          d="M16.4111 12.7954H18.7093C18.8004 12.7954 18.8743 12.7202 18.8743 12.6274V10.6015C18.8743 10.5088 18.8004 10.4336 18.7093 10.4336H16.4111C16.32 10.4336 16.2461 10.5088 16.2461 10.6015V12.6274C16.2461 12.7202 16.32 12.7954 16.4111 12.7954Z"
          fill="#808B8C"
        />
        <path
          d="M5.15911 16.1118H7.45732C7.54842 16.1118 7.62228 16.0366 7.62228 15.9439V13.9179C7.62228 13.8252 7.54842 13.75 7.45732 13.75H5.15911C5.068 13.75 4.99414 13.8252 4.99414 13.9179V15.9439C4.99414 16.0366 5.068 16.1118 5.15911 16.1118Z"
          fill="#808B8C"
        />
        <path
          d="M8.91106 16.1118H11.2093C11.3004 16.1118 11.3742 16.0366 11.3742 15.9439V13.9179C11.3742 13.8252 11.3004 13.75 11.2093 13.75H8.91106C8.81996 13.75 8.74609 13.8252 8.74609 13.9179V15.9439C8.74609 16.0366 8.81996 16.1118 8.91106 16.1118Z"
          fill="#808B8C"
        />
        <path
          d="M12.6611 16.1118H14.9593C15.0504 16.1118 15.1242 16.0366 15.1242 15.9439V13.9179C15.1242 13.8252 15.0504 13.75 14.9593 13.75H12.6611C12.57 13.75 12.4961 13.8252 12.4961 13.9179V15.9439C12.4961 16.0366 12.57 16.1118 12.6611 16.1118Z"
          fill="#808B8C"
        />
        <path
          d="M16.4111 16.1118H18.7093C18.8004 16.1118 18.8743 16.0366 18.8743 15.9439V13.9179C18.8743 13.8252 18.8004 13.75 18.7093 13.75H16.4111C16.32 13.75 16.2461 13.8252 16.2461 13.9179V15.9439C16.2461 16.0366 16.32 16.1118 16.4111 16.1118Z"
          fill="#808B8C"
        />
        <path
          d="M7.45728 17.0664H5.15911C5.068 17.0664 4.99414 17.1416 4.99414 17.2343V19.2603C4.99414 19.353 5.068 19.4282 5.15911 19.4282H7.45732C7.54842 19.4282 7.62228 19.353 7.62228 19.2603V17.2343C7.62224 17.1416 7.54838 17.0664 7.45728 17.0664Z"
          fill="#808B8C"
        />
        <path
          d="M11.2093 17.0664H8.91106C8.81996 17.0664 8.74609 17.1416 8.74609 17.2343V19.2603C8.74609 19.353 8.81996 19.4282 8.91106 19.4282H11.2093C11.3004 19.4282 11.3742 19.353 11.3742 19.2603V17.2343C11.3742 17.1416 11.3004 17.0664 11.2093 17.0664Z"
          fill="#808B8C"
        />
        <path
          d="M14.9593 17.0664H12.6611C12.57 17.0664 12.4961 17.1416 12.4961 17.2343V19.2603C12.4961 19.353 12.57 19.4282 12.6611 19.4282H14.9593C15.0504 19.4282 15.1243 19.353 15.1243 19.2603V17.2343C15.1243 17.1416 15.0504 17.0664 14.9593 17.0664Z"
          fill="#808B8C"
        />
        <path
          d="M18.7093 17.0664H16.4111C16.32 17.0664 16.2461 17.1416 16.2461 17.2343V19.2603C16.2461 19.353 16.32 19.4282 16.4111 19.4282H18.7093C18.8004 19.4282 18.8743 19.353 18.8743 19.2603V17.2343C18.8743 17.1416 18.8004 17.0664 18.7093 17.0664Z"
          fill="#808B8C"
        />
      </g>
      <defs>
        <clipPath id="clip0_2030_4911">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default calenderDark;
