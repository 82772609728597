import { useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import SideBar from "../../components/sideBar";
import styles from "./index.module.scss";

export function HeaderLayout({ children }) {
  const [sideBarShown, setSideBarShown] = useState(window.innerWidth > 768);
  const [overlay, setOverlay] = useState(false);
  return (
    <>
      <Header handleSideBar={setSideBarShown} handleOverlay={setOverlay} />
      {overlay ? <div className={styles["overlay"]}></div> : null}

      <main className={styles["layout-main"]}>{children}</main>
      {/* <Footer /> */}
    </>
  );
}

export default HeaderLayout;
