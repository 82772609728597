import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Button from "../../assets/Button.svg";
import { useDispatch } from "react-redux";
import salesRequests from "../../store/slices/salesRequestsSlice";
import QuoteModal from "../quoteModal";
import { t } from "i18next";
import { toggleStar, viewRequest } from "../../services/contentService";
import { useNavigate } from "react-router-dom";
const StarIcon = ({ filled }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={filled ? "#FFC107" : "none"}
      stroke="#FFC107"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="12 2 15 8.5 22 9.3 17 14 18.5 21 12 17 5.5 21 7 14 2 9.3 9 8.5 12 2" />
    </svg>
  );
};

// You can also use SASS for styling

const statusClasses = {
  active: styles.active,
  new_requests: styles.new,
  expired: styles.expired,
  accepted: styles.accepted,
  pending: styles.pending,
};

const RequestsTable = ({ setShowModal, salesRequestsData, toggleFavorite }) => {
  const dispatch = useDispatch();
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const dropdownRef = useRef(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };
  const handleViewClick = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/request/seen?id=${id}`,
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const response = await viewRequest(config);
    if (response?.status === 200) {
      dispatch(salesRequests.setOpenedRequestID(id));
      setShowModal(true);
      setOpenDropdownIndex(null);
    } else {
      //console.log("error");
    }
  };

  const formatDate = (date) => {
    const dateObj = new Date(date);
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const am_pm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const day = dateObj.getDate().toString().padStart(2, "0");
    const year = dateObj.getFullYear();
    const month = dateObj.toLocaleString("en-US", { month: "short" });
    const formattedDate = `${hours}:${minutes}${am_pm} - ${day} ${month} ${year}`;

    return formattedDate;
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownIndex(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>
              <p style={{ minWidth: "max-content", marginRight: "3.5rem" }}>
                Time and Date
              </p>
            </th>
            <th>Client</th>
            <th>Company </th>
            <th>Tire</th>
            <th>Route</th>
            <th></th>
            <th></th>
            <th>Commodity</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {salesRequestsData?.data.map((row, index) => (
            <tr key={index}>
              <td>
                <div onClick={() => toggleFavorite(row.id)}>
                  <StarIcon filled={row.marked} />
                </div>
              </td>
              <td>{row.id}</td>
              <td>{formatDate(row.created_at)}</td>
              <td>{row.client_fname + " " + row.client_lname}</td>
              <td>{row.client_company}</td>
              <td>{row.client_tier}</td>
              <td
                style={{ textAlign: "center", padding: "12px 0px 12px 20px" }}
                className={styles.route}
              >
                <p className={styles.port}>{`${row.port_of_loading} `}</p>
                <p className={styles.country}>{`${row.pol_country_name}`}</p>
              </td>
              <td
                style={{ textAlign: "center", padding: "12px 30px 12px 10px" }}
              >
                <p>{">"}</p>
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
                className={styles.route}
              >
                <p className={styles.port}>{`${row.port_of_discharge} `}</p>
                <p className={styles.country}>{`${row.pod_country_name}`}</p>
              </td>
              <td>{row.commodity}</td>
              <td>
                <span className={statusClasses[row.request_status]}>
                  {row.request_status.charAt(0).toUpperCase() +
                    row.request_status.slice(1)}
                </span>
              </td>
              <td className={styles.ellipsis}>
                <div className={styles.filterContainer}>
                  <button
                    className={styles.filterButton}
                    onClick={() => toggleDropdown(index)}
                  >
                    <img src={Button} />
                  </button>
                  {openDropdownIndex === index && (
                    <div className={styles["filterContainer-dropdownMenu"]}>
                      <div
                        className={styles["filterContainer-dropdownItem"]}
                        onClick={() => handleViewClick(row.id)}
                      >
                        View Details
                      </div>
                      {row.search_id && (
                        <div
                          className={styles["filterContainer-dropdownItem"]}
                          onClick={() =>
                            navigate("/search-history", {
                              state: { searchId: row.search_id },
                            })
                          }
                        >
                          Searched Results
                        </div>
                      )}

                      {["active", "accepted", "expired"].includes(
                        row.request_status
                      ) ? (
                        <div
                          onClick={() => setShowOfferModal(true)}
                          className={styles["filterContainer-dropdownItem"]}
                        >
                          View Quotation
                        </div>
                      ) : null}
                      {showOfferModal && (
                        <QuoteModal
                          handleClose={() => setShowOfferModal(false)}
                          isOpen={showOfferModal}
                          title={"Quotation Details"}
                          id={row.id}
                          pdfUrl={row.attachment}
                          expiration_date={row.expiration_date}
                          request_status={row.request_status}
                          role="sales"
                        />
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default RequestsTable;
