import React, { useState } from "react";
import styles from "./index.module.scss";
import Upload from "../../assets/upload.svg";
import X from "../../assets/x.svg";
import { useDroppable } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import DateInput from "../dateInput";
import MainButton from "../mainButton";
import { selectOpenedRequestID } from "../../store/slices/salesRequestsSlice";
import { useSelector } from "react-redux";
import { createShipment, sendOffer } from "../../services/contentService";
import DropdownWithCheckboxSelect from "../DropdownWithCheckboxSelect.jsx";
import { set } from "react-hook-form";
import { FormInput } from "../formInput/index.jsx";
import { useHookForm } from "../../hooks/hook-form.js";
import RequestQuoteInput from "../requestQuoteForm/input/index.jsx";
import CargoDropdown from "../CargoDropdown/index.jsx";
import DropdownWithRadioButtonSelect from "../DropdownWithRadioButtonSelect/index.jsx";
import { useNavigate } from "react-router-dom";
const ShipmentForm = ({
  setShowSendOfferModal,
  setShowSuccessModal,
  NT = null,
  SL = null,
  TN = null,
  CE = null,
  CM = null,
  filters = {},
  action = "create",
  id = null,
  fetchShipmentDetails = () => null,
}) => {
  // console.log("iD", id);
  const [isDropped, setIsDropped] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [freightPrice, setFreightPrice] = useState(null);
  const [commodity, setCommodity] = useState(null);
  const [date, setDate] = useState(null);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { isOver, setNodeRef } = useDroppable({
    id: "file-drop-area",
  });
  const [numberTypes, setNumberTypes] = useState(NT);
  const [shippingLine, setShippingLine] = useState(SL);
  const [tire, setTire] = useState([]);
  const [files, setFiles] = useState(null);
  const select = useSelector;
  const ID = select(selectOpenedRequestID);
  const token = localStorage.getItem("token");
  const style = {
    backgroundColor: isDragging ? "#e6f7ff" : "#fafafa",
    border: isDragging ? "2px dashed #007bff" : "2px dashed #d9d9d9",
  };
  // const isChecked = (item) => {
  //   return (
  //     numberTypes.requiredServices.find((t) => t.id === item.id) !== undefined
  //   );
  // };
  // const handleNumberTypesRequiredServicesChange = (event, item) => {
  //   const { checked } = event.target;
  //   const valueKey = "id";

  //   setNumberTypes((prevData) => {
  //     const requiredServices = prevData.requiredServices || [];

  //     if (checked) {
  //       return {
  //         ...prevData,
  //         requiredServices: [...requiredServices, item],
  //       };
  //     } else {
  //       return {
  //         ...prevData,
  //         requiredServices: requiredServices.filter(
  //           (service) => service[valueKey] !== item[valueKey]
  //         ),
  //       };
  //     }
  //   });
  // };
  // const handleShippingLineRequiredServicesChange = (event, item) => {
  //   const { checked } = event.target;
  //   const valueKey = "id";

  //   setShippingLine((prevData) => {
  //     const requiredServices = prevData.requiredServices || [];

  //     if (checked) {
  //       return {
  //         ...prevData,
  //         requiredServices: [...requiredServices, item],
  //       };
  //     } else {
  //       return {
  //         ...prevData,
  //         requiredServices: requiredServices.filter(
  //           (service) => service[valueKey] !== item[valueKey]
  //         ),
  //       };
  //     }
  //   });
  // };
  const containerStyle = {
    height: "2.8rem",
    width: "100%",
    paddingInline: "1rem",
    paddingBlock: "0.75rem",
    fontSize: "0.875rem",
    boxSizing: "border-box",
    color: "inherit",
    border: "1px solid rgba(238, 238, 238, 1)",
    borderRadius: "0.5rem",
    // border: "1px solid #d9dcdc",
    // borderRaduis: "12px",
    // height: "",
    // minHeight: "51px ",
  };
  const ClientTires = [
    { id: 0, name: "Premium Clients" },
    { id: 1, name: "Basic Clients" },
    { id: 2, name: "Starred " },
    { id: 3, name: "Not Starred" },
  ];
  const {
    handleSubmit,
    errors,
    isDirty,
    isValid,
    emailRegister,
    commodityRegister,
    trackingNumberRegister,
    requiredPasswordRegister,
    watch,
    setValue,
    dirtyFields,
    getValues,
  } = useHookForm({
    isEmail: true,
    isCommodity: true,
    isTrackingNumber: true,
  });

  const handleShipmentCreate = async (Data) => {
    // event.preventDefault();
    let data = JSON.stringify({
      client_email: Data?.email,
      tracking_number: Data?.trackingNumber,
      tracking_type: numberTypes?.key,
      commodity: Data?.commodity,
      scac: shippingLine?.key,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "shipment/tracking/create",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await createShipment(config);
    if (response.status === 201) {
      setShowSendOfferModal(false);
      setShowSuccessModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const handleShipmentEdit = async (Data) => {
    // event.preventDefault();
    let data = JSON.stringify({
      client_email: Data?.email,
      tracking_number: Data?.trackingNumber,
      tracking_type: numberTypes?.key,
      commodity: Data?.commodity,
      scac: shippingLine?.key,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `shipment/tracking/edit/${id}`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await createShipment(config);
    if (response.status === 200) {
      setShowSendOfferModal(false);
      fetchShipmentDetails();
      // setShowSuccessModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const handleDateFormat = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date)) {
      throw new Error("Invalid date string");
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const handleCommodity = (val) => {
    setCommodity();
  };
  return (
    <form
      className={styles.formContainer}
      onSubmit={handleSubmit(
        action === "create" ? handleShipmentCreate : handleShipmentEdit
      )}
    >
      {showError && (
        <div className={styles.errorContainer}>
          <div className={styles.errorIcon}>
            <span>!</span>
          </div>
          <p>{error}</p>
          <button
            onClick={() => setShowError(false)}
            className={styles.closeButton}
          >
            &#10006;
          </button>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <p>Tracking Number*</p>
          <FormInput
            name="trackingNumber"
            error={errors["trackingNumber"]}
            placeholder={"Enter tracking number"}
            formRegister={trackingNumberRegister}
            type={"number"}
            labelIcon={false}
            value={TN}
          />
        </div>
        <div className={styles.formGroup}>
          <DropdownWithRadioButtonSelect
            label={"Number Type*"}
            value={numberTypes?.title ?? null}
            placeholder={"Select number type"}
            options={filters?.tracked_by}
            selectedValue={numberTypes?.title ?? null}
            setSelectedValue={setNumberTypes}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <DropdownWithRadioButtonSelect
            options={filters.shipping_line}
            label={"Shipping Line*"}
            placeholder={"Choose shipping line"}
            selectedValue={shippingLine?.title ?? null}
            setSelectedValue={setShippingLine}
            className={styles.dropdown}
          />
          {/* <DropdownWithCheckboxSelect
            label={"Shipping Line*"}
            value={shippingLine.requiredServices ?? []}
            onChange={handleShippingLineRequiredServicesChange}
            placeholder={"Select required services"}
            options={filters.shipping_line}
            handleRequiredServicesChange={
              handleShippingLineRequiredServicesChange
            }
            className={styles.viewInput}
            containerStyle={containerStyle}
            role={"sales"}
            isChecked={isChecked}
            stepOneData={shippingLine}
          /> */}
        </div>
        <div className={styles.formGroup}>
          <p>Associated Client Email*</p>
          <FormInput
            name="email"
            error={errors["email"]}
            placeholder={"Email"}
            value={CE}
            formRegister={emailRegister}
            labelIcon={false}
            labelIconName={"Email"}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <p>Commodity*</p>
          <FormInput
            placeholder={"Enter the commodity"}
            // label="Commodity*"
            name={"commodity"}
            error={errors["commodity"]}
            formRegister={commodityRegister}
            onChange={(val) => setCommodity(val)}
            isInsurance={false}
            value={CM}
          />
        </div>
      </div>

      <div className={styles["formContainer-button"]}>
        <MainButton
          className={styles["formContainer-button-btn"]}
          type="submit" // Button submits the form
          disabled={
            !isValid ||
            !isDirty ||
            numberTypes === null ||
            shippingLine === null
          }
        >
          Send
        </MainButton>
      </div>
    </form>
  );
};

export default ShipmentForm;
