import { useNavigate } from "react-router-dom";
import { sendOTP, verifyEmail } from "../../services/authService";
import FormInput from "../formInput";
import MainButton from "../mainButton";
import PasswordValidations from "../passwordValidations";
import styles from "./index.module.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import LoadingSpinner from "../LoadingSpinner";

export default function EmailVerificationStep({
  useHookForm,
  setEmail,
  setStep,
}) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    errors,
    isDirty,
    isValid,
    emailRegister,
    setValue,
    dirtyFields,
    getValues,
  } = useHookForm({
    isEmail: true,
  });
  const navigate = useNavigate();
  // const handleFormSubmit = async (data) => {
  //   const response = await sendOTP(data);
  //   if (response.status === 200) {
  //     setStep(2);
  //     setEmail(data.email);
  //   } else {
  //     //
  //   }
  // };
  const handleVerifyEmail = async (data) => {
    setIsLoading(true);
    const response = await verifyEmail({
      email: data.email,
      type: "reset_password",
    });
    if (response.status === 200 || response.status === 201) {
      //console.log(response.data);
      //console.log("email sent successfully");
      navigate("/check-your-email", {
        state: { email: data.email, type: "password reset" },
      });
    } else {
      //console.log(response.data);
      setError(response.data.error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }, [error]);
  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleVerifyEmail)}
      className={styles["form"]}
    >
      {error && <ToastContainer />}
      <div>
        <p>Email</p>
        <FormInput
          name="email"
          error={errors["email"]}
          placeholder={"Enter your email"}
          formRegister={emailRegister}
          labelIcon={true}
          labelIconName={"Email"}
        />
      </div>
      {isLoading ? (
        <div
          style={{
            marginTop: "3rem",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div className={styles["form-btn-container"]}>
          <MainButton type={"submit"} disabled={!isValid || !isDirty}>
            Send
          </MainButton>
        </div>
      )}
    </form>
  );
}
