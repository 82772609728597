import React from "react";
import { logout } from "../../services/authService";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/slices/userSlice";
import { useNavigate } from "react-router-dom";
import logoutBtn from "../../assets/logout.svg";
import styles from "./index.module.scss";

const LogoutBtn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleLogout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    localStorage.removeItem("user-tier");
    // dispatch(setUser({ token: "", user: {} }));
    navigate("/login");
  };

  return (
    <div className={styles["logout"]} onClick={handleLogout}>
      <img src={logoutBtn} alt="logout" />
      <a>Logout</a>
    </div>
  );
};

export default LogoutBtn;
