import axios from "axios";

export const getRequestQuoteData = () => {
  return axios
    .get("content/request/data")
    .then((response) => response)
    .catch((err) => err?.response);
};

export const postRequestQuote = (body, token) => {
  return axios
    .post("content/quote/request", body, {
      headers: { Authorization: `Token ${token}` },
    })
    .then((response) => response)
    .catch((err) => err?.response);
};

export const getAllRequests = (params, token) => {
  return axios
    .get(`content/quote${params ? `?${params}` : ""}`, {
      headers: { Authorization: `Token ${token}` },
    })
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getDashboradRequests = (token) => {
  return axios
    .get(`content/dashboard/active/requests`, {
      headers: { Authorization: `Token ${token}` },
    })
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getSearchHistory = (searchId, token) => {
  return axios
    .get(`content/search/history/${searchId}`, {
      headers: { Authorization: `Token ${token}` },
    })
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getDashboradShipments = (token) => {
  return axios
    .get(`content/dashboard/shipments/latest`, {
      headers: { Authorization: `Token ${token}` },
    })
    .then((response) => response)
    .catch((err) => err?.response);
};

export const getPorts = (params = "") => {
  return axios
    .get(`content/ports${params ? `?${params}` : ""}`)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getSalesRequests = (config) => {
  // //console.log("body  : ", body, "token : ", token);
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getShipments = (config) => {
  // //console.log("body  : ", body, "token : ", token);
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getRequestInfo = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const toggleStar = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const viewRequest = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const sendOffer = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const createShipment = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const deleteShipment = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const acceptOffer = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getNotifications = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const markNotification = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
export const markAllNotifications = (config) => {
  return axios
    .request(config)
    .then((response) => response)
    .catch((err) => err?.response);
};
