import { useState, useEffect, useReducer } from "react";
import styles from "./index.module.scss";
import FreightCard from "../../components/FreightCard";
import FiltersSidebar from "../../components/filtersSidebar";
import { useLocation, useNavigate } from "react-router-dom";
import Filter from "../../assets/Filter.svg";
import Arrows from "../../assets/Arrows.svg";
import VesselSearchFIlterModal from "../../components/VesselSearchFIlterModal";
import SortModal from "../../components/SortModal";
import SortBy from "../../components/SortBy";
import { getGlobalConfigs } from "../../services/testService";
import { getSearchHistory } from "../../services/contentService";
import BackArrow from "../../assets/BackArrowIcon.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import DashboardTimelineSkeleton from "../../components/DashboardTimeLineSkeleton";

/* */
const initialState = {
  priceRange: [1000, 6000],
  transitTimeRange: [10, 20],
  etdRange: ["2024-05-04", "2024-06-11"],
  etaRange: ["2024-05-17", "2024-09-06"],
};
const filtersReducer = (state, action) => {
  switch (action.type) {
    case "SET_PRICE_RANGE":
      return { ...state, priceRange: action.payload };
    case "SET_TRANSIT_TIME_RANGE":
      return { ...state, transitTimeRange: action.payload };
    case "SET_ETA_RANGE":
      return { ...state, etaRange: action.payload };
    case "SET_ETD_RANGE":
      return { ...state, etdRange: action.payload };
    default:
      return state;
  }
};
/* */
export default function SearchHistory() {
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  const navigate = useNavigate();
  const location = useLocation();

  const [baseModal, setBaseModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [freights, setFreights] = useState([]);
  const [filterdFreights, setFilterdFreights] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sortCriterion, setSortCriterion] = useState("");
  const [bounds, setBounds] = useState({});
  const [filters, dispatch] = useReducer(filtersReducer, initialState);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [globalConfigs, setGlobalConfigs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const SearchHistory = async () => {
    setIsLoading(true);
    const response = await getSearchHistory(location.state?.searchId, token);
    if (response.status === 200 || response.status === 201) {
      //console.log(response.data);

      setFreights(JSON.parse(response.data.response_body));
      setFilterdFreights(JSON.parse(response.data.response_body));
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    SearchHistory();
  }, []);

  const sortData = (data, criterion) => {
    setSortCriterion(criterion);
    return data.slice().sort((a, b) => {
      switch (criterion) {
        case "highestPrice":
          return b.price - a.price;
        case "lowestPrice":
          return a.price - b.price;
        case "transitTime":
          return a.transit_time - b.transit_time;
        default:
          throw new Error("Invalid sorting criterion");
      }
    });
  };
  const handleSort = (data, criterion) => {
    setFreights(sortData(data, criterion));
    setFilterdFreights(sortData(data, criterion));
    //console.log("sorted :", criterion);
  };
  const calculateBounds = (data) => {
    let minPrice = Infinity;
    let maxPrice = -Infinity;
    let minTransitTime = Infinity;
    let maxTransitTime = -Infinity;
    let minETA = null;
    let maxETA = null;
    let minETD = null;
    let maxETD = null;
    data.forEach(({ price, transit_time, ETD, ETA }) => {
      // Price
      minPrice = Math.min(minPrice, price);
      maxPrice = Math.max(maxPrice, price);

      // Transit Time
      minTransitTime = Math.min(minTransitTime, transit_time);
      maxTransitTime = Math.max(maxTransitTime, transit_time);
      const etaDate = new Date(ETA);
      if (!minETA || etaDate < new Date(minETA)) minETA = ETA;
      if (!maxETA || etaDate > new Date(maxETA)) maxETA = ETA;

      // ETD (convert to Date objects and compare)
      const etdDate = new Date(ETD);
      if (!minETD || etdDate < new Date(minETD)) minETD = ETD;
      if (!maxETD || etdDate > new Date(maxETD)) maxETD = ETD;
    });

    return {
      minPrice,
      maxPrice,
      minTransitTime,
      maxTransitTime,
      minETA,
      maxETA,
      minETD,
      maxETD,
    };
  };
  const filterData = (
    data,
    { priceRange, transitTimeRange, etaRange, etdRange }
  ) => {
    return data.filter((item) => {
      const price = item.price;
      const transitTime = item.transit_time;
      const eta = new Date(item.ETA);
      const etd = new Date(item.ETD);
      //console.log("new ranges", eta, etd);
      const matchesPrice = priceRange
        ? price >= priceRange[0] && price <= priceRange[1]
        : true;
      const matchesTransitTime = transitTimeRange
        ? transitTime >= transitTimeRange[0] &&
          transitTime <= transitTimeRange[1]
        : true;
      const matchesETA = etaRange
        ? eta >= new Date(etaRange[0]) && eta <= new Date(etaRange[1])
        : true;
      const matchesETD = etdRange
        ? etd >= new Date(etdRange[0]) && etd <= new Date(etdRange[1])
        : true;

      return matchesPrice && matchesTransitTime && matchesETA && matchesETD;
    });
  };
  const handleFilter = (
    data,
    { priceRange, transitTimeRange, etaRange, etdRange }
  ) => {
    setFilterdFreights(
      filterData(data, { priceRange, transitTimeRange, etaRange, etdRange })
    );
  };
  const sortOptions = [
    {
      title: "Highest price",
      value: "Highest price",
      onclick: () => handleSort(freights, "highestPrice"),
    },
    {
      title: "Lowest price",
      value: "Lowest price",
      onclick: () => handleSort(freights, "lowestPrice"),
    },
    {
      title: " Soonest arrival",
      value: " Soonest arrival",
      onclick: () => handleSort(freights, "transitTime"),
    },
  ];
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (sortCriterion) {
      // //console.log(sortCriterion);
      handleSort(freights, sortCriterion);
    }
  }, [sortCriterion]);
  useEffect(() => {
    if (freights?.length > 0) {
      setBounds(calculateBounds(freights));
      // handleFilter(freights , )
    }
  }, [freights]);
  useEffect(() => {
    if (filtersApplied) {
      handleFilter(freights, filters);
      setIsFiltered(true);
    }
  }, [freights, filters, filtersApplied]);

  useEffect(() => {
    const fetchGlobalConfigs = async () => {
      const response = await getGlobalConfigs();
      //console.log("globalConfigs: ", response.settings);
      setGlobalConfigs(response.settings);
    };
    fetchGlobalConfigs();
  }, []);

  const shouldShowPrice = () => {
    const userTier = localStorage.getItem("user-tier"); // premium-standard
    const displayFrieghtPrice = globalConfigs.display_freight_prices; // all-none-premium-logged

    return (
      (displayFrieghtPrice === "all" && userTier) ||
      (displayFrieghtPrice === "premium" && userTier === "premium") ||
      (displayFrieghtPrice === "logged" && userTier)
    );
  };

  return (
    <>
      <div className={styles["home"]}>
        <div className={styles["home-header"]}>
          <img
            onClick={() => navigate(-1)}
            src={BackArrow}
            alt="Back"
            width={45}
            height={45}
            style={{ cursor: "pointer" }}
          />
          <h2>Client Searched Schedules</h2>
        </div>
        <div className={styles["home-main"]}>
          {isMobile ? null : (
            <FiltersSidebar
              bounds={bounds}
              dispatch={dispatch}
              filters={filters}
              setFiltersApplied={setFiltersApplied}
              shouldShowPrice={shouldShowPrice()}
            />
          )}
          {isMobile ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // width: 250,
                  justifySelf: "center",
                  alignSelf: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <button
                    className={styles.logo}
                    onClick={() => setBaseModal(true)}
                  >
                    <img className={styles.img} src={Filter} alt="Filter" />
                    <p className={styles.text}>Filter</p>
                  </button>
                </div>
                {/* <hr className={styles.separator} />

                <div>
                  <button
                    className={styles.logo}
                    onClick={() => setSortModal(true)}
                  >
                    <img className={styles.img} src={Arrows} alt="Arrows" />
                    <p className={styles.text}>Sort</p>
                  </button>
                </div> */}
              </div>
            </>
          ) : null}
          <div className={styles["home-main-content"]}>
            <div className={styles["home-title"]}>
              <h2>Search Results</h2>
              {/* {!isMobile && (
                <SortBy
                  // setIsOpend={setIsOpend}
                  // isOpend={isOpend}
                  options={sortOptions}
                  setMenuOpen={setMenuOpen}
                  label="Sort By"
                />
              )} */}
            </div>
            {isLoading ? (
              <Box
                sx={{
                  marginTop: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.7rem",
                }}
              >
                <DashboardTimelineSkeleton
                  className={styles["skeleton-styles"]}
                />
                <DashboardTimelineSkeleton
                  className={styles["skeleton-styles"]}
                />
                <DashboardTimelineSkeleton
                  className={styles["skeleton-styles"]}
                />
              </Box>
            ) : (
              <div className={styles["home-card-container"]}>
                {isFiltered && filtersApplied
                  ? filterdFreights?.map((freight, index) => (
                      <div key={index}>
                        <FreightCard
                          freight={freight}
                          globalConfigs={globalConfigs}
                          shouldShowPrice={shouldShowPrice()}
                          type={type}
                        />
                      </div>
                    ))
                  : freights?.map((freight, index) => (
                      <div key={index}>
                        <FreightCard
                          freight={freight}
                          globalConfigs={globalConfigs}
                          shouldShowPrice={shouldShowPrice()}
                          type={type}
                        />
                      </div>
                    ))}
              </div>
            )}
          </div>
        </div>

        {isMobile && baseModal ? (
          <VesselSearchFIlterModal
            handleOpen={setBaseModal}
            isOpen={baseModal}
            bounds={bounds}
            dispatch={dispatch}
            filters={filters}
            setFiltersApplied={setFiltersApplied}
            shouldShowPrice={shouldShowPrice()}
          />
        ) : null}
        {isMobile && sortModal ? (
          <SortModal
            handleOpen={setSortModal}
            isOpen={sortModal}
            handleSort={setSortCriterion}
            sortCriterion={sortCriterion}
          />
        ) : null}
      </div>
    </>
  );
}
