export const loadState = (key, defaultValue) => {
	try {
		const serializedState = sessionStorage.getItem(key);
		if (serializedState === null) {
			return defaultValue;
		}
		return JSON.parse(serializedState);
	} catch (error) {
		return defaultValue;
	}
};

export const saveState = (key, value) => {
	try {
		sessionStorage.setItem(key, JSON.stringify(value));
	} catch (error) {}
};
