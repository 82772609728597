import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { set } from "react-hook-form";

const initialState = {
  status: "",
  fromDate: null,
  toDate: null,
  criteria: "",
  openedRequestID: null,
  clientName: "",
  clientAddress: "",
  clientEmail: "",
  clientPhone: "",
  clientCompany: "",
  attachment: null,
};

export const salesRequestsSlice = createSlice({
  name: "salesRequests",
  initialState,
  reducers: {
    setFromDate: (state, action) => {
      state.fromDate = action.payload;
    },
    setToDate: (state, action) => {
      state.toDate = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setCriterias: (state, action) => {
      state.criteria = action.payload;
    },
    setOpenedRequestID: (state, action) => {
      state.openedRequestID = action.payload;
    },
    setClientName: (state, action) => {
      state.clientName = action.payload;
    },
    setClientAddress: (state, action) => {
      state.clientAddress = action.payload;
    },
    setClientEmail: (state, action) => {
      state.clientEmail = action.payload;
    },
    setClientPhone: (state, action) => {
      state.clientPhone = action.payload;
    },
    setClientCompany: (state, action) => {
      state.clientCompany = action.payload;
    },
    setAttachment: (state, action) => {
      state.attachment = action.payload;
    },
  },
});

const salesRequests = {
  salesRequestsSlice,
  setFromDate: salesRequestsSlice.actions.setFromDate,
  setToDate: salesRequestsSlice.actions.setToDate,
  setStatus: salesRequestsSlice.actions.setStatus,
  setCriterias: salesRequestsSlice.actions.setCriterias,
  setOpenedRequestID: salesRequestsSlice.actions.setOpenedRequestID,
  setClientName: salesRequestsSlice.actions.setClientName,
  setClientAddress: salesRequestsSlice.actions.setClientAddress,
  setClientEmail: salesRequestsSlice.actions.setClientEmail,
  setClientPhone: salesRequestsSlice.actions.setClientPhone,
  setClientCompany: salesRequestsSlice.actions.setClientCompany,
  setAttachment: salesRequestsSlice.actions.setAttachment,
};
export const selectFromDate = (state) => state.salesRequests.fromDate;
export const selectToDate = (state) => state.salesRequests.toDate;
export const selectStatus = (state) => state.salesRequests.status;
export const selectCriteria = (state) => state.salesRequests.criteria;
export const selectOpenedRequestID = (state) =>
  state.salesRequests.openedRequestID;
export const selectClientName = (state) => state.salesRequests.clientName;
export const selectClientAddress = (state) => state.salesRequests.clientAddress;
export const selectClientEmail = (state) => state.salesRequests.clientEmail;
export const selectClientPhone = (state) => state.salesRequests.clientPhone;
export const selectClientCompany = (state) => state.salesRequests.clientCompany;
export const selectAttachment = (state) => state.salesRequests.attachment;
export default salesRequests;
