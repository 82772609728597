import styles from "./index.module.scss";
import requestSentIcon from "../../../assets/request_sent.svg";
import MainButton from "../../mainButton";
import { useNavigate, useLocation } from "react-router-dom";

const RequestQuoteStepSix = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={styles["success-container"]}>
      <img src={requestSentIcon} alt="success" />
      <h6>Your Request was Sent Successfully</h6>
      <p>
        We will respond to your request as soon as possible. Your request number
        is {location?.state ?? "requestID"}. Thank you for your patience.
      </p>
      <div>
        <MainButton
          handleSubmit={() => navigate("/")}
          className={styles["main-btn"]}
        >
          Back to Ship Schedules
        </MainButton>
        <MainButton
          handleSubmit={() => navigate("/dashboard")}
          className={styles["outlined-btn"]}
        >
          Go to Dashboard
        </MainButton>
      </div>
    </div>
  );
};

export default RequestQuoteStepSix;
