import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { set } from "react-hook-form";

const initialState = {
  status: "",
  view: "",
  trackedBy: "",
  scac: "",
};

export const shipmentSlice = createSlice({
  name: "shipment",
  initialState,
  reducers: {
    setTrackedBy: (state, action) => {
      state.trackedBy = action.payload;
    },
    setScac: (state, action) => {
      state.scac = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setView: (state, action) => {
      state.view = action.payload;
    },
  },
});

const shipment = {
  shipmentSlice,
  setTrackedBy: shipmentSlice.actions.setTrackedBy,
  setScac: shipmentSlice.actions.setScac,
  setStatus: shipmentSlice.actions.setStatus,
  setView: shipmentSlice.actions.setView,
};
export const selectTrackedBy = (state) => state.shipment.trackedBy;
export const selectView = (state) => state.shipment.view;
export const selectStatus = (state) => state.shipment.status;
export const selectScac = (state) => state.shipment.scac;

export default shipment;
