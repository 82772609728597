import React from "react";

function calender({ theme }) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3081_2859)">
        <path
          d="M7.80292 6.66165H8.08905C8.5239 6.66165 8.87639 6.30269 8.87639 5.86014V3.64832V2.821C8.87639 2.37845 8.5239 2.01953 8.08905 2.01953H7.80292C7.36811 2.01953 7.01562 2.37845 7.01562 2.821V3.64836V5.86014C7.01563 6.30269 7.36811 6.66165 7.80292 6.66165Z"
          fill="white"
        />
        <path
          d="M17.9007 6.64211H18.1868C18.6216 6.64211 18.9741 6.2832 18.9741 5.84061V3.43873V2.80142C18.9741 2.35892 18.6216 2 18.1868 2H17.9007C17.4658 2 17.1133 2.35892 17.1133 2.80142V3.43873V5.84057C17.1134 6.2832 17.4659 6.64211 17.9007 6.64211Z"
          fill="white"
        />
        <path
          d="M21.6361 3.64844H19.6154V6.05027C19.6154 6.85148 18.9752 7.2934 18.1882 7.2934H17.9021C17.115 7.2934 16.4747 6.64157 16.4747 5.84035V3.64844H9.51681V5.86021C9.51681 6.66142 8.87654 7.31326 8.08949 7.31326H7.80335C7.01635 7.31326 6.37608 6.66142 6.37608 5.86021V3.64844H4.19598C3.49022 3.64844 2.91602 4.23297 2.91602 4.95148V20.6965C2.91602 21.4151 3.49022 21.9996 4.19598 21.9996H21.6361C22.3419 21.9996 22.916 21.4151 22.916 20.6965V4.95148C22.9161 4.23302 22.3419 3.64844 21.6361 3.64844ZM21.6361 20.6965H4.19602L4.19598 8.80628H21.6363L21.637 20.6965C21.6369 20.6965 21.6367 20.6965 21.6361 20.6965Z"
          fill="white"
        />
        <path
          d="M13.5771 12.7954H15.8753C15.9664 12.7954 16.0403 12.7202 16.0403 12.6274V10.6015C16.0403 10.5088 15.9664 10.4336 15.8753 10.4336H13.5771C13.486 10.4336 13.4121 10.5088 13.4121 10.6015V12.6274C13.4121 12.7202 13.486 12.7954 13.5771 12.7954Z"
          fill="white"
        />
        <path
          d="M17.3271 12.7954H19.6253C19.7164 12.7954 19.7903 12.7202 19.7903 12.6274V10.6015C19.7903 10.5088 19.7164 10.4336 19.6253 10.4336H17.3271C17.236 10.4336 17.1621 10.5088 17.1621 10.6015V12.6274C17.1621 12.7202 17.236 12.7954 17.3271 12.7954Z"
          fill="white"
        />
        <path
          d="M6.07512 16.1118H8.37333C8.46444 16.1118 8.5383 16.0366 8.5383 15.9439V13.9179C8.5383 13.8252 8.46444 13.75 8.37333 13.75H6.07512C5.98402 13.75 5.91016 13.8252 5.91016 13.9179V15.9439C5.91016 16.0366 5.98402 16.1118 6.07512 16.1118Z"
          fill="white"
        />
        <path
          d="M9.82512 16.1118H12.1233C12.2144 16.1118 12.2883 16.0366 12.2883 15.9439V13.9179C12.2883 13.8252 12.2144 13.75 12.1233 13.75H9.82512C9.73402 13.75 9.66016 13.8252 9.66016 13.9179V15.9439C9.66016 16.0366 9.73402 16.1118 9.82512 16.1118Z"
          fill="white"
        />
        <path
          d="M13.5771 16.1118H15.8753C15.9664 16.1118 16.0403 16.0366 16.0403 15.9439V13.9179C16.0403 13.8252 15.9664 13.75 15.8753 13.75H13.5771C13.486 13.75 13.4121 13.8252 13.4121 13.9179V15.9439C13.4121 16.0366 13.486 16.1118 13.5771 16.1118Z"
          fill="white"
        />
        <path
          d="M17.3271 16.1118H19.6253C19.7164 16.1118 19.7903 16.0366 19.7903 15.9439V13.9179C19.7903 13.8252 19.7164 13.75 19.6253 13.75H17.3271C17.236 13.75 17.1621 13.8252 17.1621 13.9179V15.9439C17.1621 16.0366 17.236 16.1118 17.3271 16.1118Z"
          fill="white"
        />
        <path
          d="M8.37329 17.0664H6.07512C5.98402 17.0664 5.91016 17.1416 5.91016 17.2343V19.2603C5.91016 19.353 5.98402 19.4282 6.07512 19.4282H8.37333C8.46443 19.4282 8.5383 19.353 8.5383 19.2603V17.2343C8.53826 17.1416 8.46439 17.0664 8.37329 17.0664Z"
          fill="white"
        />
        <path
          d="M12.1233 17.0664H9.82512C9.73402 17.0664 9.66016 17.1416 9.66016 17.2343V19.2603C9.66016 19.353 9.73402 19.4282 9.82512 19.4282H12.1233C12.2144 19.4282 12.2883 19.353 12.2883 19.2603V17.2343C12.2883 17.1416 12.2144 17.0664 12.1233 17.0664Z"
          fill="white"
        />
        <path
          d="M15.8753 17.0664H13.5771C13.486 17.0664 13.4121 17.1416 13.4121 17.2343V19.2603C13.4121 19.353 13.486 19.4282 13.5771 19.4282H15.8753C15.9664 19.4282 16.0403 19.353 16.0403 19.2603V17.2343C16.0403 17.1416 15.9664 17.0664 15.8753 17.0664Z"
          fill="white"
        />
        <path
          d="M19.6253 17.0664H17.3271C17.236 17.0664 17.1621 17.1416 17.1621 17.2343V19.2603C17.1621 19.353 17.236 19.4282 17.3271 19.4282H19.6253C19.7164 19.4282 19.7903 19.353 19.7903 19.2603V17.2343C19.7903 17.1416 19.7164 17.0664 19.6253 17.0664Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3081_2859">
          <rect
            width="24"
            height="24"
            fill={theme === "dark" ? "white" : "white"}
            transform="translate(0.916016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default calender;
