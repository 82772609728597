import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useHookForm } from "../../hooks/hook-form";
import { setUser } from "../../store/slices/userSlice";
import styles from "./index.module.scss";
import { FetchSignupAvailability, signup } from "../../services/authService";
import { FormInput } from "../formInput";
import PasswordValidations from "../passwordValidations";
import MainButton from "../mainButton";
import TeuLogo from "../../assets/TEU_logo_metadata.png";
import LoadingSpinner from "../LoadingSpinner";

function SignupForm() {
  const [error, setError] = useState(null);
  const [Code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const { email, otp } = location.state || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    errors,
    isDirty,
    isValid,
    emailRegister,
    fullNameRegister,
    passwordRegister,
    confirmPasswordRegister,
    firstNameRegister,
    companyRegister,
    positionRegister,
    lastNameRegister,
    nonRequiredPhoneRegister,

    setValue,
    dirtyFields,
    getValues,
    watch,
  } = useHookForm({
    // isEmail: true,
    isRequiredPassword: true,
    isFirstName: true,
    isLastName: true,
    isCompany: true,
    isPosition: true,
    isNonRequiredPhone: true,
    isConfirmPassword: true,
    isPassword: true,
  });

  const handleFormSubmit = async (data) => {
    setIsLoading(true);
    //console.log(data);
    const response = await signup({
      first_name: data.first_name,
      last_name: data.last_name,
      email: email,
      password: data.password,
      confirm_password: data["confirm-password"],
      company_name: data.company,
      position: data.position,
      phone_number: data.phone_number,
      phone_number_code: Code,
      otp: otp,
    });
    //console.log(response.data);
    if (response.status === 201) {
      dispatch(setUser(response.data));
      localStorage.setItem("token", response.data.token);
      navigate("/");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    } else {
      //console.log(response);
      setError(response.data?.error);
    }
    setIsLoading(false);
  };
  const handleSignUpAvailability = async () => {
    const response = await FetchSignupAvailability();

    if (
      response.status === 200 &&
      response.data.signup_availability === false
    ) {
      navigate("/login", { replace: true });
      // //console.log(response.data);
    }
  };
  useLayoutEffect(() => {
    handleSignUpAvailability();
  }, []);
  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleFormSubmit)}
      className={styles["signup-form"]}
    >
      <div>
        <div className={styles["signup-header"]}>
          <h3 className={styles["signup-title"]}>
            <img
              src={TeuLogo}
              style={{ width: "40px", height: "40px" }}
              alt="Welcome To Teu"
            />{" "}
            Sea Portal
          </h3>
          <h3 className={styles["signup-subtitle"]}>Register</h3>
        </div>
        <div className={styles["signup-field"]}>
          <p>First Name</p>
          <FormInput
            name="first_name"
            error={errors["first_name"]}
            placeholder={"Enter First Name"}
            formRegister={firstNameRegister}
            labelIcon={true}
            labelIconName={"name"}
          />
        </div>
        <div className={styles["signup-field"]}>
          <p>Last Name</p>
          <FormInput
            name="last_name"
            error={errors["last_name"]}
            placeholder={"Enter Last Name"}
            formRegister={lastNameRegister}
            labelIcon={true}
            labelIconName={"name"}
          />
        </div>
        <div className={styles["signup-field"]}>
          <p>Company Name</p>
          <FormInput
            name="company"
            error={errors["company"]}
            placeholder={"Enter Company Name"}
            formRegister={companyRegister}
            labelIcon={true}
            labelIconName={"company"}
          />
        </div>
        <div className={styles["signup-field"]}>
          <p>Position</p>
          <FormInput
            name="position"
            error={errors["position"]}
            placeholder={"Enter Position"}
            formRegister={positionRegister}
            labelIcon={true}
            labelIconName={"position"}
          />
        </div>
        <div className={styles["signup-field"]}>
          <p>Phone Number</p>
          <FormInput
            name="phone_number"
            error={errors["phone_number"]}
            placeholder={"Enter your phone number"}
            formRegister={nonRequiredPhoneRegister}
            phone={true}
            setCode={setCode}
          />
        </div>
        {/* <div className={styles["signup-field"]}>
          <p>Email</p>
          <FormInput
            name="email"
            error={errors["email"]}
            placeholder={"Email"}
            formRegister={emailRegister}
            labelIcon={true}
            labelIconName={"Email"}
          />
        </div> */}
        <div className={styles["signup-field"]}>
          <p>Password</p>
          <FormInput
            name="password"
            type="password"
            error={errors["password"]}
            placeholder={"Enter new password"}
            formRegister={passwordRegister}
            labelIconName="Lock"
            labelIcon={true}
          />

          <PasswordValidations
            isDirty={dirtyFields["password"]}
            error={errors["password"]}
            value={watch("password")}
          />
        </div>
        <div className={styles["signup-field"]}>
          <p>Confirm Password</p>
          <FormInput
            name="confirm-password"
            type="password"
            error={errors["confirm-password"]}
            placeholder={"Re-enter new password"}
            formRegister={confirmPasswordRegister}
            labelIconName="Lock"
            labelIcon={true}
          />
        </div>
        {error && <p className={styles["error"]}>{error}</p>}
      </div>
      <div style={{ gap: "1rem" }}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <MainButton type={"submit"} disabled={!isValid || !isDirty}>
            Register
          </MainButton>
        )}
        <a className={styles["sign-up"]} onClick={() => navigate("/login")}>
          Already have an account? <span>Sign In</span>
        </a>
      </div>
    </form>
  );
}

export default SignupForm;
