import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackArrow from "../../components/BackArrow";
import EmailVerificationStep from "../../components/emailVerificationStep";
import OTPStep from "../../components/otpStep";
import { useHookForm } from "../../hooks/hook-form";
import styles from "./index.module.scss";
import ForgetPasswordForm from "../../components/forgetPasswordForm";
import AuthLayout from "../../components/AuthLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ForgetPassword() {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(null);
  const location = useLocation();
  const { isError, errorMessage } = location.state || {};
  useEffect(() => {
    if (isError) {
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 3000, // Automatically close after 3 seconds
      });
    }
  }, []);
  return (
    <AuthLayout>
      <ToastContainer />
      <BackArrow />
      <h3 className={styles["forget-password-title"]}>Forgot Your Password?</h3>
      <p className={styles["forget-password-subtitle"]}>
        There is nothing to worry about, we'll send you an email with a link to
        reset your password.
      </p>

      <EmailVerificationStep
        setEmail={setEmail}
        useHookForm={useHookForm}
        setStep={setStep}
      />
    </AuthLayout>
  );
}
