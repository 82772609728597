import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import loadingSlice from "./slices/loadingSlice";
import salesRequests, { salesRequestsSlice } from "./slices/salesRequestsSlice";
import sidebarReducer from "./slices/sidebarSlice";
import searchReducer from "./slices/searchSlice";
import shipment from "./slices/shipmentSlice";
import myAccountTitleReducer from "./slices/myAccountTitleSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    loading: loadingSlice,
    salesRequests: salesRequestsSlice.reducer,
    sidebar: sidebarReducer,
    search: searchReducer,
    shipment: shipment.shipmentSlice.reducer,
    myAccountTitle: myAccountTitleReducer,
  },
});

export default store;
