import { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import teuLogo from "../../assets/TEU-main-logo.svg";
import MainButton from "../mainButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import creditIcon from "../../assets/credit.png";
import notificationIcon from "../../assets/notification.png";
import emptyNotificationIcon from "../../assets/empty-notifications.png";
import profileIcon from "../../assets/profile-pic.png";
// import downArrow from "../../assets/chevron-down.png";
import downArrow from "../../assets/caret.svg";
import menuIcon from "../../assets/menu-icon.svg";
import menuIconWithNotifications from "../../assets/menu-icon-with-notification.svg";
import LogoutBtn from "../LogoutBtn";
import { getProfile } from "../../services/authService";
import { useDispatch } from "react-redux";
import { setProfile, userData } from "../../store/slices/userSlice";
import avatarPlaceholder from "../../assets/user-placeholder.webp";
import accountIcon from "../../assets/account-icon.svg";
import {
  getAllRequests,
  getNotifications,
  markAllNotifications,
  markNotification,
} from "../../services/contentService";

export default function Header({
  handleSideBar,
  handleOverlay,
  layout = "user",
}) {
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const location = useLocation();
  const dispatch = useDispatch();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState([]);
  const dropdownRef = useRef(null);
  const { profile } = useSelector(userData);
  const [title, setTitle] = useState("My App");
  const type = localStorage.getItem("type");
  const myAccountTitle = useSelector((state) => state.myAccountTitle.title);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isOpen, setIsOpen] = useState(false);
  const [requestsCount, setRequestsCount] = useState(0);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const notificationDropdownRef = useRef(null);

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handling the title of the the profile page.

  useEffect(() => {
    if (location.pathname === "/profile" && myAccountTitle === null) {
      setTitle("My Account");
    } else if (
      location.pathname === "/profile" &&
      myAccountTitle === "personalData"
    ) {
      setTitle("Edit Personal Information");
    } else if (
      location.pathname === "/profile" &&
      myAccountTitle === "password"
    ) {
      setTitle("Change Password");
    }
  }, [myAccountTitle]);

  useEffect(() => {
    switch (location.pathname) {
      case "/email-verification":
        setTitle("Email Verification");
        break;
      case "/login":
        setTitle("Login");
        break;
      case "/loading":
        setTitle("Loading...");
        break;
      case "/signup":
        setTitle("Sign Up");
        break;
      case "/forget-password":
        setTitle("Forget Password");
        break;
      case "/new-password":
        setTitle("New Password");
        break;
      case "/password-changed":
        setTitle("Password Changed");
        break;
      case "/profile":
        setTitle("My Account");
        break;
      case "/request-quote":
        setTitle("Request a Quote");
        break;
      case "/check-your-email":
        setTitle("Check Your Email");
        break;
      case "/requests":
        setTitle("Requests");
        break;
      case "/dashboard":
        setTitle("Dashboard");
        break;
      case "/sales-requests":
        setTitle("Sales Requests");
        break;
      case "/shipment-tracking":
        setTitle("Shipment Tracking");
        break;
      case "/shipment-info":
        setTitle("Shipment Info");
        break;
      case "/":
        setTitle("Ship Schedules");
        break;
      default:
        setTitle("My App");
    }
  }, [location.pathname]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      handleSideBar(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Getting The Profile Data To Display The Avatar On The Header.
  const getProfileData = async () => {
    const response = await getProfile(token);
    if (response.status === 200) {
      dispatch(setProfile(response.data));
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const getAllNotifications = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "content/notifications",
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await getNotifications(config);
    if (response.status === 200) {
      setNotifications(response?.data?.data);
      setNotificationCount(response?.data?.unseen_count);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    getProfileData();
    getAllNotifications();
  }, []);
  const handleNotificationClick = async (notification) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `content/notifications/mark_as_seen/${notification.id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await markNotification(config);
    if (response.status === 200) {
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
    if (notification.type === "request") {
      if (type === "client") {
        navigate("/requests", {
          state: { requestId: notification.type_id },
        });
      } else if (type === "sales") {
        navigate("/sales-requests", {
          state: { requestId: notification.type_id },
        });
      }
    } else if (notification.type === "tracking") {
      if (type === "client") {
        navigate("/shipment-info", {
          state: { trackingNumber: notification.type_id },
        });
      } else if (type === "sales") {
        navigate("/shipment-info", {
          state: { trackingNumber: notification.type_id },
        });
      }
    }
    getAllNotifications();
  };
  const handleMarkAllAsRead = async () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `content/notifications/mark_all_as_read`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await markAllNotifications(config);
    if (response.status === 200) {
      getAllNotifications();
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const fetchRequestsCount = async () => {
    const response = await getAllRequests(`page=1`, token);
    if (response.status === 200) {
      setRequestsCount(response?.data?.count?.active);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    fetchRequestsCount();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <header className={styles.header}>
      {!isMobile ? (
        <nav className="container">
          <a className={styles.logo}>
            <img src={teuLogo} alt="teu" />
            <p>Sea Portal</p>
          </a>
          <div className={styles["web-tabs"]}>
            <ul>
              {/* <li>
                <button className={styles.credit}>
                  <img alt="credit" src={creditIcon} />
                  <p>
                    <span>5 credits</span>
                  </p>
                </button>
              </li> */}
              <li></li>
              <li>
                <div style={{ position: "relative" }}>
                  <button
                    className={styles.notificationButton}
                    onClick={handleToggleMenu}
                  >
                    <img
                      alt="notification"
                      src={
                        notificationCount === 0
                          ? emptyNotificationIcon
                          : notificationIcon
                      }
                    />
                  </button>

                  {isMenuOpen && (
                    <div
                      ref={notificationDropdownRef}
                      className={styles.dropdown}
                    >
                      <div className={styles.dropdownHeader}>
                        <h4 className={styles.dropdownTitle}>Notifications</h4>
                        <a
                          onClick={handleMarkAllAsRead}
                          className={
                            notificationCount === 0
                              ? styles["dropdownHeader-link-disabled"]
                              : styles["dropdownHeader-link"]
                          }
                        >
                          Mark all as read
                        </a>
                      </div>

                      <div className={styles.notificationsList}>
                        {notifications.map((notification) => (
                          <li
                            onClick={() =>
                              handleNotificationClick(notification)
                            }
                            key={notification.id}
                            className={`${styles.notificationItem} ${
                              notification.seen
                                ? styles.seenNotification
                                : styles.unseenNotification
                            }`}
                          >
                            <p>
                              {notification.message}{" "}
                              <span className={styles["notificationItem-date"]}>
                                {new Date(
                                  notification.created_at
                                ).toLocaleString()}
                              </span>
                            </p>
                          </li>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </li>
              {!token && (
                <li>
                  <MainButton
                    handleSubmit={() => navigate("/login")}
                    variant={"contained"}
                  >
                    Login
                  </MainButton>
                </li>
              )}
              {token && layout === "user" && (
                <li className={styles["profile-dropdown"]} ref={dropdownRef}>
                  <button
                    className={styles.profilePic}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <img
                      alt="credit"
                      src={profile?.picture ?? avatarPlaceholder}
                      className={styles["img"]}
                    />
                  </button>
                  <button
                    className={styles.profilePic}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <img
                      alt="downArrorw"
                      src={downArrow}
                      className={`${isOpen ? styles.open : ""} ${styles.arrow}`}
                    />
                  </button>
                  {isOpen && (
                    <div
                      className={
                        type === "client"
                          ? styles["dropDownOptions"]
                          : styles["salesDropDownOptions"]
                      }
                    >
                      <div
                        className={styles["item"]}
                        onClick={() => {
                          navigate("/profile");
                          setIsOpen(false);
                        }}
                      >
                        <img src={accountIcon} alt="account-icon" />
                        <a>My Account</a>
                      </div>
                      <div className={styles["item"]}>
                        <LogoutBtn />
                      </div>
                    </div>
                  )}
                </li>
              )}
            </ul>
          </div>
        </nav>
      ) : (
        <nav className="container">
          <div className={styles["mobile-tabs"]}>
            <ul>
              <li>
                <button
                  onClick={() => {
                    handleSideBar((prevState) => !prevState);
                    handleOverlay((prevState) => !prevState);
                  }}
                  className={`${styles["notification"]} ${styles["menu-icons"]}`}
                >
                  {requestsCount > 0 ? (
                    <img
                      src={menuIconWithNotifications}
                      alt="menu-with-notifications"
                    />
                  ) : (
                    <img src={menuIcon} alt="menu-icon" />
                  )}
                </button>
              </li>
              <li>
                <h4 style={{ opacity: 0.8 }}>{title}</h4>
              </li>
              <li>
                <div style={{ position: "relative" }}>
                  <button
                    className={styles.notificationButton}
                    onClick={handleToggleMenu}
                  >
                    <img
                      alt="notification"
                      src={
                        notificationCount === 0
                          ? emptyNotificationIcon
                          : notificationIcon
                      }
                    />
                  </button>

                  {isMenuOpen && (
                    <div
                      ref={notificationDropdownRef}
                      className={styles.dropdown}
                    >
                      <div className={styles.dropdownHeader}>
                        <h4 className={styles.dropdownTitle}>Notifications</h4>
                        <a
                          onClick={handleMarkAllAsRead}
                          className={
                            notifications.length === 0
                              ? styles["dropdownHeader-link-disabled"]
                              : styles["dropdownHeader-link"]
                          }
                        >
                          Mark all as read
                        </a>
                      </div>
                      <div className={styles.notificationsList}>
                        {notifications.map((notification) => (
                          <li
                            onClick={() =>
                              handleNotificationClick(notification)
                            }
                            key={notification.id}
                            className={`${styles.notificationItem} ${
                              notification.seen
                                ? styles.seenNotification
                                : styles.unseenNotification
                            }`}
                          >
                            <p>
                              {notification.message}{" "}
                              <span className={styles["notificationItem-date"]}>
                                {new Date(
                                  notification.created_at
                                ).toLocaleString()}
                              </span>
                            </p>
                            {/* <p>
                             
                            </p> */}
                          </li>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </nav>
      )}
    </header>
  );
}
