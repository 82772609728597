import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";
import styles from "./index.module.scss";

const DashboardCardSkeleton = () => {
  return (
    <div className={styles["dashboard-card-skeleton"]}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Skeleton variant="text" width={20} height={15} />
        <Skeleton variant="text" width={120} height={15} />
      </Box>
      <Box sx={{}}>
        <Skeleton variant="circular" width={114} height={114} />
      </Box>
    </div>
  );
};

export default DashboardCardSkeleton;
