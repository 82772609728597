import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import MainButton from "../mainButton";
import styles from "./index.module.scss";
function EditConfirmationModal({ open, setOpen, setEdit }) {
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setEdit("personalData");
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{ fontSize: "24px" }}
          sx={{ padding: "24px 24px 16px 24px" }}
        >
          {"Are You Sure?"}
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: "16px" }}>
          <DialogContentText>
            Please note that you will not be able to edit your information again
            untill after 60 days. <br /> Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0 24px 24px 24px" }}>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <MainButton
            className={styles["modal-confirm"]}
            handleSubmit={handleConfirm}
          >
            Yes
          </MainButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditConfirmationModal;
