const data = {
  metadata: {
    type: "BL",
    number: "242350920",
    sealine: "MAEU",
    sealine_name: "Maersk",
    status: "IN_TRANSIT",
    is_status_from_sealine: true,
    updated_at: "2024-09-05 13:28:57",
    cache_expires: "2024-09-06 13:28:57",
    api_calls: {
      total: 100,
      used: 1,
      remaining: 99,
    },
    unique_shipments: {
      total: 0,
      used: 0,
      remaining: 0,
    },
  },
  locations: [
    {
      id: 1,
      name: "Port Said East",
      state: "As Suways",
      country: "Egypt",
      country_code: "EG",
      locode: "EGPSE",
      lat: 31.216666666667,
      lng: 32.35,
      timezone: "Africa/Cairo",
    },
    {
      id: 2,
      name: "Chornomors'k",
      state: "Odeska Oblast",
      country: "Ukraine",
      country_code: "UA",
      locode: "UAILK",
      lat: 46.29914,
      lng: 30.65529,
      timezone: "Europe/Simferopol",
    },
    {
      id: 3,
      name: "Shanghai",
      state: "Shanghai Shi",
      country: "China",
      country_code: "CN",
      locode: "CNSHG",
      lat: 31.366365,
      lng: 121.61475,
      timezone: "Asia/Shanghai",
    },
    {
      id: 4,
      name: "Yokohama",
      state: "Kanagawa",
      country: "Japan",
      country_code: "JP",
      locode: "JPYOK",
      lat: 35.43333,
      lng: 139.65,
      timezone: "Asia/Tokyo",
    },
    {
      id: 5,
      name: "Constanta",
      state: "Constanta",
      country: "Romania",
      country_code: "RO",
      locode: "ROCND",
      lat: 44.18073,
      lng: 28.63432,
      timezone: "Europe/Bucharest",
    },
  ],
  facilities: [
    {
      id: 1,
      name: "EAST PORT SAID TERMINAL",
      country_code: "EG",
      locode: null,
      bic_code: null,
      smdg_code: null,
      lat: null,
      lng: null,
    },
    {
      id: 2,
      name: "YANGSHAN SGH GUANDONG TERMINAL",
      country_code: "CN",
      locode: null,
      bic_code: null,
      smdg_code: null,
      lat: null,
      lng: null,
    },
    {
      id: 3,
      name: "Maersk Yokohama terminal",
      country_code: "JP",
      locode: null,
      bic_code: null,
      smdg_code: null,
      lat: null,
      lng: null,
    },
    {
      id: 4,
      name: "CSCT Terminal",
      country_code: "RO",
      locode: null,
      bic_code: null,
      smdg_code: null,
      lat: null,
      lng: null,
    },
    {
      id: 5,
      name: "Fishing Port of Ilyichevsk",
      country_code: "UA",
      locode: null,
      bic_code: null,
      smdg_code: null,
      lat: null,
      lng: null,
    },
  ],
  route: {
    prepol: {
      location: 4,
      date: "2024-07-24 14:44:00",
      actual: true,
    },
    pol: {
      location: 4,
      date: "2024-07-30 23:55:00",
      actual: true,
    },
    pod: {
      location: 5,
      date: "2024-09-27 08:00:00",
      actual: false,
      predictive_eta: null,
    },
    postpod: {
      location: 2,
      date: "2024-10-01 22:00:00",
      actual: false,
    },
  },
  vessels: [
    {
      id: 1,
      name: "CAPE MALE",
      imo: 9440150,
      call_sign: "V7RU3",
      mmsi: 538003580,
      flag: "MH",
    },
    {
      id: 2,
      name: "MONACO",
      imo: 9314961,
      call_sign: "A8IF2",
      mmsi: 636090967,
      flag: "LR",
    },
    {
      id: 3,
      name: "MAERSK CAMBRIDGE",
      imo: 9924182,
      call_sign: "5LDM2",
      mmsi: 636021337,
      flag: "LR",
    },
  ],
  containers: [
    {
      number: "CAAU6505323",
      iso_code: "45G1",
      size_type: "40' High Cube Dry",
      status: "IN_TRANSIT",
      is_status_from_sealine: true,
      events: [
        {
          order_id: 1,
          location: 4,
          facility: 3,
          description: "Gate out",
          event_type: "EQUIPMENT",
          event_code: "GTOT",
          status: "CPS",
          date: "2024-07-23 13:47:00",
          actual: true,
          is_additional_event: false,
          type: "land",
          transport_type: null,
          vessel: null,
          voyage: null,
        },
        {
          order_id: 2,
          location: 4,
          facility: 3,
          description: "Gate in",
          event_type: "EQUIPMENT",
          event_code: "GTIN",
          status: "CEP",
          date: "2024-07-24 14:44:00",
          actual: true,
          is_additional_event: false,
          type: "land",
          transport_type: null,
          vessel: null,
          voyage: null,
        },
        {
          order_id: 3,
          location: 4,
          facility: 3,
          description: "Load",
          event_type: "EQUIPMENT",
          event_code: "LOAD",
          status: "CLL",
          date: "2024-07-30 16:31:00",
          actual: true,
          is_additional_event: false,
          type: "sea",
          transport_type: null,
          vessel: 2,
          voyage: "431S",
        },
        {
          order_id: 4,
          location: 4,
          facility: 3,
          description: "Vessel departure",
          event_type: "TRANSPORT",
          event_code: "DEPA",
          status: "VDL",
          date: "2024-07-30 23:55:00",
          actual: true,
          is_additional_event: false,
          type: "sea",
          transport_type: "VESSEL",
          vessel: 2,
          voyage: "431S",
        },
        {
          order_id: 5,
          location: 3,
          facility: 2,
          description: "Vessel arrival",
          event_type: "TRANSPORT",
          event_code: "ARRI",
          status: "VAT",
          date: "2024-08-05 22:50:00",
          actual: true,
          is_additional_event: false,
          type: "sea",
          transport_type: "VESSEL",
          vessel: 2,
          voyage: "431S",
        },
        {
          order_id: 6,
          location: 3,
          facility: 2,
          description: "Discharge",
          event_type: "EQUIPMENT",
          event_code: "DISC",
          status: "CDT",
          date: "2024-08-06 03:13:00",
          actual: true,
          is_additional_event: false,
          type: "sea",
          transport_type: null,
          vessel: 2,
          voyage: "431S",
        },
        {
          order_id: 7,
          location: 3,
          facility: 2,
          description: "Load",
          event_type: "EQUIPMENT",
          event_code: "LOAD",
          status: "CLT",
          date: "2024-08-09 01:59:00",
          actual: true,
          is_additional_event: false,
          type: "sea",
          transport_type: null,
          vessel: 3,
          voyage: "430W",
        },
        {
          order_id: 8,
          location: 3,
          facility: 2,
          description: "Vessel departure",
          event_type: "TRANSPORT",
          event_code: "DEPA",
          status: "VDT",
          date: "2024-08-09 15:00:00",
          actual: true,
          is_additional_event: false,
          type: "sea",
          transport_type: "VESSEL",
          vessel: 3,
          voyage: "430W",
        },
        {
          order_id: 9,
          location: 1,
          facility: 1,
          description: "Vessel arrival",
          event_type: "TRANSPORT",
          event_code: "ARRI",
          status: "VAT",
          date: "2024-09-17 12:00:00",
          actual: false,
          is_additional_event: false,
          type: "sea",
          transport_type: "VESSEL",
          vessel: 3,
          voyage: "430W",
        },
        {
          order_id: 10,
          location: 1,
          facility: null,
          description: "Vessel departure",
          event_type: "TRANSPORT",
          event_code: "DEPA",
          status: "VDT",
          date: "2024-09-21 13:00:00",
          actual: false,
          is_additional_event: false,
          type: "sea",
          transport_type: "VESSEL",
          vessel: 1,
          voyage: "438N",
        },
        {
          order_id: 11,
          location: 5,
          facility: 4,
          description: "Vessel arrival",
          event_type: "TRANSPORT",
          event_code: "ARRI",
          status: "VAD",
          date: "2024-09-27 08:00:00",
          actual: false,
          is_additional_event: false,
          type: "sea",
          transport_type: "VESSEL",
          vessel: 1,
          voyage: "438N",
        },
        {
          order_id: 12,
          location: 5,
          facility: null,
          description: "Gate out",
          event_type: "EQUIPMENT",
          event_code: "GTOT",
          status: "LTS",
          date: "2024-09-28 22:00:00",
          actual: false,
          is_additional_event: false,
          type: "land",
          transport_type: null,
          vessel: null,
          voyage: null,
        },
        {
          order_id: 13,
          location: 2,
          facility: 5,
          description: "Gate in",
          event_type: "EQUIPMENT",
          event_code: "GTIN",
          status: "CDC",
          date: "2024-10-01 22:00:00",
          actual: false,
          is_additional_event: false,
          type: "land",
          transport_type: null,
          vessel: null,
          voyage: null,
        },
      ],
    },
  ],
  route_data: {
    route: [
      {
        path: [
          [35.4333, 139.65],
          [34.8051, 140.019],
          [34.7926, 140.0233],
          [34.782, 140.0206],
          [34.7732, 140.011],
          [34.5101, 139.5631],
          [34.5013, 139.553],
          [34.4904, 139.5496],
          [34.4775, 139.5528],
          [33.8427, 139.856],
          [33.8303, 139.8592],
          [33.8198, 139.8561],
          [33.8112, 139.8467],
          [33.4673, 139.2661],
          [33.4603, 139.2531],
          [33.4543, 139.2396],
          [33.4495, 139.2257],
          [30.8957, 130.7086],
          [30.8909, 130.688],
          [30.8886, 130.6672],
          [30.8889, 130.6462],
          [31.5336, 122.4785],
          [31.5383, 122.4596],
          [31.549, 122.4452],
          [31.5657, 122.4353],
          [31.6372, 122.4084],
          [31.6497, 122.4006],
          [31.6548, 122.3895],
          [31.6526, 122.3751],
          [31.3664, 121.6148],
        ],
        type: "SEA",
        transport_type: "VESSEL",
      },
      {
        path: [
          [31.3664, 121.6148],
          [31.6524, 122.3746],
          [31.6548, 122.3891],
          [31.6499, 122.4003],
          [31.6376, 122.4082],
          [31.5675, 122.4346],
          [31.548, 122.444],
          [31.531, 122.4567],
          [31.5163, 122.4726],
          [30.9031, 123.2868],
          [30.8903, 123.3007],
          [30.8752, 123.3115],
          [30.8578, 123.3191],
          [30.6886, 123.3725],
          [30.6702, 123.3763],
          [30.6518, 123.3757],
          [30.6337, 123.3708],
          [30.1052, 123.1627],
          [30.0914, 123.157],
          [30.0779, 123.1508],
          [30.0646, 123.1441],
          [28.8251, 122.4928],
          [28.8121, 122.4857],
          [28.7992, 122.4783],
          [28.7865, 122.4705],
          [23.9531, 119.4045],
          [23.9406, 119.3965],
          [23.928, 119.3886],
          [23.9153, 119.3808],
          [23.5336, 119.1435],
          [23.5157, 119.133],
          [23.4974, 119.1235],
          [23.4785, 119.115],
          [8.704, 112.9062],
          [8.685, 112.8979],
          [8.6663, 112.889],
          [8.6479, 112.8796],
          [1.5884, 109.1108],
          [1.57, 109.1012],
          [1.5513, 109.0921],
          [1.5324, 109.0833],
          [-5.8096, 105.791],
          [-5.8208, 105.7842],
          [-5.8294, 105.775],
          [-5.8353, 105.7634],
          [-34.2102, 25.8073],
          [-34.2145, 25.7943],
          [-34.2182, 25.7811],
          [-34.2212, 25.7678],
          [-34.4072, 24.8483],
          [-34.411, 24.8282],
          [-34.4143, 24.8081],
          [-34.4172, 24.7879],
          [-35.0052, 20.1561],
          [-35.0073, 20.1356],
          [-35.0081, 20.1151],
          [-35.0077, 20.0945],
          [-34.9862, 19.6461],
          [-34.9849, 19.6325],
          [-34.9823, 19.6191],
          [-34.9783, 19.606],
          [-34.5435, 18.3853],
          [-34.5379, 18.3729],
          [-34.5304, 18.3619],
          [-34.521, 18.3522],
          [-12.0547, -1.0276],
          [-5.317089330523271, -5.558832048101181],
          [11.0969, -16.5977],
          [11.1122, -16.6069],
          [11.1283, -16.6146],
          [11.1451, -16.6207],
          [14.7406, -17.7419],
          [14.7558, -17.7456],
          [14.7712, -17.7474],
          [14.7867, -17.7472],
          [20.8333, -17.3203],
          [20.849, -17.319],
          [20.8646, -17.3173],
          [20.8802, -17.3152],
          [21.8168, -17.1795],
          [21.8354, -17.1761],
          [21.8536, -17.1713],
          [21.8715, -17.1653],
          [22.2477, -17.0217],
          [22.2652, -17.0145],
          [22.2824, -17.0065],
          [22.2991, -16.9976],
          [23.9197, -16.0901],
          [23.932, -16.0831],
          [23.9441, -16.076],
          [23.9563, -16.0688],
          [26.2623, -14.6782],
          [26.2742, -14.6706],
          [26.2857, -14.6625],
          [26.2967, -14.6538],
          [32.5313, -9.5425],
          [32.5473, -9.529],
          [32.5628, -9.5151],
          [32.578, -9.5007],
          [33.3693, -8.7296],
          [33.3823, -8.7167],
          [33.3951, -8.7035],
          [33.4076, -8.6901],
          [35.7783, -6.1123],
          [35.7901, -6.0983],
          [35.8005, -6.0834],
          [35.8097, -6.0676],
          [35.8556, -5.9802],
          [35.8613, -5.9674],
          [35.8655, -5.9542],
          [35.8683, -5.9404],
          [35.9571, -5.3034],
          [35.959, -5.2894],
          [35.9609, -5.2753],
          [35.9627, -5.2613],
          [36.9893, 2.7719],
          [36.9919, 2.7928],
          [36.9946, 2.8136],
          [36.9972, 2.8345],
          [37.1239, 3.8505],
          [37.1255, 3.8641],
          [37.1268, 3.8778],
          [37.1278, 3.8914],
          [37.4687, 9.1513],
          [37.4693, 9.165],
          [37.4693, 9.1786],
          [37.4689, 9.1923],
          [37.4651, 9.2666],
          [37.4639, 9.2874],
          [37.4626, 9.3082],
          [37.4611, 9.3289],
          [37.3824, 10.3491],
          [37.3804, 10.3686],
          [37.3773, 10.388],
          [37.3732, 10.4071],
          [36.9404, 12.2022],
          [36.9358, 12.2213],
          [36.931, 12.2403],
          [36.926, 12.2594],
          [31.7397, 31.9184],
          [31.7327, 31.9377],
          [31.7227, 31.9553],
          [31.7096, 31.9712],
          [31.3907, 32.2975],
          [31.3806, 32.3057],
          [31.3692, 32.311],
          [31.3565, 32.3135],
          [31.2345, 32.3237],
          [31.2236, 32.327],
          [31.2176, 32.3343],
          [31.2163, 32.3457],
          [31.2167, 32.35],
        ],
        type: "SEA",
        transport_type: "VESSEL",
      },
      {
        path: [
          [31.2167, 32.35],
          [31.2163, 32.3457],
          [31.2176, 32.3343],
          [31.2237, 32.327],
          [31.2345, 32.3237],
          [31.3565, 32.3135],
          [31.3692, 32.311],
          [31.3807, 32.3057],
          [31.3908, 32.2976],
          [36.6368, 26.9706],
          [36.651, 26.9575],
          [36.6663, 26.9459],
          [36.6828, 26.9359],
          [36.6928, 26.9304],
          [36.7083, 26.9223],
          [36.724, 26.9145],
          [36.74, 26.9073],
          [38.2495, 26.2453],
          [38.2656, 26.2383],
          [38.2817, 26.2315],
          [38.2979, 26.2247],
          [38.4722, 26.1529],
          [38.4858, 26.1471],
          [38.4992, 26.1409],
          [38.5124, 26.1344],
          [38.632, 26.0728],
          [38.645, 26.0659],
          [38.6579, 26.0586],
          [38.6705, 26.0511],
          [39.1025, 25.7852],
          [39.1185, 25.7784],
          [39.1349, 25.7774],
          [39.1515, 25.7821],
          [39.9171, 26.1407],
          [39.9331, 26.1496],
          [39.9476, 26.1606],
          [39.9604, 26.1737],
          [40.1291, 26.3744],
          [40.142, 26.3895],
          [40.1553, 26.4044],
          [40.1689, 26.419],
          [40.2109, 26.4632],
          [40.2242, 26.478],
          [40.2367, 26.4934],
          [40.2484, 26.5095],
          [40.3875, 26.7122],
          [40.3972, 26.7281],
          [40.4052, 26.7447],
          [40.4115, 26.7622],
          [40.6552, 27.5781],
          [40.6603, 27.596],
          [40.665, 27.6141],
          [40.6693, 27.6324],
          [40.9304, 28.8047],
          [40.9339, 28.8189],
          [40.9383, 28.8329],
          [40.9434, 28.8466],
          [40.9939, 28.9708],
          [41.0007, 28.9832],
          [41.01, 28.9932],
          [41.0219, 29.0008],
          [41.0032, 28.9918],
          [41.0201, 29.0014],
          [41.0352, 29.0132],
          [41.0486, 29.0272],
          [41.0301, 29.0047],
          [41.043, 29.0195],
          [41.0568, 29.0333],
          [41.0714, 29.0464],
          [41.049, 29.0277],
          [41.0674, 29.0417],
          [41.0868, 29.054],
          [41.1073, 29.0647],
          [41.0863, 29.055],
          [41.101, 29.0616],
          [41.1158, 29.0678],
          [41.1307, 29.0737],
          [41.1149, 29.0677],
          [41.1257, 29.0705],
          [41.1365, 29.0707],
          [41.1473, 29.0682],
          [41.1417, 29.0702],
          [41.1457, 29.0691],
          [41.1497, 29.0685],
          [41.1538, 29.0686],
          [41.1476, 29.0681],
          [41.1517, 29.0687],
          [41.1557, 29.0697],
          [41.1595, 29.0713],
          [41.2154, 29.0993],
          [41.2288, 29.1046],
          [41.2427, 29.1076],
          [41.257, 29.1082],
          [44.0528, 28.9735],
          [44.0657, 28.9706],
          [44.0752, 28.9633],
          [44.0814, 28.9517],
          [44.1807, 28.6343],
        ],
        type: "SEA",
        transport_type: "VESSEL",
      },
      {
        path: [
          [44.1806904, 28.6337161],
          [44.274323, 28.5571897],
          [44.7752845, 28.688693],
          [44.9153602, 28.4801996],
          [45.0251269, 28.4487212],
          [45.2426755, 28.1467366],
          [45.3414237, 28.1693852],
          [45.4098845, 28.0428815],
          [45.4665434, 28.1850386],
          [45.5803442, 28.169117],
          [46.1746466, 30.0498497],
          [46.1822855, 30.3188324],
          [46.062305, 30.4509044],
          [46.1450565, 30.5261135],
          [46.2356615, 30.6078136],
          [46.2988944, 30.6552527],
        ],
        type: "LAND",
        transport_type: "TRUCK",
      },
    ],
    pin: [-5.317089330523271, -5.558832048101181],
    ais: {
      status: "OK",
      data: {
        last_event: {
          description: "Vessel departure",
          date: "2024-08-09 15:00:00",
          voyage: "430W",
        },
        discharge_port: {
          name: "Constanta",
          country_code: "RO",
          code: "CND",
          date: "2024-09-27 08:00:00",
          date_label: "ETA",
        },
        vessel: {
          name: "MAERSK CAMBRIDGE",
          imo: 9924182,
          call_sign: "5LDM2",
          mmsi: 636021337,
          flag: "LR",
        },
        last_vessel_position: {
          lat: -12.05474,
          lng: -1.027637,
          updated_at: "2024-09-04 08:38:35",
        },
        departure_port: {
          country_code: "SG",
          code: "SIN",
          date: "2024-08-17 23:31:00",
          date_label: "ATD",
        },
        arrival_port: {
          country_code: "EG",
          code: "PSD",
          date: "2024-09-17 07:00:00",
          date_label: "ETA",
        },
        updated_at: "2024-09-05 13:33:44",
      },
    },
  },
};

export default data;
