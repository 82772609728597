import React from "react";
import styles from "./index.module.scss";
import { CircularProgress, Typography } from "@mui/material";

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.6rem",
      }}
    >
      <CircularProgress sx={{ color: "#157ffb" }} size={25} />
      <Typography sx={{ color: "#157ffb", fontWeight: 500 }}>
        loading...
      </Typography>
    </div>
  );
};

export default LoadingSpinner;
