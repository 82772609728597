import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import OriginPort from "../originPort";
import DestinationPort from "../destinationPort";
import searchIcon from "../../assets/search.png";
import searchBlue from "../../assets/search-blue.svg";
import arrowIcon from "../../assets/caret_icon.svg";
import DateInput from "../dateInput";
import { getCargos } from "../../services/testService";
import Search from "../../assets/svgs/Search";
import DropdownMultipleWithCount from "../DropdownMultipleWithCount";
import CargoDropdown from "../CargoDropdown";
import formatDateToYYYYMMDD from "../../utils/formatDate";
import AutoComplete from "../AutoComplete";
import { getPorts } from "../../services/contentService";
import { useDispatch } from "react-redux";
import {
  setOriginPort,
  setDestinationPort,
  setReadyOn,
  setIsOriginChanged,
  setIsDestinationChanged,
  setIsReadyOnChanged,
} from "../../store/slices/searchSlice";
import { use } from "i18next";

// const containerStyles = {
//   height: "3.2rem",
//   width: "100%",
//   background: "rgba(255, 255, 255, 0.17)",
//   border: "1px solid rgba(202, 202, 202, 0.5)",
//   borderRadius: "10px",
//   "& .MuiOutlinedInput-root": {
//     background: "none",
//   },
//   "& .MuiSelect-select": {
//     color: "white",
//   },
//   "& .MuiSelect-outlined": {
//     color: "white",
//   },
//   "& .MuiInputBase-input": {
//     color: "white",
//   },
//   "& .MuiOutlinedInput-input": {
//     color: "white",
//   },
//   "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
//     color: "white", // Ensure this is the most specific selector
//   },
//   "& .MuiOutlinedInput-root": {
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderColor: "white", // Change the outline border color to white
//     },
//     "&:hover .MuiOutlinedInput-notchedOutline": {
//       borderColor: "white", // Change the outline border color to white on hover
//     },
//     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//       borderColor: "white", // Change the outline border color to white when focused
//     },
//   },
// };
// const labelStyle = {
//   color: "#0D0E0E",
//   fontWeight: "500",
//   overflow: "visible",
// };
export default function FreightSearch({ handleSearch, isPressed }) {
  // const [selectedOriginPort, setSelectedOriginPort] = useState();
  // const [selectedDestinationPort, setSelectedDestinationPort] = useState();
  // const [cargo, setCargo] = useState([]);
  const dispatch = useDispatch();

  const [cargos, setCargos] = useState([]);
  const [date, SetDate] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // const handleCount = (id, op) => {
  //   const newArr = [];
  //   for (const item of cargo) {
  //     if (item.type == id) {
  //       if (op == "+") {
  //         if (item.count < 99) {
  //           item.count = item.count + 1;
  //           //console.log(item.count);
  //         }
  //       } else {
  //         item.count = item.count - 1;
  //       }
  //     }
  //     if (item.count !== 0) {
  //       newArr.push(item);
  //     }
  //   }
  //   //  / setStepThreeData({ ...stepThreeData, containers: newArr });
  //   setCargo(newArr);
  // };
  // const handleContainerChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   const lastSelected = value[value.length - 1];
  //   const found = cargo?.find((item) => item.type == lastSelected.type);
  //   if (found) {
  //     const newValue = cargo?.filter((item) => item.type != lastSelected.type);
  //     setCargo(newValue);
  //   } else {
  //     setCargo(
  //       value.map((item) => {
  //         return { ...item, count: item.count ?? 1 };
  //       })
  //     );
  //   }
  // };
  // const fetchPorts = async (port) => {
  //   const response = await getPorts(port);
  //   // //console.log("ports", response.data.results);
  //   setPorts(response.data.results);
  // };
  const fetchCarogs = async () => {
    const response = await getCargos();
    //console.log("cargos", response.data.cargo);
    setCargos(response.data.cargo);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // useEffect(() => {
  //   fetchPorts(port);
  // }, [port, setPort]);
  useEffect(() => {
    fetchCarogs();
  }, []);

  // useEffect(() => {
  //   //console.log("cargo", cargo);
  // }, [cargo]);

  // The Origin and Destination Ports New Logic From Request Quotation.
  const [ports, setPorts] = useState({ results: [] });
  const [portsPod, setPortsPod] = useState({ results: [] });
  const [portsPage, setPortsPage] = useState(1);
  const [portsPagePod, setPortsPagePod] = useState(1);
  const [portsInputValue, setPortsInputValue] = useState("");
  const [portsInputValuePod, setPortsInputValuePod] = useState("");
  const [loading, setLoading] = useState(false);
  const [portCode, setPortCode] = useState(null);
  const [portsValues, setportsValues] = useState({});
  // For Pol
  const getAllPorts = async (params = "") => {
    setLoading(true);
    try {
      const response = await getPorts(params);
      if (response.status === 200) {
        //console.log(response.data);
        setPorts({
          ...response.data,
          results:
            portsPage > 1
              ? [...ports.results, ...response.data.results]
              : response.data.results,
        });
      }
    } catch (err) {
      //console.log("err", err);
    }
    setLoading(false);
  };
  // For Pod
  const getAllPortsPod = async (params = "") => {
    setLoading(true);
    try {
      const response = await getPorts(params);
      if (response.status === 200) {
        //console.log(response.data);
        setPortsPod({
          ...response.data,
          results:
            portsPagePod > 1
              ? [...portsPod.results, ...response.data.results]
              : response.data.results,
        });
      }
    } catch (err) {
      //console.log("err", err);
    }
    setLoading(false);
  };
  // For Pol
  const handlePortsScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 10;

    //console.log("bottom", bottom);
    if (bottom && !loading && portsPage < ports.total_pages) {
      setPortsPage((prevPage) => prevPage + 1);
    }
  };
  // For Pod
  const handlePortsScrollPod = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop <=
      event.target.clientHeight + 10;

    //console.log("bottom", bottom);
    if (bottom && !loading && portsPagePod < portsPod.total_pages) {
      setPortsPagePod((prevPage) => prevPage + 1);
    }
  };
  // For Pol
  const getQueryParams = () => {
    const queryParams = [];
    portsPage > 1 && queryParams.push(`page=${portsPage}`);
    portsInputValue && queryParams.push(`value=${portsInputValue}`);
    portCode && queryParams.push(`locode=${portCode}`);
    return queryParams.join("&");
  };
  // For Pod
  const getQueryParamsPod = () => {
    const queryParamsPod = [];
    portsPagePod > 1 && queryParamsPod.push(`page=${portsPagePod}`);
    portsInputValuePod && queryParamsPod.push(`value=${portsInputValuePod}`);
    portCode && queryParamsPod.push(`locode=${portCode}`);
    return queryParamsPod.join("&");
  };
  // For Pol
  const handlePolChange = (event, newValue) => {
    //console.log("new value", newValue);
    setPortsInputValue("");
    dispatch(setOriginPort(newValue));
    dispatch(setIsOriginChanged(true));
    setportsValues({ ...portsValues, pol: newValue });
  };
  // For Pod
  const handlePodChange = (event, newValue) => {
    //console.log("new value", newValue);
    setPortsInputValuePod("");
    dispatch(setDestinationPort(newValue));
    dispatch(setIsDestinationChanged(true));
    setportsValues({ ...portsValues, pod: newValue });
  };
  // For Pol
  useEffect(() => {
    const params = getQueryParams();
    getAllPorts(params);
  }, [portsInputValue, portsPage]);
  // For Pod
  useEffect(() => {
    const params = getQueryParamsPod();
    getAllPortsPod(params);
  }, [portsInputValuePod, portsPagePod]);

  const handleDateChange = (value) => {
    //console.log("type", value?.$d);
    SetDate(value);
    dispatch(setIsReadyOnChanged(true));
  };

  useEffect(() => {
    dispatch(setReadyOn(formatDateToYYYYMMDD(date)));
  }, [date]);

  return (
    <div className={styles["search"]}>
      <AutoComplete
        optionLabel="value"
        handleScroll={handlePortsScroll}
        handleInputChange={(event, val) => {
          setPortsInputValue(val);
        }}
        inputValue={portsInputValue}
        options={ports?.results}
        label={"Origin Port"}
        handleChange={handlePolChange}
        value={portsValues?.pol ?? null}
        placeholder={"Enter origin port"}
        inputClassName={styles["input-container-styles"]}
        className={styles["input-styles"]}
        labelClassName={styles["label-styles"]}
        quoteForm={false}
      />
      <div onClick={handleSearch} className={styles["search-arrow"]}>
        <img alt="search" src={arrowIcon} />
      </div>
      <AutoComplete
        optionLabel="value"
        handleScroll={handlePortsScrollPod}
        handleInputChange={(event, val) => setPortsInputValuePod(val)}
        inputValue={portsInputValuePod}
        options={portsPod?.results}
        label={"Destination Port"}
        handleChange={handlePodChange}
        value={portsValues?.pod ?? null}
        placeholder={"Enter destination port"}
        inputClassName={styles["input-container-styles"]}
        className={styles["input-styles"]}
        labelClassName={styles["label-styles"]}
        quoteForm={false}
      />
      <CargoDropdown options={cargos} />
      <div className={styles["date-search-container"]}>
        <DateInput
          iconColor={"dark"}
          handleChange={handleDateChange}
          label="Starting from"
          placeholderShowed={false}
          placeholder={"Select date"}
          className={styles["search-date"]}
          dateLabel={styles["date-label"]}
          theme={true}
          disablePast={true}
        />
        <div className={styles["search-action-container"]}>
          <button
            onClick={handleSearch}
            className={
              isPressed
                ? styles["search-action-pressed"]
                : styles["search-action"]
            }
            disabled={isPressed}
          >
            <Search isPressed={isPressed} />
          </button>
        </div>
      </div>
      {/* <OriginPort
        ports={ports}
        setSelectedOriginPort={setSelectedOriginPort}
        setSearchPort={setOriginPort}
      /> */}
      {/* <DestinationPort
        ports={ports}
        setSearchPort={setDestinationPort}
        setSelectedDestinationPort={setSelectedDestinationPort}
      /> */}
      {/* <Cargo cargos={cargos} cargo={cargo} setCargo={setCargo} /> */}
      {/* <DropdownMultipleWithCount
        label={"Cargo"}
        value={cargo}
        onChange={handleContainerChange}
        placeholder={"Select cargo "}
        options={cargos}
        handleCount={handleCount}
        labelStyle={labelStyle}
        background={false}
        containerStyle={containerStyles}
        className={styles["cargo-styles"]}
        customPlaceholderColor={styles["placeholder-color"]}
        // valueKey="id"
      /> */}
    </div>
  );
}
