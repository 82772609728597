import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./index.module.scss";
import Slider from "react-slick";
import { useRef } from "react";
import NextSliderArrow from "../SVGs/NextSliderArrow";
import PreviousSliderArrow from "../SVGs/PreviousSliderArrow";

const Carousel = ({
  showDots = false,
  slidesToShow = 1,
  slidesToScroll = 1,
  children,
  showArrows = false,
  carouselArrows = "",
}) => {
  const settings = {
    dots: showDots,
    infinite: true,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    arrows: true,
  };
  const sliderRef = useRef(null);

  const nextSlide = () => {
    sliderRef?.current?.slickNext();
  };
  const previousSlide = () => {
    sliderRef?.current?.slickPrev();
  };
  return (
    <div className={styles["slider-container"]}>
      <div className={`${styles["slider-container-arrows"]} ${carouselArrows}`}>
        <button onClick={previousSlide}>
          <PreviousSliderArrow />
        </button>
        <button onClick={nextSlide}>
          <NextSliderArrow />
        </button>
      </div>
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>
    </div>
  );
};

export default Carousel;
