import styles from "./index.module.scss";

const TextArea = ({ formRegister, label, placeholder, onChange, value }) => {
  return (
    <div className={styles["container"]}>
      <label>{label}</label>
      <textarea
        {...formRegister}
        placeholder={placeholder}
        rows={3}
        maxLength={500}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default TextArea;
