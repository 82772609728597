import { loadState, saveState } from "../../helpers/sessionStorage";
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    token: loadState("token", ""),
    profile: loadState("profile", ""),
  },
  reducers: {
    setUser: (state, action) => {
      saveState("token", action.payload.token);
      saveState("profile", action.payload.user);
      return action.payload;
    },
    setProfile: (state, action) => {
      saveState("profile", action.payload);
      state.profile = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProfile, setUser } = userSlice.actions;

export const userData = (state) => state.user;

export default userSlice.reducer;
