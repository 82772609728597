import React, { act, useEffect, useState } from "react";
import styles from "./index.module.scss";
import HorizontalTabs from "../../components/HorizontalTabs";
import ArrowTab from "../../components/SVGs/ArrowTab";
import ToggleTab from "../../components/SVGs/ToggleTab";
import ClockTab from "../../components/SVGs/ClockTab";
import TrueTab from "../../components/SVGs/TrueTab";
import tick from "../../assets/Select.svg";
import ExpireTab from "../../components/SVGs/ExpireTab";
import OfferSuccess from "../../assets/offer-success.svg";
import Actions from "../../components/Actions";
import RequestsTable from "../../components/RequestsTable";
import Pagination from "../../components/Pagination";
import BaseModal from "../../components/BaseModal";
import ClientDetails from "../../components/ClientDetails";
import RequestDetails from "../../components/RequestDetails";
import MainButton from "../../components/mainButton";
import OfferForm from "../../components/OfferForm";
import {
  getRequestInfo,
  getSalesRequests,
  toggleStar,
} from "../../services/contentService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  selectAttachment,
  selectCriteria,
  selectFromDate,
  selectOpenedRequestID,
  selectToDate,
} from "../../store/slices/salesRequestsSlice";
import Spinner from "../../components/Spinner";
import salesRequests from "../../store/slices/salesRequestsSlice";
import { useNavigate } from "react-router-dom";
import QuoteModal from "../../components/quoteModal";

function SalesRequests() {
  const [activeTab, setActiveTab] = useState("");
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false);
  const [showSendOfferModal, setShowSendOfferModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [salesRequests, setSalesRequests] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const location = useLocation();
  const { requestId } = location.state || {};

  const [modalData, setModalData] = useState(null);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [count, setCount] = useState(null);
  const [order, setOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const select = useSelector;
  const navigate = useNavigate();
  const from = select(selectFromDate);
  const to = select(selectToDate);
  const criteria = select(selectCriteria);
  const attachment = select(selectAttachment);
  const ID = select(selectOpenedRequestID);
  // const token = localStorage.getItem("token");
  const [requestInfo, setRequestInfo] = useState(null);
  const fetchRequestDetails = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/request?id=${ID}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await getRequestInfo(config);

    if (response?.status === 200) {
      setRequestInfo(response?.data?.data);
      //console.log(response?.data?.data);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const fetchRequestByID = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/request?id=${requestId}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await getRequestInfo(config);

    if (response?.status === 200) {
      setModalData(response?.data?.data);
      setShowOfferModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    if (ID) {
      fetchRequestDetails();
    }
  }, [ID]);
  //console.log(activeTab);
  const tabs = [
    {
      name: "All",
      slug: "",
      icon: ToggleTab,
    },
    {
      name: "New",
      slug: "pending",
      count: salesRequests && salesRequests?.count?.pending,
      icon: ArrowTab,
    },

    {
      name: "Active",
      slug: "active",

      icon: ClockTab,
    },
    {
      name: "Accepted",
      slug: "accepted",
      count: salesRequests && salesRequests?.count?.accepted,
      icon: TrueTab,
    },
    {
      name: "Expired",
      slug: "expired",
      icon: ExpireTab,
    },
  ];
  const handleClose = () => {
    setShowRequestDetailsModal(false);
    fetchRequests();
  };
  const fetchRequests = async () => {
    const formData = new FormData();
    // //console.log(activeTab, order, pageNumber, from, to, searchTerm, criteria);
    // //console.log(criteria);
    if (activeTab !== "") formData.append("status", activeTab);
    if (order !== "") formData.append("order", order);
    if (pageNumber) formData.append("page", pageNumber);
    if (from !== null) formData.append("from_date", from);
    if (to !== null) formData.append("to_date", to);
    if (searchTerm !== null) formData.append("request_id", searchTerm);
    if (criteria !== "") formData.append("criteria", criteria);
    for (let [key, value] of formData.entries()) {
      //console.log(key + ":", value);
    }
    const queryString = new URLSearchParams(formData).toString();
    const finalQueryString = queryString.replace(
      /criteria=[^&]+/,
      `criteria=${criteria}`
    );
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/requests${
        finalQueryString ? `?${finalQueryString}` : ""
      }`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      params: formData,
    };
    setLoading(true);
    const response = await getSalesRequests(config);

    if (response?.status === 200) {
      //console.log(response.data);
      setSalesRequests(response.data);
      setLoading(false);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      setLoading(false);
    } else {
      setLoading(false);
      //console.log(response);
    }
  };

  const toggleFavorite = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `/content/request/mark?id=${id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await toggleStar(config);

    if (response?.status === 200) {
      fetchRequests();
      //console.log("success");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const handleSendOffer = () => {
    setShowRequestDetailsModal(false);
    setShowSendOfferModal(true);
    // fetchRequests();
  };
  const handleSend = () => {
    setShowSendOfferModal(false);
    setShowSuccessModal(true);
  };
  const handleSuccess = () => {
    setShowSuccessModal(false);
    fetchRequests();
  };
  useEffect(() => {
    fetchRequests();
  }, [activeTab]);
  useEffect(() => {
    fetchRequests();
  }, [searchTerm]);
  useEffect(() => {
    fetchRequests();
  }, [pageNumber]);
  useEffect(() => {
    if (from === null && to === null) {
      fetchRequests();
    }
  }, [from, to]);
  useEffect(() => {
    if (requestId) {
      fetchRequestByID();
    }
  }, [requestId]);
  useEffect(() => {
    fetchRequests();
    setPageNumber(1);
  }, [order]);
  useEffect(() => {
    if (ID) {
      fetchRequestDetails();
    }
  }, [ID, showSendOfferModal]);

  return (
    <div className={styles["sales-requests"]}>
      <h1 className={styles["sales-requests-title"]}>Requests</h1>
      <div className={styles["sales-requests-tabs"]}>
        <HorizontalTabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          role={"sales"}
          layout={"sales"}
        />
      </div>
      <Actions
        fetchRequests={fetchRequests}
        setOrder={setOrder}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
      />
      {loading ? (
        <Spinner />
      ) : (
        <RequestsTable
          setShowModal={setShowRequestDetailsModal}
          salesRequestsData={salesRequests}
          toggleFavorite={toggleFavorite}
        />
      )}

      <Pagination
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        itemsPerPage={salesRequests && salesRequests?.count?.division}
        totalItems={salesRequests && salesRequests?.count?.total_count}
      />
      {/*Request details Modal */}
      <BaseModal
        title={"Client and Request Details"}
        isOpen={showRequestDetailsModal}
        handleClose={handleClose}
      >
        <ClientDetails />
        <h3 className={styles["sales-requests-h3"]}>Request Details</h3>
        <RequestDetails salesRequests={salesRequests} />
        <div className={styles["sales-requests-button"]}>
          {requestInfo?.request_status === "active" ? (
            <MainButton
              className={styles["sales-requests-button-btn-disabled"]}
              // handleSubmit={handleSendOffer}
              disabled={true}
            >
              <img src={tick} />
              <p>Offer Sent</p>
            </MainButton>
          ) : (
            <MainButton
              className={styles["sales-requests-button-btn"]}
              handleSubmit={handleSendOffer}
            >
              Send Offer
            </MainButton>
          )}
        </div>
      </BaseModal>
      {/*Send Offer Modal */}
      <BaseModal
        title={"Submit Quotation"}
        isOpen={showSendOfferModal}
        handleClose={() => setShowSendOfferModal(false)}
      >
        <OfferForm
          setShowSuccessModal={setShowSuccessModal}
          setShowSendOfferModal={setShowSendOfferModal}
          fetchRequests={fetchRequests}
        />
      </BaseModal>
      {/*Success Modal */}
      <BaseModal
        // title={"Request & Client Details"}
        isOpen={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        isTitle={false}
      >
        <div className={styles["sales-requests-success-modal"]}>
          <img src={OfferSuccess} />
          <h3 className={styles["sales-requests-h3"]}>
            Offer Submitted Successfully{" "}
          </h3>
          <h6 className={styles["sales-requests-h6"]}>
            The client will be notified of the offer you submitted. Once the
            client accepts the offer, you will also be notified to start
            contacting the client for shipping documents and booking the
            shipment.{" "}
          </h6>

          <div className={styles["sales-requests-button-success"]}>
            <MainButton
              className={styles["sales-requests-button-success-btn"]}
              handleSubmit={handleSuccess}
            >
              Back to requests
            </MainButton>
          </div>
        </div>
      </BaseModal>
      {showOfferModal && modalData && (
        <QuoteModal
          handleClose={() => setShowOfferModal(false)}
          isOpen={showOfferModal}
          title={"Quotation Details"}
          id={modalData.id}
          pdfUrl={modalData.attachment}
          expiration_date={modalData.expiration_date}
          request_status={modalData.request_status}
          role="sales"
        />
      )}
    </div>
  );
}

export default SalesRequests;
