import React from "react";
import BackArrowIcon from "../../assets/BackArrowIcon.svg";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
function BackArrow() {
  const navigate = useNavigate();
  return (
    <button className={styles["img-btn"]} onClick={() => navigate(-1)}>
      <img src={BackArrowIcon} alt="BackArrowIcon" />
    </button>
  );
}

export default BackArrow;
