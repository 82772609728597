import styles from "./index.module.scss";
import clock from "../../assets/clock-icon.svg";
import moment from "moment/moment";
import stateIcon from "../../assets/state.svg";
import truck from "../../assets/truck2.svg";
import ship from "../../assets/ship2.svg";
import { memo, useEffect, useState } from "react";
import formatDate from "../../utils/formateDate";

// For Now Before Integration
import maersk from "../../assets/maersk-icon.png";

const DashboardTimeline = ({ lastestShipment }) => {
  const {
    commodity,
    pod_country,
    pod_date,
    pod_name,
    pol_country,
    pol_country_flag,
    pol_date,
    pol_name,
    post_pod_date,
    post_pod_country,
    post_pod_name,
    pre_pol_country,
    pre_pol_date,
    pre_pol_name,
    scac,
    status,
    tracking_number,
    tracking_type,
    pod_country_flag,
    pod_unlocode,
    pol_unlocode,
    pre_pod_unlocode,
    pre_pol_actual,
    pre_pol_country_flag,
    pre_pol_unlocode,
    id,
  } = lastestShipment;

  const [trackingType, setTrackingType] = useState("");
  const [trackingStatus, setTrackingStatus] = useState("");

  // Switching on the tracking type.
  useEffect(() => {
    switch (tracking_type) {
      case "booking_number":
        setTrackingType("Booking Number");
        break;
      case "bill_of_lading":
        setTrackingType("Bill Of Loading");
        break;
      case "container":
        setTrackingType("Container");
        break;
      default:
        setTrackingType("Tracking Type");
    }
  }, [tracking_type]);

  // Switching on the status.
  useEffect(() => {
    switch (status) {
      case "planned":
        setTrackingStatus("Planned");
        break;
      case "in-transit":
        setTrackingStatus("In Transit");
        break;
      case "delivered":
        setTrackingStatus("Delivered");
        break;
      case "unknown":
        setTrackingStatus("Unknown");
        break;
      case "error":
        setTrackingStatus("Error");
        break;
      default:
        setTrackingStatus("Tracking Status");
    }
  }, [status]);

  function readinessDateFormat(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }
  function getDaysBetween(date1, date2) {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    const diffInMs = Math.abs(secondDate - firstDate);
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  }
  return (
    <div className={styles["card"]}>
      <div className={`${styles["card-row"]}`}>
        <div className={styles["card-row-header"]}>
          <p>
            {trackingType} <span>{tracking_number}</span>
          </p>
        </div>
        <div className={styles["card-row-status"]}>
          <p>{trackingStatus}</p>
        </div>
      </div>

      <hr />
      {/* Request Card TimeLine */}
      <div className={styles["card-shipping-row"]}>
        {/* Origin Content */}
        <div
          className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-origin"]}`}
        >
          <>
            <img src={stateIcon} />
            <p className={styles["card-shipping-row-item-strong"]}>
              {pre_pol_name}
            </p>
            <p className={styles["card-shipping-row-item-country"]}>
              {pre_pol_country}
            </p>
            <p>
              Readiness: <span>{readinessDateFormat(pre_pol_date)}</span>
            </p>
          </>
        </div>
        {/* Origin Separator */}
        <div
          className={`${styles["card-shipping-row-separator-container"]} 
            ${styles["card-shipping-row-separator-container-origin"]}`}
        >
          <img src={truck} />
          <div className={styles["card-shipping-row-separator"]}></div>
        </div>
        {/* Port Of Loading */}
        <div
          className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-pol"]}`}
        >
          <img src={pol_country_flag} />
          <p className={styles["card-shipping-row-item-strong"]}>{pol_name}</p>
          <p className={styles["card-shipping-row-item-country"]}>
            {pol_country}
          </p>
          <p>
            ETD: <span>{formatDate(pol_date)}</span>
          </p>
        </div>
        {/* The Blue Separator */}
        <div className={styles["card-shipping-row-separator-container"]}>
          <div className={styles["transit"]}>
            <img src={ship} />
            <span>{getDaysBetween(pol_date, pod_date)} days</span>
          </div>
          <div
            className={`${styles["card-shipping-row-separator"]} ${styles["card-shipping-row-blue-separator"]}`}
          ></div>
        </div>
        {/* Port Of Discharge */}
        <div
          className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-pod"]}`}
        >
          <img src={pod_country_flag} />
          <p className={styles["card-shipping-row-item-strong"]}>{pod_name}</p>
          <p className={styles["card-shipping-row-item-country"]}>
            {pod_country}
          </p>
          <p>
            ETA: <span>{formatDate(pod_date)}</span>
          </p>
        </div>
        {/* Destination Separator */}
        <div
          className={`${styles["card-shipping-row-separator-container"]} 
          ${styles["card-shipping-row-separator-container-destination"]}`}
        >
          <img src={truck} />
          <div className={styles["card-shipping-row-separator"]}></div>
        </div>
        {/* Destination Content */}
        <div
          className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-destination"]}`}
        >
          <img src={stateIcon} />
          <p className={styles["card-shipping-row-item-strong"]}>
            {post_pod_name}
          </p>
          <p className={styles["card-shipping-row-item-country"]}>
            {post_pod_country}
          </p>
          <p>
            Delivery: <span>{formatDate(post_pod_date)}</span>
          </p>
        </div>
      </div>
      <hr />

      <div className={`${styles["card-row"]}`}>
        <div className={styles["timeline-footer"]}>
          <div className={styles["timeline-footer-item"]}>
            <img src={maersk} alt="shipping-line" />
            <h4>{scac}</h4>
          </div>
          <div className={styles["timeline-footer-item"]}>
            <h3>Commodity:</h3>
            <p>{commodity}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardTimeline);
