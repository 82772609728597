import { Checkbox, FormControlLabel } from "@mui/material";
import styles from "./index.module.scss";

const FormCheckbox = ({ checked, handleChange, label }) => {
  return (
    <div className={styles["checkbox-container"]}>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: "#bababa",
              "&.Mui-checked": {
                color: "#157FFB",
              },
              "&, & + .MuiFormControlLabel-label": {
                fontFamily: "Poppins !important",
              },
            }}
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={label}
      />
    </div>
  );
};

export default FormCheckbox;
