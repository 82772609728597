import styles from "./index.module.scss";
import FormInput from "../../components/formInput";
import PasswordValidations from "../../components/passwordValidations";
import { useHookForm } from "../../hooks/hook-form";
import MainButton from "../../components/mainButton";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchSignupAvailability, login } from "../../services/authService";
import { useDispatch } from "react-redux";
import { setProfile, setUser } from "../../store/slices/userSlice";
import Email from "../../assets/Email.svg";
import AuthLayout from "../../components/AuthLayout";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TeuLogo from "../../assets/TEU_logo_metadata.png";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [register, setRegister] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { isError, errorMessage } = location.state || {};
  const {
    handleSubmit,
    errors,
    isDirty,
    isValid,
    emailRegister,
    requiredPasswordRegister,
    setValue,
    dirtyFields,
    getValues,
    watch,
    values,
  } = useHookForm({
    isEmail: true,
    isRequiredPassword: true,
  });
  const email = watch("email"); // Watch email field
  const password = watch("password"); // Watch password field

  const handleFormSubmit = async (data) => {
    setIsLoading(true);
    const response = await login(data);

    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("type", response.data?.user?.type);
      localStorage.setItem("user-tier", response.data?.user?.user_tier);
      dispatch(setUser(response.data));
      navigate("/");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
      setError(response.data?.error);
    } else {
      //console.log(response);
      setError(response.data?.error);
    }
    setIsLoading(false);
  };
  const handleForgetPassword = (data) => {
    navigate("/forget-password");
  };
  const handleSignUp = async () => {
    const response = await FetchSignupAvailability();

    if (response.status === 200 && response.data.signup_availability == true) {
      navigate("/email-verification");
    }
  };
  const handleSignUpAvailability = async () => {
    const response = await FetchSignupAvailability();

    if (response.status === 200) {
      setRegister(response.data.signup_availability);
      // //console.log(response.data);
    }
  };
  useEffect(() => {
    // if (email) {
    //   setValue("email", email);

    //   //console.log(email);
    //   //console.log(isValid);

    console.warn("email : ", getValues());
    // }
  }, [email, setValue]);
  useEffect(() => {
    if (password) {
      //console.log(password);
      setValue("password", password);
      //console.log(isValid);
    }
  }, [password, setValue]);
  useEffect(() => {
    if (isError) {
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: false,
        theme: "colored",
      });
    }
  }, []);
  useEffect(() => {
    handleSignUpAvailability();
  }, []);

  return (
    <AuthLayout customBg={styles["custom-bg"]}>
      <ToastContainer />
      <form
        noValidate
        onSubmit={handleSubmit(handleFormSubmit)}
        className={styles["login-form"]}
      >
        <div>
          <div className={styles["login-header"]}>
            <h3 className={styles["login-title"]}>
              <img
                src={TeuLogo}
                style={{ width: "40px", height: "40px" }}
                alt="Welcome To Teu"
              />{" "}
              Sea Portal
            </h3>
            <h3 className={styles["login-subtitle"]}>Sign In</h3>
          </div>
          <div className={styles["login-field"]}>
            <p>Email</p>
            <FormInput
              name="email"
              error={errors["email"]}
              placeholder={"Enter your email"}
              formRegister={emailRegister}
              labelIcon={true}
              labelIconName={"Email"}
              value={email}
            />
          </div>
          <div className={styles["login-field"]}>
            <p>password</p>
            <FormInput
              name="password"
              type="password"
              error={errors["password"]}
              placeholder={"Enter your password"}
              formRegister={requiredPasswordRegister}
              labelIcon={true}
              labelIconName={"Lock"}
              value={password}
            />
          </div>

          <div className={styles["tabs"]}>
            <a
              className={styles["forget-password"]}
              onClick={handleForgetPassword}
            >
              Forgot password?
            </a>
          </div>
          {error && <p className={styles["error"]}>{error}</p>}
        </div>
        <div style={{ gap: "1rem" }}>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <MainButton type={"submit"} disabled={!isValid || !isDirty}>
              Sign In
            </MainButton>
          )}
          {register && (
            <a className={styles["sign-up"]} onClick={handleSignUp}>
              Don't have an account? <span>Register</span>
            </a>
          )}
        </div>
      </form>
    </AuthLayout>
  );
}
