export default function formatDate(dateString) {
  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Extract the day of the month
  const day = date.getDate();

  // Extract the month and get its abbreviated name
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const month = months[date.getMonth()];

  // Return the formatted date
  return `${day} ${month}`;
}
