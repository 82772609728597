import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
const CountryCodeSelector = ({ setCode }) => {
  const countryCode = localStorage.getItem("countryCode");
  const [phone, setPhone] = useState(countryCode);
  const handleChange = (val) => {
    // //console.log("codeeee", val);
    setPhone(val);
    localStorage.setItem("countryCode", val);
    setCode(val);
  };
  const location = useLocation();
  const pathname = location.pathname;
  // //console.log(pathname);
  // useEffect(() => {
  //   //console.log("phone", phone);
  // }, [phone]);

  const customStyle = {
    input: {
      display: "none",
      width: "0px",
      height: "40px",
      fontSize: "16px",
      paddingLeft: "0px",
      // border: "0px solid #ccc",
      borderRadius: "8px",
    },
    flagDropdown: {
      borderRadius: "0.5rem",
      height: "2.7rem",
      width: "60px",
      border: " 0px solid rgba(238, 238, 238, 1)",
      marginRight: "5px",
      // padding: "0px",
      paddingInline: "1rem",
      backgroundColor: "white",
    },
    dropdown: {
      marginTop: pathname === "/profile" ? "0.5rem" : "3rem",
    },
    container: {
      width: "auto",
      // marginBottom: "0.5rem",
      marginRight: "3.5rem",
    },
  };

  return (
    <PhoneInput
      // country={"eg"}
      value={phone}
      onChange={(val) => handleChange(val)}
      inputStyle={customStyle.input}
      buttonStyle={customStyle.flagDropdown}
      dropdownStyle={customStyle.dropdown}
      containerStyle={customStyle.container}
    />
  );
};

export default CountryCodeSelector;
