import React from "react";
import { Skeleton, Box, Typography, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Spinner = () => {
  return (
    <Box
      sx={{
        padding: "16px",
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        marginTop: "24px",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
