import React from "react";

function ClockTab({ color }) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1666_7795)">
        <path
          d="M17.9065 15.3693C17.5603 15.1118 17.0709 15.1837 16.8134 15.5298C16.6717 15.7202 16.5202 15.907 16.3629 16.0849C16.0771 16.4081 16.1075 16.9019 16.4307 17.1877C16.5794 17.3191 16.764 17.3836 16.9479 17.3836C17.164 17.3836 17.3791 17.2944 17.5335 17.1198C17.7198 16.9091 17.8993 16.6879 18.067 16.4624C18.3245 16.1163 18.2527 15.6268 17.9065 15.3693Z"
          fill={color}
        />
        <path
          d="M19.2131 11.7055C18.7917 11.6138 18.3753 11.8812 18.2835 12.3027C18.233 12.5346 18.172 12.767 18.102 12.9934C17.9747 13.4057 18.2056 13.8431 18.6178 13.9705C18.6947 13.9942 18.7723 14.0055 18.8487 14.0055C19.1823 14.0055 19.4912 13.7901 19.5949 13.4546C19.6779 13.1859 19.7504 12.9102 19.8103 12.6351C19.902 12.2135 19.6347 11.7973 19.2131 11.7055Z"
          fill={color}
        />
        <path
          d="M14.146 17.8727C13.9386 17.9885 13.7238 18.0967 13.5077 18.1943C13.1145 18.3719 12.9397 18.8346 13.1173 19.2278C13.2477 19.5167 13.5321 19.6877 13.8297 19.6877C13.9372 19.6877 14.0465 19.6654 14.1508 19.6183C14.4072 19.5025 14.6618 19.3742 14.9077 19.2369C15.2845 19.0266 15.4194 18.5507 15.209 18.1739C14.9987 17.7973 14.5228 17.6624 14.146 17.8727Z"
          fill={color}
        />
        <path
          d="M9.21874 4.25V10.1764L6.35393 13.0412C6.04885 13.3463 6.04885 13.8409 6.35393 14.146C6.50651 14.2986 6.70639 14.3748 6.90639 14.3748C7.10631 14.3748 7.30627 14.2986 7.45885 14.146L10.5524 11.0524C10.6989 10.9059 10.7812 10.7072 10.7812 10.5V4.25C10.7812 3.81852 10.4315 3.46875 9.99998 3.46875C9.5685 3.46875 9.21874 3.81852 9.21874 4.25Z"
          fill={color}
        />
        <path
          d="M19.2188 2.17969C18.7873 2.17969 18.4375 2.52945 18.4375 2.96094V5.13031C16.6191 2.27844 13.4434 0.5 10 0.5C7.32891 0.5 4.8177 1.5402 2.92891 3.42891C1.0402 5.3177 0 7.82891 0 10.5C0 13.1711 1.0402 15.6823 2.92891 17.5711C4.8177 19.4598 7.32891 20.5 10 20.5C10.0066 20.5 10.013 20.4992 10.0195 20.499C10.0261 20.4992 10.0325 20.5 10.0391 20.5C10.3206 20.5 10.605 20.4881 10.8843 20.4648C11.3142 20.4287 11.6336 20.051 11.5976 19.6211C11.5616 19.1911 11.1843 18.8716 10.7539 18.9077C10.5178 18.9275 10.2773 18.9375 10.0391 18.9375C10.0325 18.9375 10.0261 18.9383 10.0195 18.9385C10.013 18.9383 10.0066 18.9375 10 18.9375C5.34754 18.9375 1.5625 15.1525 1.5625 10.5C1.5625 5.84754 5.34754 2.0625 10 2.0625C12.9984 2.0625 15.7561 3.66066 17.2631 6.20312H15.115C14.6835 6.20312 14.3337 6.55289 14.3337 6.98438C14.3337 7.41586 14.6835 7.76562 15.115 7.76562H17.5C17.9758 7.76562 18.4209 7.63191 18.7999 7.40027C18.8243 7.38637 18.8477 7.37141 18.87 7.35527C19.55 6.90797 20 6.13855 20 5.26562V2.96094C20 2.52945 19.6502 2.17969 19.2188 2.17969Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1666_7795">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ClockTab;
