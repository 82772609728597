// src/redux/slices/searchSlice.js
import { createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    originPort: "ITGOA",
    destinationPort: "EGALY",
    readyOn: "2024-12-02",
    cargoType: "GC",
    isOriginChanged: false,
    isDestinationChanged: false,
    isReadyOnChanged: false,
    isCargoTypeChanged: false,
  },
  reducers: {
    setOriginPort: (state, action) => {
      state.originPort = action.payload;
    },
    setDestinationPort: (state, action) => {
      state.destinationPort = action.payload;
    },
    setReadyOn: (state, action) => {
      state.readyOn = action.payload;
    },
    setCargoType: (state, action) => {
      state.cargoType = action.payload;
    },

    setIsOriginChanged: (state, action) => {
      state.isOriginChanged = action.payload;
    },
    setIsDestinationChanged: (state, action) => {
      state.isDestinationChanged = action.payload;
    },
    setIsReadyOnChanged: (state, action) => {
      state.isReadyOnChanged = action.payload;
    },
    setIsCargoTypeChanged: (state, action) => {
      state.isCargoTypeChanged = action.payload;
    },
  },
});

export const {
  setOriginPort,
  setDestinationPort,
  setReadyOn,
  setCargoType,

  setIsOriginChanged,
  setIsDestinationChanged,
  setIsReadyOnChanged,
  setIsCargoTypeChanged,
} = searchSlice.actions;
export default searchSlice.reducer;
