import { useState } from "react";
import styles from "./index.module.scss";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import DateInput from "../dateInput";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import moment from "moment";
export default function FiltersSidebar({
  bounds,
  dispatch,
  filters,
  setFiltersApplied,
  shouldShowPrice,
}) {
  const [price, setPrice] = useState([bounds.minPrice, bounds.maxPrice]);
  const [transit, setTransit] = useState([
    bounds.minTransitTime,
    bounds.maxTransitTime,
  ]);
  const [ETA, setETA] = useState([bounds.minETA, bounds.maxETA]);
  const handlePriceChange = (event, newValue) => {
    dispatch({ type: "SET_PRICE_RANGE", payload: newValue });
    setPrice(newValue);
    setFiltersApplied(true);
  };
  const handleTransitTimeChange = (event, newValue) => {
    dispatch({ type: "SET_TRANSIT_TIME_RANGE", payload: newValue });
    //console.log(newValue);
    setTransit(newValue);
    setFiltersApplied(true);
  };
  const handleETAFrom = (from) => {
    const formattedDate = moment(from.$d).format("YYYY-MM-DD");
    dispatch({
      type: "SET_ETA_RANGE",
      payload: [formattedDate, filters.etaRange[1]],
    });
    setFiltersApplied(true);
  };
  const handleETATo = (to) => {
    const formattedDate = moment(to.$d).format("YYYY-MM-DD");
    dispatch({
      type: "SET_ETA_RANGE",
      payload: [filters.etaRange[0], formattedDate],
    });
    setFiltersApplied(true);
  };
  const handleETDFrom = (from) => {
    const formattedDate = moment(from.$d).format("YYYY-MM-DD");
    //console.log("new From", formattedDate);
    dispatch({
      type: "SET_ETD_RANGE",
      payload: [formattedDate, filters.etdRange[1]],
    });
    setFiltersApplied(true);
  };
  const handleETDTo = (to) => {
    const formattedDate = moment(to.$d).format("YYYY-MM-DD");
    //console.log("new From", formattedDate);
    dispatch({
      type: "SET_ETD_RANGE",
      payload: [filters.etdRange[0], formattedDate],
    });
    setFiltersApplied(true);
  };

  return (
    <aside className={styles.filtersSidebar}>
      <h2>Filters</h2>
      <div className={styles.filters}>
        {shouldShowPrice ? (
          <div className={styles.filter}>
            <h3>Price</h3>
            <div className={styles.filter}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <span>{price[0] ? price[0] : bounds.minPrice} $</span>
                  {/* Left label */}
                  <span>{price[1] ? price[1] : bounds.maxPrice} $</span>
                  {/* Right label */}
                </div>
                <Slider
                  value={price}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  min={bounds.minPrice}
                  max={bounds.maxPrice}
                  style={{ color: "#d32f2f" }} // Custom color for the slider
                  valueLabelFormat={(value) => `${value} $`}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className={styles.filter}>
          <h3>ETD</h3>
          <div className={styles.inputs}>
            <DateInput
              theme={true}
              label={"from"}
              placeholderShowed={false}
              handleChange={handleETDFrom}
              inputHeight={styles["date-height"]}
            />

            <DateInput
              theme={true}
              label={"to"}
              placeholderShowed={false}
              handleChange={handleETDTo}
              inputHeight={styles["date-height"]}
            />
          </div>
        </div>
        <div className={styles.filter}>
          <h3>ETA</h3>
          <div className={styles.inputs}>
            <DateInput
              theme={true}
              label={"from"}
              handleChange={handleETAFrom}
              placeholderShowed={false}
              inputHeight={styles["date-height"]}
            />
            <DateInput
              theme={true}
              label={"to"}
              placeholderShowed={false}
              handleChange={handleETATo}
              inputHeight={styles["date-height"]}
            />
          </div>
        </div>
        <div className={styles.filter}>
          <h3>Transit Time</h3>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <span>
                {transit[0] ? transit[0] : bounds.minTransitTime} days
              </span>
              {/* Left label */}
              <span>
                {transit[1] ? transit[1] + 1 : bounds.maxTransitTime + 1} days
              </span>{" "}
              {/* Right label */}
            </div>
            <Slider
              value={transit}
              onChange={handleTransitTimeChange}
              valueLabelDisplay="auto"
              min={bounds.minTransitTime}
              max={bounds.maxTransitTime + 1}
              style={{ color: "#d32f2f" }} // Custom color for the slider
              valueLabelFormat={(value) => `${value} days`}
            />
          </div>
        </div>
      </div>
    </aside>
  );
}
