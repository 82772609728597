import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import styles from "./index.module.scss";
import icon from "../../assets/grey-chevron-down.svg";
import { useLocation } from "react-router-dom";

const DropdownWithCheckboxSelect = ({
  inputIcon = icon,
  label,
  value,
  onChange,
  placeholder,
  options,
  containerStyle,
  inputStyle,
  labelStyle,
  listItemTextStyle,
  handleChange,
  background,
  handleRequiredServicesChange,
  stepOneData,
  valueKey = "id",
  className = "",
  role = "sales",
  isChecked,
}) => {
  const dropdownContainerStyle = {
    // width: "100%",
    minHeight: "2.6rem",
    background: "#fff",
    borderRadius: "6px",
    padding: "0px !important",
    outline: "none !important",
    fontSize: "0.875rem !important",
    marginTop: "2px !important",
    ...containerStyle,
    // borderColor: trigger && !selectedGrade ? "#1F1F21" : "#1F1F21",
    "& *": {
      fontFamily: "Poppins !important",
      fontSize: "0.875rem !important",
      border: "0 !important",
      // color: "#1F1F21 !important",
      // paddingTop: "0px !important",
      // paddingBottom: "0px !important",
    },
    "&-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
      backgroundColor: "transparent !important",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: value?.length > 0 ? "inherit" : "#bababa",
      paddingBlock: "12px !important",
      fontSize: "0.875rem !important",
      ...inputStyle,
    },
  };
  const labelStyles = {
    fontSize: "1rem",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#000",
    padding: "0 !important",
    ...labelStyle,
  };

  return (
    <div className={`${styles["container"]} ${className}`}>
      <InputLabel style={labelStyles} id="demo-multiple-chip-label-grade">
        {label}
      </InputLabel>
      <Select
        IconComponent={() => (
          <img src={inputIcon} alt="" className={styles["icon"]} />
        )}
        multiple
        label={label}
        labelId="demo-multiple-chip-label-grade"
        id="demo-multiple-chip-grade"
        sx={dropdownContainerStyle}
        displayEmpty
        value={value}
        onChange={onChange}
        renderValue={(selected) =>
          selected?.length > 0
            ? selected.map((item) => item.title).join(", ")
            : placeholder
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250,
            },
          },
          // getContentAnchorEl: null,
        }}
      >
        {options?.map((item, index) => (
          <MenuItem
            key={item[valueKey]}
            value={item}
            sx={{
              backgroundColor: (theme) =>
                stepOneData?.requiredServices?.some(
                  (option) => option?.[valueKey] === item?.[valueKey]
                )
                  ? "#e7efff" // Color when selected
                  : "inherit",
              "&:hover": {
                backgroundColor: "#e7efff", // Hover background color
              },
              "&.Mui-selected": {
                backgroundColor: "#fff", // Selected background color
                "&:hover": {
                  backgroundColor: "#e7efff", // Background color when both selected and hovered
                },
              },
            }}
          >
            <div className={styles["menuItemContainer"]}>
              <div className={styles["menuItemContainer-label"]}>
                <Checkbox
                  checked={
                    // index === 0 || // ensure the first option is always selected.
                    !!stepOneData?.requiredServices?.find(
                      (option) => option?.[valueKey] === item?.[valueKey]
                    )
                  }
                  onChange={(event) =>
                    handleRequiredServicesChange(event, item)
                  }
                  sx={{
                    "&.Mui-checked": {
                      color: "#157FFB",
                    },
                  }}
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "0.875rem",
                    ...listItemTextStyle,
                  }}
                  primary={item.title}
                />
              </div>
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DropdownWithCheckboxSelect;
