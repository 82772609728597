import Skeleton from "@mui/material/Skeleton";
import styles from "./index.module.scss";

const DashboardTimelineSkeleton = ({ className = "" }) => {
  return (
    <div className={styles["card"]}>
      <div className={`${styles["card-row"]} ${className}`}>
        <Skeleton variant="text" animation="wave" width={80} height={15} />
        <Skeleton variant="rounded" animation="wave" width={110} height={30} />
      </div>

      <hr className={className} />
      {/* Request Card TimeLine */}
      <div className={`${styles["card-row"]} ${className}`}>
        <Skeleton variant="text" animation="wave" width={"100%"} height={15} />
      </div>
      <hr className={className} />

      <div className={`${styles["card-row"]} ${className}`}>
        <Skeleton variant="text" animation="wave" width={80} height={15} />
        <Skeleton variant="text" animation="wave" width={80} height={15} />
      </div>
    </div>
  );
};

export default DashboardTimelineSkeleton;
