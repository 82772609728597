import styles from "./index.module.scss";
import eye from "../../assets/eye.svg";
import eyeLocked from "../../assets/hidden_icon.svg";
import Lock from "../../assets/Lock.svg";
import Email from "../../assets/Email.svg";
import Person from "../../assets/person.svg";
import Company from "../../assets/company.svg";
import Position from "../../assets/position.svg";
import { forwardRef, useState } from "react";
import CountryCodeSelector from "../countryCodeSelector";

export function FormInput({
  formRegister,
  icon,
  name,
  value = null,
  placeholder,
  label,
  maxLength,
  type,
  error,
  inputMode,
  disabled,
  labelIcon,
  labelIconName,
  phone,
  setCode,
  onFocus,
}) {
  const [showPassword, setShowPassword] = useState(() => type);

  const handleShowPassword = () => {
    setShowPassword((prev) => (prev === "password" ? "text" : "password"));
  };
  return (
    <div className={styles["container"]}>
      <div
        className={[
          styles["input-container"],
          error ? styles["input-container-error"] : "",
        ].join(" ")}
      >
        {labelIcon ? (
          <img
            className={styles["label-icon"]}
            src={(function () {
              switch (labelIconName) {
                case "Email":
                  return Email;
                case "Lock":
                  return Lock;
                case "name":
                  return Person;
                case "position":
                  return Position;
                case "company":
                  return Company;
                default:
                  return "";
              }
            })()}
            alt={labelIconName}
          />
        ) : (
          phone && (
            <div style={{ height: "100%" }}>
              {" "}
              <CountryCodeSelector setCode={setCode} />
            </div>
          )
        )}

        {label && (
          <label htmlFor={name} className={"input-label"}>
            {label}
          </label>
        )}
        <input
          id={name}
          name={name}
          value={value}
          type={showPassword}
          {...formRegister}
          onFocus={onFocus}
          placeholder={
            placeholder
              ? placeholder.charAt(0).toUpperCase() +
                placeholder.slice(1).toLowerCase()
              : ""
          }
          maxLength={maxLength}
          autoComplete="off"
          inputMode={inputMode}
          disabled={disabled}
        />
        {icon && icon}
        {type === "password" && (
          <img
            className={styles["eye"]}
            src={showPassword === "password" ? eyeLocked : eye}
            onClick={handleShowPassword}
            alt="show password"
          />
        )}
      </div>
      {error && <p className={styles["form-error"]}>{error?.message}</p>}
    </div>
  );
}

export default forwardRef(FormInput);
