import styles from "./index.module.scss";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "../../assets/close-icon.svg";

const BaseModal = ({
  isOpen,
  handleClose,
  title,
  children,
  titleCentered,
  isTitle = true,
  baseModalHeight,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xl" // Adjust width as needed
      fullWidth
      PaperProps={{
        className: `${styles.customDialogPaper} ${baseModalHeight}`,
      }}
      classes={{ paper: styles.customDialogRoot }}
    >
      {title && (
        <DialogTitle sx={{ padding: "0 !important" }}>
          <div
            className={styles.titleBar}
            style={{ borderBottom: isTitle ? "1px solid #d1d1d1" : "none" }}
          >
            {titleCentered && <div />}
            <span>{isTitle ? title : ""}</span>
            <IconButton
              onClick={handleClose}
              size="small"
              className={styles.closeButton}
            >
              <img src={CloseIcon} />
            </IconButton>
          </div>
        </DialogTitle>
      )}
      <DialogContent className={styles.modalContent}>{children}</DialogContent>
    </Dialog>
  );
};

export default BaseModal;
