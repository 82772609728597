import correct from "../../assets/tick-circle-green.svg";
import errorIcon from "../../assets/tick-circle-grey.svg";
import styles from "./index.module.scss";

export function PasswordValidations({ value, isDirty, error, className }) {
  const oneNumberRegex = /^(?=.*?[0-9]).{1,}$/;
  const oneSmallCaseRegex = /^(?=.*?[a-z]).{1,}$/;
  const oneCapitalCaseRegex = /^(?=.*?[A-Z]).{1,}$/;
  const oneSpecialCharRegex = /^(?=.*?[#?!@$%^&*-]).{1,}$/;
  const lengthRegex = /^.{8,}$/;

  const oneNumber = value && oneNumberRegex.test(value) ? 1 : 0;
  const oneSmallCase = value && oneSmallCaseRegex.test(value) ? 1 : 0;
  const oneCapitalCase = value && oneCapitalCaseRegex.test(value) ? 1 : 0;
  const oneSpecialChar = value && oneSpecialCharRegex.test(value) ? 1 : 0;
  const eightChars = value && lengthRegex.test(value) ? 1 : 0;

  //console.log("value", value);

  return (
    <>
      {isDirty && (
        <div className={`${styles["containers"]} ${className}`}>
          <div className={styles["progress-container"]}>
            <div
              className={styles["progress"]}
              style={{
                width: `${
                  (oneNumber +
                    oneSmallCase +
                    oneCapitalCase +
                    oneSpecialChar +
                    eightChars) *
                  20
                }%`,
              }}
            />
          </div>
          <div className={styles["validations-container"]}>
            <p
              className={[
                styles["validation"],
                oneSmallCase ? styles["success"] : styles["error"],
              ].join(" ")}
            >
              {oneSmallCase ? (
                <img
                  src={correct}
                  className={styles["validation-icon"]}
                  alt="correct"
                />
              ) : (
                <img
                  src={errorIcon}
                  className={styles["validation-icon"]}
                  alt="invalid"
                />
              )}
              Lowercase
            </p>
            <p
              className={[
                styles["validation"],
                oneCapitalCase ? styles["success"] : styles["error"],
              ].join(" ")}
            >
              {oneCapitalCase ? (
                <img
                  src={correct}
                  className={styles["validation-icon"]}
                  alt="correct"
                />
              ) : (
                <img
                  src={errorIcon}
                  className={styles["validation-icon"]}
                  alt="invalid"
                />
              )}
              Uppercase
            </p>
            <p
              className={[
                styles["validation"],
                oneNumber ? styles["success"] : styles["error"],
              ].join(" ")}
            >
              {oneNumber ? (
                <img
                  src={correct}
                  className={styles["validation-icon"]}
                  alt="correct"
                />
              ) : (
                <img
                  src={errorIcon}
                  className={styles["validation-icon"]}
                  alt="invalid"
                />
              )}
              Number
            </p>
            <p
              className={[
                styles["validation"],
                oneSpecialChar ? styles["success"] : styles["error"],
              ].join(" ")}
            >
              {oneSpecialChar ? (
                <img
                  src={correct}
                  className={styles["validation-icon"]}
                  alt="correct"
                />
              ) : (
                <img
                  src={errorIcon}
                  className={styles["validation-icon"]}
                  alt="invalid"
                />
              )}
              Symbol
            </p>
            <p
              className={[
                styles["validation"],
                eightChars ? styles["success"] : styles["error"],
              ].join(" ")}
            >
              {eightChars ? (
                <img
                  src={correct}
                  className={styles["validation-icon"]}
                  alt="correct"
                />
              ) : (
                <img
                  src={errorIcon}
                  className={styles["validation-icon"]}
                  alt="invalid"
                />
              )}
              8 Characters
            </p>
          </div>
        </div>
      )}
      {!isDirty && value === "" && error?.message && (
        <p
          className={[
            styles["validation"],
            styles["error"],
            styles["error-message"],
          ].join(" ")}
        >
          {error?.message}
        </p>
      )}
    </>
  );
}

export default PasswordValidations;
