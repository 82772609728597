import React from "react";
import { useEffect, useState } from "react";
import FormInput from "../formInput";
import PasswordValidations from "../passwordValidations";
import MainButton from "../mainButton";
import { resetPassword } from "../../services/authService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useHookForm } from "../../hooks/hook-form";
import styles from "./index.module.scss";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";

export default function ForgetPasswordForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { email, otp } = location.state || {};
  const navigate = useNavigate();
  const {
    handleSubmit,
    errors,
    isDirty,
    isValid,
    passwordRegister,
    confirmPasswordRegister,
    dirtyFields,
    getValues,
    watch,
  } = useHookForm({
    isPassword: true,
    isConfirmPassword: true,
  });

  const handleFormSubmit = async (data) => {
    setIsLoading(true);
    //console.log("data", data);
    const payload = {
      new_password: data.password,
      confirm_password: data["confirm-password"],
    };
    const response = await resetPassword(
      payload,
      `token=${otp}&email=${email}&type=reset_password`
    );
    if (response.status === 200) {
      navigate("/password-changed");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
    setIsLoading(false);
  };

  return (
    <form
      noValidate
      className={styles["new-password-container"]}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div>
        <p className={styles["new-password-container-subtitle"]}>
          Change Password
        </p>

        <div>
          <p>Password</p>
          <FormInput
            name="password"
            type="password"
            error={errors["password"]}
            placeholder={"Enter new password"}
            formRegister={passwordRegister}
            labelIconName="Lock"
            labelIcon={true}
          />
          <PasswordValidations
            isDirty={dirtyFields["password"]}
            error={errors["password"]}
            value={watch("password")}
          />
        </div>
        <div>
          <p>Confirm Password</p>
          <FormInput
            name="confirm-password"
            type="password"
            error={errors["confirm-password"]}
            placeholder={"Re-enter new password"}
            formRegister={confirmPasswordRegister}
            labelIconName="Lock"
            labelIcon={true}
          />
        </div>
      </div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <MainButton disabled={!isValid || !isDirty} type={"submit"}>
          Save
        </MainButton>
      )}
    </form>
  );
}
