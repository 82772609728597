import React from "react";
import styles from "./index.module.scss";

const DashboardCard = ({ number, title, icon }) => {
  return (
    <div className={styles["dashboard-card"]}>
      <div className={styles["dashboard-card-content"]}>
        <h3>{number}</h3>
        <p>{title}</p>
      </div>
      <div className={styles["dashboard-card-icon"]}>
        <img src={icon} alt="card-icon" />
      </div>
    </div>
  );
};

export default DashboardCard;
