import React from "react";
import AuthLayout from "../../components/AuthLayout";
import SignupForm from "../../components/signupForm";
import styles from "./index.module.scss";

function SignUp() {
  return (
    <AuthLayout customBg={styles["custom-bg"]}>
      <SignupForm />
    </AuthLayout>
  );
}

export default SignUp;
