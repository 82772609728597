import React, { useRef, useState } from "react";
import styles from "./index.module.scss";
import { Sheet } from "react-modal-sheet";
import Button from "../../assets/Button.svg";
const ShipmentModal = ({
  request,
  children,
  dropdownRef,
  toggleDropdown,
  openDropdownIndex,
  setShowSendOfferModal,
  setOpen,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const ref = useRef();
  const snapTo = (i) => ref.current?.snapTo(2);
  const type = localStorage.getItem("type");

  return (
    <Sheet
      ref={ref}
      isOpen={true}
      onClose={() => setIsOpen(true)}
      snapPoints={[0.9, 0.5, 0.15]}
      initialSnap={1}
      onSnap={(snapIndex) => null}
    >
      <Sheet.Container>
        <Sheet.Header
          style={{
            height: "65px",
            borderBottom: "1px solid rgb(226, 227, 227)",
          }}
        />
        {type === "sales" && (
          <Sheet.Header
            children={
              <div className={styles.filterContainer} ref={dropdownRef}>
                <button
                  className={styles.filterButton}
                  onClick={() => toggleDropdown()}
                >
                  <img src={Button} width={40} height={40} />
                </button>
                {openDropdownIndex && (
                  <div className={styles["filterContainer-dropdownMenu"]}>
                    <div
                      className={styles["filterContainer-dropdownItem"]}
                      onClick={() => {
                        setShowSendOfferModal(true);
                        snapTo();
                      }}
                    >
                      Edit Shipment
                    </div>

                    <div
                      onClick={() => {
                        setOpen(true);
                        snapTo();
                      }}
                      className={styles["filterContainer-dropdownItem"]}
                    >
                      Delete Shipment
                    </div>
                  </div>
                )}
              </div>
            }
            className={styles["modal-header"]}
          />
        )}

        <Sheet.Content style={{ paddingTop: "1rem" }}>{children}</Sheet.Content>
      </Sheet.Container>
      {/* <Sheet.Backdrop /> */}
    </Sheet>
  );
};

export default ShipmentModal;
