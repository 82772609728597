import styles from "./index.module.scss";
import { Autocomplete, InputAdornment, Popper, TextField } from "@mui/material";
import icon from "../../assets/grey-chevron-down.svg";
import locationPin from "../../assets/location-pin.svg";

const AutoComplete = ({
  inputIcon = icon,
  options,
  label,
  containerStyle,
  dropdownStyle,
  inputClassName,
  labelClassName,
  value,
  handleChange,
  placeholder,
  inputValue = "",
  optionLabel,
  handleInputChange = () => {},
  handleScroll = () => {},
  disabled,
  className = "",
  quoteForm = true,
  // formRegister,
  // error,
}) => {
  const listBoxStyle = {
    maxHeight: "200px",
    overflowY: "auto",
    maxWidth: "100%",
    minWidth: "100%",
    ...dropdownStyle,
  };
  const autocompleteStyle = {
    maxWidth: "100% !important",
    // height: "5.2rem",
    "& .MuiOutlinedInput-root": {
      padding: "0 !important",
      fontFamily: "Poppins !important",
    },
    ...containerStyle,
  };
  return (
    disabled,
    (
      <div className={`${styles["container"]} ${inputClassName}`}>
        <Autocomplete
          // {...formRegister}
          // open={Boolean(inputValue)} // Menu only opens if inputValue is not empty
          disabled={disabled}
          value={value}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          id="combo-box-demo"
          inputValue={inputValue}
          onInputChange={handleInputChange}
          options={options}
          popupIcon={
            quoteForm ? (
              <div
                style={{
                  width: "1.6rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={inputIcon} alt="" />
              </div>
            ) : null
          }
          getOptionLabel={(option) => `${option.value}, ${option.country}`}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              <img
                src={option.country_flag}
                alt=""
                style={{ width: 20, marginRight: 8 }}
              />
              {`${option.value}, ${option.country}`}
            </li>
          )}
          PopperComponent={(props) => (
            <Popper
              {...props}
              modifiers={[
                {
                  name: "flip",
                  enabled: false,
                },
                {
                  name: "flip",
                  options: {
                    mainAxis: false,
                    altAxis: false,
                  },
                },
              ]}
            />
          )}
          ListboxProps={{
            onScroll: handleScroll,
            role: "listbox",
            className: styles.listboxItem,
            style: listBoxStyle,
          }}
          sx={autocompleteStyle}
          renderInput={(params) =>
            quoteForm ? (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  className: `${styles["autocompleteLabel"]} ${labelClassName}`,
                  shrink: true,
                }}
                inputProps={{
                  ...params.inputProps,
                  className: `${styles["autocompleteInput"]} ${inputClassName} ${className}`,
                }}
                placeholder={placeholder}
                sx={{
                  fieldset: {
                    display: "none",
                  },
                }}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ position: "absolute", left: "20px" }}
                    >
                      {value && value.country_flag ? (
                        <img
                          src={value.country_flag}
                          alt=""
                          style={{ width: 32, marginBottom: "4px" }}
                        />
                      ) : (
                        <img
                          src={locationPin}
                          className={styles["label-icon-img"]}
                        />
                      )}
                      {/* Adjust icon style as needed */}
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  className: `${styles["autocompleteLabel"]} ${labelClassName}`,
                  shrink: true,
                }}
                inputProps={{
                  ...params.inputProps,
                  className: `${styles["autocompleteInput"]} ${inputClassName} ${className}`,
                }}
                placeholder={placeholder}
                sx={{
                  fieldset: {
                    display: "none",
                  },
                }}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ position: "absolute", left: "15px" }}
                    >
                      {value && value.country_flag ? (
                        <img
                          src={value.country_flag}
                          alt=""
                          style={{ width: 32, marginBottom: "4px" }}
                        />
                      ) : (
                        <img
                          src={locationPin}
                          className={styles["label-icon-img"]}
                        />
                      )}
                      {/* Adjust icon style as needed */}
                    </InputAdornment>
                  ),
                }}
              />
            )
          }
        />
      </div>
    )
  );
};

export default AutoComplete;
