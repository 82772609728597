import { ThemeProvider, createTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Step from "./components/requestQuoteForm/steps/Step";
import Layout from "./layout/layout";
import ProtectedRouteLayout from "./layout/protectedRouteLayout";
import CheckYourEmail from "./pages/CheckYourEmail";
import ForgetPassword from "./pages/forgetPassword";
import Login from "./pages/login";
import RequestQuoteForm from "./pages/requestQuote";
import SearchVessels from "./pages/searchVessels";
import ProtectedRoute from "./utils/ProtectedRoute";
import NewPassword from "./pages/newPassword";
import Requests from "./pages/Requests";
import Profile from "./pages/profile";
import PasswordChanged from "./pages/passwordChanged";
import SignUp from "./pages/signUp";
import EmailVerification from "./pages/EmailVerification";
import Loading from "./pages/Loading";
import SalesRequests from "./pages/SalesRequests";
import SalesRouteLayout from "./components/SalesRouteLayout";
import SalesShipmentTracking from "./pages/SalesShipmentTracking";
import ShipmentInfo from "./pages/ShipmentInfo";
import Dashboard from "./pages/Dashboard";
import SearchHistory from "./pages/SearchHistory";
import HeaderLayout from "./layout/HeaderLayout";

function App() {
  const loading = useSelector((state) => state.loading);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const type = localStorage.getItem("type");
  const { token } = user;
  const getLayout = (path, component) => {
    const authRoutes = [
      "signup",
      "login",
      "forget-password",
      "new-password",
      "check-your-email",
      "password-changed",
      "email-verification",
      "loading",
    ];
    const protectedRoutes = [
      "checkout",
      "payment-status",
      "live-wheel",
      "request-quote",
      "search-history",
    ];
    const salesRoutes = ["sales-requests"];

    if (authRoutes.includes(path)) {
      return component;
    } else if (protectedRoutes.includes(path)) {
      return <ProtectedRouteLayout>{component}</ProtectedRouteLayout>;
    } else if (salesRoutes.includes(path)) {
      return <SalesRouteLayout>{component}</SalesRouteLayout>;
    } else if (path === "shipment-tracking" && type === "sales") {
      return <SalesRouteLayout>{component}</SalesRouteLayout>;
    } else if (path === "shipment-tracking" && type === "client") {
      return <Layout>{component}</Layout>;
    } else if (path === "shipment-info" && type === "sales") {
      return <HeaderLayout>{component}</HeaderLayout>;
    } else if (path === "shipment-info" && type === "client") {
      return <HeaderLayout>{component}</HeaderLayout>;
    } else {
      return <Layout>{component}</Layout>;
    }
  };

  // const handleTokenAuth = async () => {
  // 	dispatch(setLoading(true));
  // 	const response = await checkToken({ token: user.token });
  // 	if (response.status === 200) {
  // 		if (response.data.exists) {
  // 			axios.defaults.headers.Authorization = `Token ${user.token}`;
  // 		} else {
  // 			delete axios.defaults.headers.Authorization;
  // 			dispatch(setUser({ token: '', user: null, profile: null }));
  // 		}
  // 	}
  // 	setTimeout(() => {
  // 		dispatch(setLoading(false));
  // 	}, 1000);
  // };

  // useEffect(() => {
  //   if (user?.token) {
  //     // handleTokenAuth();
  //   } else {
  //     axios.interceptors.request.use(function (config) {
  //       delete config.headers.Authorization;
  //       return config;
  //     });
  //   }
  // }, [token]);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#BD3C31",
      },
    },
  });

  return (
    <>
      {loading && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
      {!loading && (
        <>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ProtectedRoute>
                <Routes>
                  <Route
                    path="/email-verification"
                    element={getLayout(
                      "email-verification",
                      <EmailVerification />
                    )}
                  />
                  <Route
                    path="/loading"
                    element={getLayout("loading", <Loading />)}
                  />
                  <Route
                    path="/login"
                    element={getLayout("login", <Login />)}
                  />
                  <Route
                    path="/signup"
                    element={getLayout("signup", <SignUp />)}
                  />
                  <Route
                    path="/forget-password"
                    element={getLayout("forget-password", <ForgetPassword />)}
                  />
                  <Route
                    path="/new-password"
                    element={getLayout("new-password", <NewPassword />)}
                  />
                  <Route
                    path="/password-changed"
                    element={getLayout("password-changed", <PasswordChanged />)}
                  />
                  <Route
                    path="/"
                    element={getLayout("search-vessels", <SearchVessels />)}
                  />
                  <Route
                    path="/profile"
                    element={getLayout("profile", <Profile />)}
                  />
                  <Route
                    path="/request-quote"
                    element={getLayout("request-quote", <RequestQuoteForm />)}
                  >
                    <Route path=":step" element={<Step />} />
                  </Route>
                  <Route
                    path="/check-your-email"
                    element={getLayout("check-your-email", <CheckYourEmail />)}
                  />
                  <Route
                    path="/requests"
                    element={getLayout("requests", <Requests />)}
                  />
                  <Route
                    path="/dashboard"
                    element={getLayout("dashboard", <Dashboard />)}
                  />
                  <Route
                    path="/search-history"
                    element={getLayout("search-history", <SearchHistory />)}
                  />
                  <Route
                    path="/sales-requests"
                    element={getLayout("sales-requests", <SalesRequests />)}
                  />
                  <Route
                    path="/shipment-tracking"
                    element={getLayout(
                      "shipment-tracking",
                      <SalesShipmentTracking />
                    )}
                  />
                  <Route
                    path="/shipment-info"
                    element={getLayout("shipment-info", <ShipmentInfo />)}
                  />
                </Routes>
              </ProtectedRoute>
            </BrowserRouter>
          </ThemeProvider>
        </>
      )}
    </>
  );
}

export default App;
