import axios from "axios";

export const getFreights = (
  token,
  originPort = "ITGOA",
  destinationPort = "EGALY",
  readyOn = "2024-12-02",
  cargoType = "GC"
) => {
  return axios
    .get("/content/freights/integration", {
      headers: {
        Authorization: `Token ${token}`,
      },
      params: {
        origin_port: originPort,
        destination_port: destinationPort,
        ready_on: readyOn,
        cargo_type: cargoType,
      },
    })
    .then((response) => response)
    .catch((err) => err?.response);
};

export const getCargos = () => {
  return axios
    .get("/content/containers/getAll")
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getPorts = (port = "") => {
  return axios
    .get("/content/ports", {
      params: {
        // country: port,
        value: port,
      },
    })
    .then((response) => response)
    .catch((err) => err?.response);
};
export const getGlobalConfigs = () => {
  return axios
    .get("/global/config")
    .then((response) => response.data)
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
