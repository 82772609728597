// CustomModal.jsx

import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "../../assets/Vector.png";
import styles from "./index.module.scss";
import DateInput from "../dateInput";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import moment from "moment/moment";
import ApplyButton from "../ApplyButton";
function VesselSearchFIlterModal({
  handleOpen,
  isOpen,
  bounds,
  dispatch,
  filters,
  setFiltersApplied,
  shouldShowPrice,
}) {
  const [open, setOpen] = useState(true);
  const [price, setPrice] = useState([bounds.minPrice, bounds.maxPrice]);
  const [transit, setTransit] = useState([
    bounds.minTransitTime,
    bounds.maxTransitTime,
  ]);
  const handlePriceChange = (event, newValue) => {
    dispatch({ type: "SET_PRICE_RANGE", payload: newValue });
    setPrice(newValue);
    setFiltersApplied(true);
  };
  const handleTransitTimeChange = (event, newValue) => {
    dispatch({ type: "SET_TRANSIT_TIME_RANGE", payload: newValue });
    setTransit(newValue);
    setFiltersApplied(true);
  };
  const handleETAFrom = (from) => {
    const formattedDate = moment(from.$d).format("YYYY-MM-DD");
    dispatch({
      type: "SET_ETA_RANGE",
      payload: [formattedDate, filters.etaRange[1]],
    });
    setFiltersApplied(true);
  };
  const handleETATo = (to) => {
    const formattedDate = moment(to.$d).format("YYYY-MM-DD");
    dispatch({
      type: "SET_ETA_RANGE",
      payload: [filters.etaRange[0], formattedDate],
    });
    setFiltersApplied(true);
  };
  const handleETDFrom = (from) => {
    const formattedDate = moment(from.$d).format("YYYY-MM-DD");
    dispatch({
      type: "SET_ETD_RANGE",
      payload: [formattedDate, filters.etdRange[1]],
    });
    setFiltersApplied(true);
  };
  const handleETDTo = (to) => {
    const formattedDate = moment(to.$d).format("YYYY-MM-DD");
    dispatch({
      type: "SET_ETD_RANGE",
      payload: [filters.etdRange[0], formattedDate],
    });
    setFiltersApplied(true);
  };

  const handleClose = () => {
    handleOpen(false);
  };
  // const [value, setValue] = useState([0, 3000]);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xl" // Adjust width as needed
      fullWidth
      PaperProps={{
        className: styles.customDialogPaper,
      }}
      classes={{ paper: styles.customDialogRoot }}
    >
      <DialogTitle>
        <div className={styles.titleBar}>
          <span>Filter by</span>
          <IconButton
            onClick={handleClose}
            size="small"
            className={styles.closeButton}
          >
            <img src={CloseIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {" "}
        <aside className={styles.filtersSidebar}>
          <div className={styles.filters}>
            {shouldShowPrice ? (
              <div className={styles.filter}>
                <h3>Price</h3>
                <div className={styles.filter}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                      }}
                    >
                      <span>{price[0] ? price[0] : bounds.minPrice} $</span>
                      {/* Left label */}
                      <span>{price[1] ? price[1] : bounds.maxPrice} $</span>
                      {/* Right label */}
                    </div>
                    <Slider
                      value={price}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      min={bounds.minPrice}
                      max={bounds.maxPrice}
                      style={{ color: "#d32f2f" }} // Custom color for the slider
                      valueLabelFormat={(value) => `${value} $`}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles.filter}>
              <h3>ETD</h3>
              <div className={styles.inputs}>
                <DateInput
                  theme={true}
                  label={"from"}
                  placeholderShowed={false}
                  handleChange={handleETDFrom}
                />

                <DateInput
                  theme={true}
                  label={"to"}
                  placeholderShowed={false}
                  handleChange={handleETDTo}
                />
              </div>
            </div>
            <div className={styles.filter}>
              <h3>ETA</h3>
              <div className={styles.inputs}>
                <DateInput
                  theme={true}
                  label={"from"}
                  placeholderShowed={false}
                  handleChange={handleETAFrom}
                />
                <DateInput
                  theme={true}
                  label={"to"}
                  placeholderShowed={false}
                  handleChange={handleETATo}
                />
              </div>
            </div>
            <div className={styles.filter}>
              <h3>Transit</h3>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <span>
                    {transit[0] ? transit[0] : bounds.minTransitTime} days
                  </span>
                  {/* Left label */}
                  <span>
                    {transit[1] ? transit[1] + 1 : bounds.maxTransitTime + 1}{" "}
                    days
                  </span>
                  {/* Right label */}
                </div>
                <Slider
                  value={transit}
                  onChange={handleTransitTimeChange}
                  valueLabelDisplay="auto"
                  min={bounds.minTransitTime}
                  max={bounds.maxTransitTime + 1}
                  style={{ color: "#d32f2f" }} // Custom color for the slider
                  valueLabelFormat={(value) => `${value} days`}
                />
                <ApplyButton
                  onclick={handleClose}
                  color={"red"}
                  isDisabled={false}
                >
                  Apply
                </ApplyButton>
              </div>
            </div>
          </div>
        </aside>
      </DialogContent>
    </Dialog>
  );
}

export default VesselSearchFIlterModal;
