import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
  useMapsLibrary,
  useMap,
} from "@vis.gl/react-google-maps";
import { useState, useEffect } from "react";
import shipmentIcon from "../../assets/Boat.svg";
import data from "./data";

function ShipmentTrackingMap() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const mapId = process.env.REACT_APP_GOOGLE_PUBLIC_MAP_ID;

  const [markers, setMarkers] = useState([]); // State to hold marker data
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [shipmentInfo, setShipmentInfo] = useState(false);

  const shipmentPosition = {
    lat: data.route_data.pin[0],
    lng: data.route_data.pin[1],
  };

  const routes = data.route_data.route; // The Shipment Routes Data

  // Markers Locations
  useEffect(() => {
    const locations = data.locations;
    setMarkers(locations);
  }, []);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };
  const handleCloseInfoWindow = () => {
    setSelectedMarker(null); // Close the InfoWindow
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        minHeight: "300px",
        overflow: "hidden", // Ensures the rounded corners are applied
        borderRadius: "20px", // Adjust the radius as needed
      }}
    >
      <APIProvider apiKey={apiKey} libraries={["marker"]}>
        <Map
          mapId={mapId}
          defaultZoom={2}
          defaultCenter={shipmentPosition}
          gestureHandling={"greedy"}
          disableDefaultUI={false}
          options={{
            restriction: {
              latLngBounds: {
                north: 85, // Northern hemisphere latitude limit
                south: -85, // Southern hemisphere latitude limit
                west: -180, // Western longitude limit
                east: 180, // Eastern longitude limit
              },
              strictBounds: true, // Prevent moving outside the bounds
            },
            minZoom: 1, // Limit zoom to a reasonable level so the whole map is visible
          }}
        >
          {/* Origin And Destination Marker */}
          {markers.map((marker) => (
            <AdvancedMarker
              key={marker.id}
              position={{ lat: marker.lat, lng: marker.lng }}
              onClick={() => handleMarkerClick(marker)}
            >
              <Pin />
            </AdvancedMarker>
          ))}

          {/* Shipment Marker */}
          <AdvancedMarker
            position={shipmentPosition}
            onClick={() => setShipmentInfo(true)}
          >
            <img src={shipmentIcon} width={50} height={50} />
          </AdvancedMarker>

          {/* Render InfoWindow if a marker is selected */}
          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onCloseClick={handleCloseInfoWindow}
            >
              <div>
                <h2>
                  {selectedMarker.name} in {selectedMarker.country}
                </h2>
              </div>
            </InfoWindow>
          )}

          {/* Shipment Information */}
          {shipmentInfo && (
            <InfoWindow
              position={shipmentPosition}
              onCloseClick={() => setShipmentInfo(false)}
            >
              <div>
                <h2>The Shipment</h2>
              </div>
            </InfoWindow>
          )}

          {/* Directions */}
          <Routes routes={routes} />
        </Map>
      </APIProvider>
    </div>
  );
}

export default ShipmentTrackingMap;

// The Routes Component
function Routes({ routes }) {
  // Drawing The Line
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");

  const [directionsService, setDirectionsService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();

  // Initialize The DirectionsService and DirectionsRenderer
  useEffect(() => {
    if (!map || !routesLibrary) return;

    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
  }, [map, routesLibrary]);

  // Draw polyline for multiple sea routes
  useEffect(() => {
    if (!map) return;

    // Function to create polylines
    routes.forEach((route) => {
      const polylinePath = route.path.map((coords) => ({
        lat: coords[0],
        lng: coords[1],
      }));

      const polyline = new window.google.maps.Polyline({
        path: polylinePath,
        geodesic: true,
        strokeColor: route.type === "SEA" ? "#0000FF" : "#FF0000", // Customize the color if needed
        strokeOpacity: 1,
        strokeWeight: 3,
      });

      // Set the polyline on the map
      polyline.setMap(map);
    });
  }, [map]);

  return null;
}
