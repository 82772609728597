import React from "react";
import styles from "./index.module.scss";
import { red } from "@mui/material/colors";

const ApplyButton = ({ onclick, isDisabled, color = "", title = "Apply" }) => {
  return (
    <button
      onClick={onclick}
      className={color === "red" ? styles.redApplyButton : styles.applyButton}
      disabled={isDisabled}
    >
      {title}
    </button>
  );
};

export default ApplyButton;
