import React from "react";
import styles from "./index.module.scss";
import MainButton from "../../components/mainButton";
import PasswordChangedIcon from "../../assets/password-changed.svg";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../components/AuthLayout";
function PasswordChanged() {
  const navigate = useNavigate();

  // //console.log("navigate with this email", data?.email);
  return (
    <AuthLayout>
      <div className={styles["login-btn-container"]}>
        <img src={PasswordChangedIcon} alt="Inbox" />
        <p className={styles["login-title"]}>Password Changed Successfully</p>
        <div style={{ gap: "0.5rem" }}>
          <p className={styles["login-p"]}>
            Your password has been changed successfully.
          </p>
          <p className={styles["login-p"]}>
            Please sign in using your new password.
          </p>
        </div>
      </div>
      <MainButton
        handleSubmit={() => {
          navigate("/login");
        }}
        className={styles["login-btn"]}
      >
        Back to Sign In
      </MainButton>
    </AuthLayout>
  );
}

export default PasswordChanged;
