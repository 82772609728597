import React, { useEffect } from "react";
import AuthLayout from "../../components/AuthLayout";
import { Bounce } from "react-activity";
import "react-activity/dist/library.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkOTP } from "../../services/authService";
function Loading() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const type = searchParams.get("type");

  const handleFormSubmit = async () => {
    let data = new FormData();
    data.append("otp", searchParams.get("otp"));
    data.append("email", searchParams.get("email"));
    data.append("type", type);

    const response = await checkOTP(data);
    if (response.status === 200) {
      if (type === "register_user") {
        navigate("/signup", {
          state: {
            email: searchParams.get("email"),
            otp: searchParams.get("otp"),
          },
        });
      } else if (type === "reset_password") {
        navigate("/new-password", {
          state: {
            email: searchParams.get("email"),
            otp: searchParams.get("otp"),
          },
        });
      }
    } else {
      if (type === "register_user") {
        if (response.data?.signup_availability === false) {
          navigate("/login", {
            state: { isError: true, errorMessage: response.data.error },
          });
        } else {
          navigate("/email-verification", {
            state: { isError: true, errorMessage: response.data.error },
          });
        }
      } else if (type === "reset_password") {
        navigate("/forget-password", {
          state: { isError: true, errorMessage: response.data.error },
        });
      }
    }
  };

  useEffect(() => {
    handleFormSubmit();
  }, []);
  return (
    <AuthLayout>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "35vh",
          zIndex: 6,
          justifyContent: "center",
        }}
      >
        <Bounce size={35} color="#BD3C31" />
      </div>
    </AuthLayout>
  );
}

export default Loading;
