import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  const location = useLocation();

  // Retrieve token, type, and current path
  const token = localStorage.getItem("token");
  const type = localStorage.getItem("type");
  const currentPath = location.pathname.split("/")[1];

  // Define route arrays for each user type
  const userRoutes = [
    "",
    "search-vessels",
    "request-quote",
    "profile",
    "requests",
  ];
  const authRoutes = [
    "login",
    "signup",
    "forget-password",
    "new-password",
    "password-changed",
    "check-your-email",
    "loading",
  ];
  const salesRoutes = ["sales-requests", "search-history"];

  // Define initial path for each user type
  const initialPath = type === "sales" ? "/sales-requests" : "";

  // Check if user is not authenticated
  if (!user.token && !token) {
    if (userRoutes.includes(currentPath) || salesRoutes.includes(currentPath)) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  // Check if user is authenticated and trying to access an auth-only route (like login)
  if (user.token && token && authRoutes.includes(currentPath)) {
    return <Navigate to={initialPath} state={{ from: location }} replace />;
  }

  // Restrict access based on user type
  if (type === "sales" && userRoutes.includes(currentPath)) {
    return <Navigate to="/sales-requests" state={{ from: location }} replace />;
  } else if (type === "client" && salesRoutes.includes(currentPath)) {
    return <Navigate to="" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
