import styles from "./index.module.scss";
import maerskIcon from "../../assets/maersk-icon.png";
import arrowRight from "../../assets/arrow-right.svg";

const DashboardTable = ({ activeRequests }) => {
  const timaAndDateFormat = (date) => {
    const dateObj = new Date(date);
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const am_pm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const day = dateObj.getDate().toString().padStart(2, "0");
    const year = dateObj.getFullYear();
    const month = dateObj.toLocaleString("en-US", { month: "short" });
    const formattedDate = `${hours}:${minutes}${am_pm} - ${day} ${month} ${year}`;

    return formattedDate;
  };

  return (
    <div className={styles["table-container"]}>
      <table className={styles["shipment-table"]}>
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Time and Date</th>
            <th>Commodity</th>
            <th>Route</th>
            <th>Shipping Line</th>
          </tr>
        </thead>
        <tbody>
          {activeRequests.slice(0, 5)?.map((request) => (
            <tr key={request?.id}>
              <td>{request?.id}</td>
              <td>{timaAndDateFormat(request?.created_at)}</td>
              <td>{request?.commodity}</td>
              <td className={styles["route-cell-container"]}>
                <div className={styles["route-cell"]}>
                  <div className={styles["route-cell-item"]}>
                    <span className={styles["port-text"]}>
                      {request?.port_of_loading}
                    </span>
                    <br />
                    <span className={styles["country-text"]}>
                      {request?.pol_country_name}
                    </span>
                  </div>
                  <img src={arrowRight} alt="arrow-right" />
                  <div className={styles["route-cell-item"]}>
                    <span className={styles["port-text"]}>
                      {request?.port_of_discharge}
                    </span>
                    <br />
                    <span className={styles["country-text"]}>
                      {request?.pod_country_name}
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <img src={maerskIcon} alt={`shipping-line-icon`} />
                {request?.shipping_line?.name ?? "--"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardTable;
