import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import SearchBar from "../SearchBar";
import SortBy from "../SortBy";
import FilterIcon from "../../assets/filter-icon.svg";
import DownIcon from "../../assets/icon-down.svg";
import DateInput from "../dateInput";
import Dropdown from "../dropdown";
import DropdownWithCheckboxSelect from "../DropdownWithCheckboxSelect.jsx";
import ApplyButton from "../ApplyButton/index.jsx";
import { use } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import salesRequests, {
  salesRequestsSlice,
} from "../../store/slices/salesRequestsSlice.js";
import MainButton from "../mainButton/index.jsx";
import shipment from "../../store/slices/shipmentSlice.js";
function Actions({
  fetchRequests,
  setOrder,
  setSearchTerm,
  searchTerm,
  placeholder = "Search by request ID",
  type = "requests",
  setShowSendOfferModal,
  filters,
}) {
  const user = localStorage.getItem("type");
  const [menuOpen, setMenuOpen] = useState(false);
  const [starred, setStarred] = useState("Starred Requests");
  const [sort, setSort] = useState("New to Old");
  const [requests, setRequests] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [tire, setTire] = useState([]);
  const [filteredBy, setFilteredBy] = useState("");
  const [stepOne, setStepOne] = useState({
    requiredServices: [],
  });
  const [shippingLine, setShippingLine] = useState({
    requiredServices: [],
  });
  const [numberTypes, setNumberTypes] = useState({
    requiredServices: [],
  });
  const [status, setStatus] = useState({
    requiredServices: [],
  });
  const [criteria, setCriteria] = useState({
    requiredServices: [],
  });
  const handleShippingLineRequiredServicesChange = (event, item) => {
    const { checked } = event.target;
    const valueKey = "id";

    setShippingLine((prevData) => {
      const requiredServices = prevData.requiredServices || [];

      if (checked) {
        return {
          ...prevData,
          requiredServices: [...requiredServices, item],
        };
      } else {
        return {
          ...prevData,
          requiredServices: requiredServices.filter(
            (service) => service[valueKey] !== item[valueKey]
          ),
        };
      }
    });
  };
  const handleNumberTypesRequiredServicesChange = (event, item) => {
    const { checked } = event.target;
    const valueKey = "id";

    setNumberTypes((prevData) => {
      const requiredServices = prevData.requiredServices || [];

      if (checked) {
        return {
          ...prevData,
          requiredServices: [...requiredServices, item],
        };
      } else {
        return {
          ...prevData,
          requiredServices: requiredServices.filter(
            (service) => service[valueKey] !== item[valueKey]
          ),
        };
      }
    });
  };
  const handleStatusRequiredServicesChange = (event, item) => {
    const { checked } = event.target;
    const valueKey = "id";

    setStatus((prevData) => {
      const requiredServices = prevData.requiredServices || [];

      if (checked) {
        return {
          ...prevData,
          requiredServices: [...requiredServices, item],
        };
      } else {
        return {
          ...prevData,
          requiredServices: requiredServices.filter(
            (service) => service[valueKey] !== item[valueKey]
          ),
        };
      }
    });
  };
  const handleCriteriaRequiredServicesChange = (event, item) => {
    const { checked } = event.target;
    const valueKey = "id";

    setCriteria((prevData) => {
      const requiredServices = prevData.requiredServices || [];

      if (checked) {
        return {
          ...prevData,
          requiredServices: [...requiredServices, item],
        };
      } else {
        return {
          ...prevData,
          requiredServices: requiredServices.filter(
            (service) => service[valueKey] !== item[valueKey]
          ),
        };
      }
    });
  };
  const dispatch = useDispatch();
  const select = useSelector;
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const containerStyle = {
    border: "1px solid #d9dcdc",
    borderRaduis: "12px",
    height: "3.2rem",
    minHeight: "51px ",
  };
  const handleStarredRequest = (event) => {
    const {
      target: { value },
    } = event;

    setStarred(value);
  };
  const handleClientTire = (event, item) => {
    const selectedValues = event.target.value;
    setStepOne((prevState) => ({
      ...prevState,
      requiredServices: [...selectedValues],
    }));
  };

  const ClientTires = [
    { id: 0, title: "Premium Clients", key: "premium_clients" },
    { id: 1, title: "Basic Clients", key: "basic_clients" },
    { id: 2, title: "Starred ", key: "starred" },
    { id: 3, title: "Not Starred", key: "not_starred" },
  ];
  const isChecked = (item) => {
    return tire.find((t) => t.id === item.id) !== undefined;
  };
  const handleRequiredServicesChange = (event, item) => {
    const { checked } = event.target;
    const valueKey = "id";

    setStepOne((prevData) => {
      const requiredServices = prevData.requiredServices || [];

      if (checked) {
        return {
          ...prevData,
          requiredServices: [...requiredServices, item],
        };
      } else {
        return {
          ...prevData,
          requiredServices: requiredServices.filter(
            (service) => service[valueKey] !== item[valueKey]
          ),
        };
      }
    });
  };
  const handleDateFormat = (dateString) => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
      throw new Error("Invalid date string");
    }

    // Format the date to YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`; // Return formatted date
  };
  const handleApply = () => {
    fetchRequests();
    // setFilteredBy(
    //   `from ${from ? from : "-♾️"} to ${to ? to : "♾️"}  ${
    //     stepOne?.requiredServices.length !== 0
    //       ? " & Criteria  : " +
    //         stepOne.requiredServices.map((service) => service.name).join(", ")
    //       : ""
    //   }`
    // );
  };
  const handleClear = () => {
    setFrom(null);
    setTo(null);
    setStepOne({
      requiredServices: [],
    });
    setFilteredBy(null);

    fetchRequests();
  };
  const handleShipmentClear = () => {
    setCriteria({
      requiredServices: [],
    });
    setStatus({
      requiredServices: [],
    });
    setNumberTypes({
      requiredServices: [],
    });
    setShippingLine({
      requiredServices: [],
    });

    fetchRequests();
  };
  const words = filteredBy?.split(" ");

  const getStyleForWord = (index) => {
    switch (index) {
      case 0:
      case 2:
        return { fontWeight: "600" };
      case 1:
      case 3:
        return { color: "#157ffb" };
      case 4:
        return { color: "red" };
      case 5:
        return { fontWeight: "600" };
      case 6:
        return { color: "black" };
      case 7:
        return { fontWeight: "600" };
      default:
        return {};
    }
  };
  const handleOptionClick = (criteria, order) => {
    setOrder(order);
    setSort(criteria);
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    dispatch(salesRequests.setFromDate(from));
    dispatch(salesRequests.setToDate(to));
  }, [from, to]);
  useEffect(() => {
    dispatch(
      salesRequests.setCriterias(
        stepOne?.requiredServices?.map((client) => client.key).join(",")
      )
    );
  }, [stepOne]);
  useEffect(() => {
    dispatch(
      shipment.setTrackedBy(
        numberTypes?.requiredServices?.map((client) => client.key).join(",")
      )
    );
  }, [numberTypes]);
  useEffect(() => {
    dispatch(
      shipment.setStatus(
        status?.requiredServices?.map((client) => client.key).join(",")
      )
    );
  }, [status]);
  useEffect(() => {
    dispatch(
      shipment.setScac(
        shippingLine?.requiredServices?.map((client) => client.key).join(",")
      )
    );
  }, [shippingLine]);
  useEffect(() => {
    dispatch(
      shipment.setView(
        criteria?.requiredServices?.map((client) => client.key).join(",")
      )
    );
  }, [criteria]);
  console.log(filters);
  return (
    <div className={styles.actions}>
      <div className={styles["actions-container"]}>
        <SearchBar
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          placeholder={placeholder}
        />
        <button
          className={styles["sortDropdown-filterButton"]}
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        >
          <img className={styles.icon} src={FilterIcon} />
          <span>Filter</span>
        </button>
        <div className={styles.sortDropdown}>
          <button
            className={styles["sortDropdown-filterButton"]}
            onClick={toggleDropdown}
          >
            <span>{sort}</span>
            <img className={styles.icon} src={DownIcon} alt="Down Arrow" />
          </button>

          {isOpen && (
            <div className={styles.dropdownMenu}>
              <div
                className={styles.dropdownItem}
                onClick={() => handleOptionClick("New to Old", "desc")}
              >
                New to Old
              </div>
              <div
                className={styles.dropdownItem}
                onClick={() => handleOptionClick("Old to New", "asc")}
              >
                Old to New
              </div>
            </div>
          )}
        </div>
        {/* <SortBy
          // setIsOpend={setIsOpend}
          // isOpend={isOpend}
          options={sortOptions}
          setMenuOpen={setMenuOpen}
          label="Sort By"
          role="sales"
        /> */}
        {type === "shipment" && user === "sales" ? (
          <div className={styles.addButton}>
            <MainButton handleSubmit={() => setShowSendOfferModal(true)}>
              Add New Shipment
            </MainButton>
          </div>
        ) : null}
      </div>
      {isFilterOpen &&
        (type === "shipment" ? (
          <div className={styles["actions-filters"]}>
            <DropdownWithCheckboxSelect
              label={"Tracked by"}
              value={numberTypes.requiredServices ?? []}
              onChange={handleNumberTypesRequiredServicesChange}
              placeholder={"Select required services"}
              options={filters?.tracked_by}
              handleRequiredServicesChange={
                handleNumberTypesRequiredServicesChange
              }
              className={styles.viewInput}
              containerStyle={containerStyle}
              role={"sales"}
              isChecked={isChecked}
              stepOneData={numberTypes}
            />
            <DropdownWithCheckboxSelect
              label={"Shipping Line"}
              value={shippingLine.requiredServices ?? []}
              onChange={handleShippingLineRequiredServicesChange}
              placeholder={"Select required services"}
              options={filters?.shipping_line}
              handleRequiredServicesChange={
                handleShippingLineRequiredServicesChange
              }
              className={styles.viewInput}
              containerStyle={containerStyle}
              role={"sales"}
              isChecked={isChecked}
              stepOneData={shippingLine}
            />
            <DropdownWithCheckboxSelect
              label={"Status"}
              value={status.requiredServices ?? []}
              onChange={handleStatusRequiredServicesChange}
              placeholder={"Select required services"}
              options={filters?.status}
              handleRequiredServicesChange={handleStatusRequiredServicesChange}
              className={styles.viewInput}
              containerStyle={containerStyle}
              role={"sales"}
              isChecked={isChecked}
              stepOneData={status}
            />
            <DropdownWithCheckboxSelect
              label={"Criteria"}
              value={criteria.requiredServices ?? []}
              onChange={handleCriteriaRequiredServicesChange}
              placeholder={"Select required services"}
              options={filters?.view}
              handleRequiredServicesChange={
                handleCriteriaRequiredServicesChange
              }
              className={styles.viewInput}
              containerStyle={containerStyle}
              role={"sales"}
              isChecked={isChecked}
              stepOneData={criteria}
            />
            <ApplyButton
              onclick={handleApply}
              isDisabled={[numberTypes, criteria, status, shippingLine].every(
                (item) => item.requiredServices.length === 0
              )}
            />
            <ApplyButton
              onclick={handleShipmentClear}
              isDisabled={[numberTypes, criteria, status, shippingLine].every(
                (item) => item.requiredServices.length === 0
              )}
              title="Clear"
            />
          </div>
        ) : (
          <div className={styles["actions-filters"]}>
            <DateInput
              iconColor={"dark"}
              handleChange={(date) => setFrom(handleDateFormat(date))}
              label="From"
              placeholderShowed={false}
              className={styles.dateInput}
              value={from}
            />
            <DateInput
              iconColor={"dark"}
              handleChange={(date) => setTo(handleDateFormat(date))}
              label="To"
              placeholderShowed={false}
              className={styles.dateInput}
              value={to}
            />

            <DropdownWithCheckboxSelect
              label={"Criteria"}
              value={stepOne.requiredServices ?? []}
              onChange={handleRequiredServicesChange}
              placeholder={"Select required services"}
              options={ClientTires}
              handleRequiredServicesChange={handleRequiredServicesChange}
              className={styles.viewInput}
              containerStyle={containerStyle}
              role={"sales"}
              isChecked={isChecked}
              stepOneData={stepOne}
            />
            <ApplyButton
              onclick={handleApply}
              isDisabled={
                from !== null ||
                to !== null ||
                stepOne?.requiredServices?.length !== 0
                  ? false
                  : true
              }
            />
            <ApplyButton
              onclick={handleClear}
              isDisabled={
                from === null &&
                to === null &&
                stepOne?.requiredServices?.length === 0
                  ? true
                  : false
              }
              title="Clear"
            />
          </div>
        ))}

      {filteredBy && (
        <p style={{ color: "#667085" }}>
          <span
            style={{ color: "#667085", fontWeight: "bold", fontSize: "20px" }}
          >
            Filtered by :{" "}
          </span>
          {words.map((word, index) => (
            <span key={index} style={getStyleForWord(index)}>
              {word}{" "}
            </span>
          ))}
        </p>
      )}
    </div>
  );
}

export default Actions;
