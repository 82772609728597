import React from "react";
import AuthLayout from "../../components/AuthLayout";
import BackArrow from "../../components/BackArrow";
import ForgetPasswordForm from "../../components/forgetPasswordForm";

const NewPassword = () => {
  return (
    <AuthLayout>
      <ForgetPasswordForm />
    </AuthLayout>
  );
};

export default NewPassword;
