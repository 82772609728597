import React from "react";
import styles from "./index.module.scss";
function AuthLayout({ children, customBg = "" }) {
  return (
    <div className={`${styles["login"]} ${customBg}`}>
      <div className={styles["login-container"]}>{children}</div>
    </div>
  );
}

export default AuthLayout;
