import MainButton from "../mainButton";
import styles from "./index.module.scss";
import greyArrowLeft from "../../assets/grey-arrow-left.svg";

const FormFooter = ({
  handleBackButton,
  disableBackButton,
  handleNextButton,
  disableNextButton,
  buttonText,
}) => {
  return (
    <footer className={styles["request-quote-form-footer"]}>
      <MainButton
        disabled={disableBackButton}
        handleSubmit={handleBackButton}
        className={`${styles["request-quote-form-footer-back"]} ${
          disableBackButton && styles["back-disabled"]
        }`}
      >
        <img src={greyArrowLeft} alt="" />
        Back
      </MainButton>
      <MainButton
        type={"submit"}
        disabled={disableNextButton}
        handleSubmit={handleNextButton}
        className={styles["request-quote-form-footer-next"]}
      >
        {buttonText}
      </MainButton>
    </footer>
  );
};

export default FormFooter;
